<div class="zone-saisie">
  <input
    [type]="estVisible ? 'text' : 'password'"
    class="form-control"
    [class.survol-bouton]="survolBouton"
    [attr.id]="idChamp"
    [attr.autocomplete]="autocompletion"
    [ngModel]="motDePasse"
    (ngModelChange)="saisie($event)"
    [disabled]="disabled"
    [placeholder]="placeholder"
    (focus)="focus()"
    (blur)="blur()"
    (mousemove)="mousemove($event)"
    (click)="click($event)"
  />
  <div class="bouton" [class.d-none]="!focused || !motDePasse" #boutonVisibilite>
    <span class="fa fa-fw fa-eye-slash" *ngIf="estVisible"></span>
    <span class="fa fa-fw fa-eye" *ngIf="!estVisible"></span>
  </div>
</div>
