import { CommercialDTO, ProfilUtilisateur, ParametresUtilisateurCRM } from "../crm/crm";
export class Synchronisation {
    public PARAMETRES_CRM_UTILISATEUR!: ElementSynchronise<ParametresUtilisateurCRM>;
    public PROFIL_UTILISATEUR!: ElementSynchronise<ProfilUtilisateur>;
    public COMMERCIAL_ACTIF!: ElementSynchronise<CommercialDTO>;
    public CLIENTS!: ElementSynchronise<SynchronisationClient>;
    public EVENEMENTS!: ElementSynchronise<SynchronisationEvenement>;
    public CONTACTS_CLIENTS!: ElementSynchronise<SynchronisationContactClient>;
    public FAMILLES_STAT_1!: ElementSynchronise<SynchronisationFamilleStat>;
    public FAMILLES_STAT_2!: ElementSynchronise<SynchronisationFamilleStat>;
    public FAMILLES_STAT_3!: ElementSynchronise<SynchronisationFamilleStat>;
    public ARTICLES!: ElementSynchronise<SynchronisationArticle>;
    public ARTICLE_MEDIAS!: ElementSynchronise<SynchronisationArticleMedia>;
    public TARIFS!: ElementSynchronise<TarifSynchronise>;
    public TARIFS_MARCHE!: ElementSynchronise<TarifSynchronise>;
    public TARIFS_SPECIAUX!: ElementSynchronise<TarifSynchronise>;
    public REMISES!: ElementSynchronise<SynchronisationRemisesClient>;
    public REMISES_QUANTITE!: ElementSynchronise<SynchronisationRemisesClient>;
    public RISTOURNES!: ElementSynchronise<SynchronisationRemisesClient>;
    public ODRD!: ElementSynchronise<SynchronisationRemisesClient>;
}

export type DemandeSynchronisation = Partial<{ [key in keyof Synchronisation]: Date | string | null }>

export interface ElementSynchronise<T> {
  horodatage: string;
  valeurs: T[];
  dernierNumeroVersion?: string;
  cleSupression: unknown;
}

export class SynchronisationClient {
    public id!: number;
    public adresseComplement1!: string;
    public adresseComplement2!: string;
    public adresseWeb!: string;
    public advCode!: string;
    public advNom!: string;
    public classeRemiseClient!: string;
    public classeRemiseExceptionnel!: string;
    public codePostal!: string;
    public codeRemise!: string;
    public codeRemisePromotion!: string;
    public codeTarif!: string;
    public codeTarifPromotion!: string;
    public commercial1Nom!: string;
    public commercial1Ref!: string;
    public commercial2Nom!: string;
    public commercial2Ref!: string;
    public commercial3Nom!: string;
    public commercial3Ref!: string;
    public dateCreation!: string;
    public dateModif!: string;
    public dateFin?: string;
    public devise!: string;
    public email!: string;
    public etablissement!: string;
    public familleTarifClient!: string;
    public familleTarifExceptionnel!: string;
    public fax!: string;
    public langue!: string;
    public localite!: string;
    public modeReglementRef!: string;
    public modeReglementLibelle!: string;
    public modeTransportRef!: string;
    public modeTransportLibelle!: string;
    public nafLibelle!: string;
    public nafRef!: string;
    public nomAbrege!: string;
    public nomClient!: string;
    public nombreSalarie!: number;
    public origineTiersLibelle!: string;
    public origineTiersRef!: string;
    public paysLibelle!: string;
    public paysRef!: string;
    public rue!: string;
    public siret!: string;
    public telephone!: string;
    public tiers!: string;
    public titreLibelle!: string;
    public titreRef!: string;
    public utilisateurCreation!: string;
    public utilisateurModif!: string;
    public ville!: string;
    public media!: string;
    public chiffreAffaire!: number;
    public plafondDirection!: number;
    public joursLivraison!: string;
    public joursFermeture!: string;
    public contactPrincipalCode!: string;
    public codeAdresseDevis!: string;
    public codeAdresseCommande!: string;
    public codeAdresseBL!: string;
    public codeAdresseFacture!: string;
    public codeAdresseReleve!: string;
    public depotRef!: string;
    public statutOperationBdd!: string;
    public feu!: number;
    public enseigne!: string;
    public familleClient!: string;
    public familleClientLib!: string;
    public secteurClient!: string;
    public secteurClientLib!: string;
    public categorieClient!: string;
    public joursAppel!: string;
    public joursVisite!: string;
    public heureAppel!: string;
    public heureVisite!: string;
    public numeroSemaineReferenceAppel!: number;
    public numeroSemaineReferenceVisite!: number;
    public frequenceAppel!: number;
    public frequenceVisite!: number;
    public tournee!: string;
    public codeFraisFacturation!: string;
    public tauxTvaFraisFacturation!: number;
    public modeGesCons!: string;
    public applicationDeconsAttente!: number;
    public contrainteLivraison!: number;
    public codeMarche!: string;
    public libelleCodeMarche!: string;
}

export class SynchronisationContactClient {
    public id!: number;
    public codeService!: string;
    public contact!: string;
    public dateCreation!: string;
    public dateFin?: string;
    public dateModif!: string;
    public email!: string;
    public fax!: string;
    public fonction1!: string;
    public fonction2!: string;
    public fonction3!: string;
    public fonction4!: string;
    public fonction5!: string;
    public fonction6!: string;
    public fonction7!: string;
    public fonction8!: string;
    public fonction9!: string;
    public fonction10!: string;
    public libelleContact!: string;
    public libelleFonction1!: string;
    public libelleFonction2!: string;
    public libelleFonction3!: string;
    public libelleFonction4!: string;
    public libelleFonction5!: string;
    public libelleFonction6!: string;
    public libelleFonction7!: string;
    public libelleFonction8!: string;
    public libelleFonction9!: string;
    public libelleFonction10!: string;
    public nom!: string;
    public nomAbrege!: string;
    public nomTiers!: string;
    public prenom!: string;
    public telephone!: string;
    public telephoneMobile!: string;
    public tiers!: string;
    public titre!: string;
    public titreLibelle!: string;
    public serviceLibelle!: string;
    public statutOperationBdd!: string;
    public utilisateurCreation!: string;
    public utilisateurModif!: string;
    public anniversaire!: string;
    public remarque!: string;
}

export class SynchronisationEvenement {
    public affaireLibelle!: string;
    public affaireRef!: string;
    public codeEvenementLibelle!: string;
    public codeEvenementRef!: string;
    public contactNom!: string;
    public contactRef!: string;
    public dateCreation!: string;
    public dateDebut!: string;
    public dateModif!: string;
    public etablissement!: string;
    public familleEvenementLibelle!: string;
    public familleEvenementRef!: string;
    public finEvenement!: string;
    public heureDebut!: string;
    public incidentLibelle!: string;
    public incidentRef!: string;
    public libelleEvenement!: string;
    public numeroEvenement!: number;
    public operationMarketingLibelle!: string;
    public operationMarketingRef!: string;
    public priorite!: number;
    public projetLibelle!: string;
    public projetRef!: string;
    public tiersNom!: string;
    public tiersRef!: string;
    public utilisateurEvenementNom!: string;
    public utilisateurEvenementRef!: string;
    public indicationRdv!: number;
    public indicationRealise!: number;
    public indicationAnnule!: number;
    public noteBlob?: string;
}

export interface SynchronisationFamilleStat {
  familleStatRef: string;
  familleStatLibelle: string;
  dateCreation: string;
  dateModif: string;
  statutMaBoutique?: string;
}

export interface SynchronisationArticle {
  id: number;
  articleRef: string;
  designation: string;
  designationAbrege: string;
  ean: string;
  fournisseurHabituelRef: string;
  fournisseurHabituelNom: string;
  familleTarifRef: string;
  familleStat1Ref: string;
  familleStat2Ref: string;
  familleStat3Ref: string;
  familleStat1N1Ref: string;
  familleStat1N2Ref: string;
  familleStat1N3Ref: string;
  familleStat1N4Ref: string;
  familleStat1LibelleRecherche?: string;
  familleStat2N1Ref: string;
  familleStat2N2Ref: string;
  familleStat2N3Ref: string;
  familleStat2N4Ref: string;
  familleStat3N1Ref: string;
  familleStat3N2Ref: string;
  familleStat3N3Ref: string;
  familleStat3N4Ref: string;
  natureRef: string;
  natureLibelle: string;
  uniteReference: string;
  uniteReferenceCoefficient: number;
  uniteReferenceConsigne: number;
  uniteAchat: string;
  uniteAchatCoefficient: number;
  uniteAchatConsigne: number;
  uniteAchatConvertieEn: string;
  uniteStockage: string;
  uniteStockageCoefficient: number;
  uniteStockageConsigne: number;
  uniteStockageConvertieEn: string;
  uniteVente: string;
  uniteVenteCoefficient: number;
  uniteVenteConsigne: number;
  uniteVenteConvertieEn: string;
  uniteDivers1: string;
  uniteDivers1Coefficient: number;
  uniteDivers1Consigne: number;
  uniteDivers1ConvertieEn: string;
  uniteDivers2: string;
  uniteDivers2Coefficient: number;
  uniteDivers2Consigne: number;
  uniteDivers2ConvertieEn: string;
  unitePalette: string;
  unitePaletteCoefficient: number;
  unitePaletteConsigne: number;
  unitePaletteConvertieEn: string;
  dateCreation: string;
  dateModif: string;
  media: string;
  contenance: number;
  listeDatesFinValiditesDepots: string;
  classeRemise: string;
  statutOperationBdd: string;
  volumeAlcool: number;
  tauxTva: number;
  montantAccise: number;
  montantTaxeSucre: number;
  montantRegie: number;
  montantSecu: number;
  typeTeteFut: string;
  typeTeteFutLibelle: string;
  codeAppellation: string;
  codeCategorie: string;
  codeOrigine: string;
  codeCouleur: string;
  millesime: number;
  estArticleBio: boolean;
  coutRevient: number;
  statutMaBoutique?: string;
}

export interface SynchronisationArticleMedia {
  id: number;
  articleRef: string;
  specTechnique: string;
  specDegustation: string;
  dateModification: string;
}

export class SynchronisationAdresseClient {
    public id!: number;
    public tiers!: string;
    public codeAdresse!: string;
    public adresseLibelle!: string;
    public adresseComplement1!: string;
    public adresseComplement2!: string;
    public rue!: string;
    public ville!: string;
    public codePostal!: string;
    public telephone!: string;
    public fax!: string;
    public email!: string;
    public joursLivraison!: string;
    public dateCreation!: string;
    public dateModif!: string;
}

export class SynchronisationUnite {
    public uniteVente!: string;
    public uniteLibelle!: string;
    public dateCreation!: string;
    public dateModif!: string;
}

export interface StatusType {
    [key: string]: {
        realise: number;
        rdv: number;
        valeur: number;
        libelle: string;
    }
}

export const StatusTache: StatusType = {
    RDV_A_CONFIRMER: {
        realise: 3,
        rdv: 1,
        valeur: 0,
        libelle: "Rendez-vous à confirmer"
    },
    RDV_CONFIRMER: {
        realise: 3,
        rdv: 2,
        valeur: 1,
        libelle: "Rendez-vous confirmé"
    },
    TACHE_A_REALISER: {
        realise: 1,
        rdv: 3,
        valeur: 2,
        libelle: "Tâche à réaliser"
    },
    TACHE_REALISER: {
        realise: 2,
        rdv: 3,
        valeur: 3,
        libelle: "Tâche réalisée"
    },
    AUTRE: {
        realise: 3,
        rdv: 3,
        valeur: 4,
        libelle: "Autre"
    },
} as const

export interface TarifSynchronise {
  id: number;
  statutOperationBdd: string;
  codeTarif: string;
  codeMarche?: string;
  clientRef: string;
  articleRef: string;
  depot: string;
  prix: number;
  typePrix?: number;
  dateEffet: string;
  dateFinValidite: string;
}

export class LibelleTarifSynchronise {
  public readonly id!: number;
  public readonly codeTarif!: string;
  public readonly libelle!: string;
  public readonly statutOperationBdd!: string;
}

export class SynchronisationExceptionTarifaire {
  public id!: number;
  public tiers!: string;
  public numeroFamille!: number;
  public famille!: string;
  public codeTarif!: string;
  public statutOperationBdd!: string;
}

export interface SynchronisationRemisesClient {
  // Caractéristiques
  id: number;
	typeRemise: string;
	depot: string;
	articleUnite: string;
	dateEffet: string;
	dateFin: string;
	motif: string;
	remiseCumul: string;
	visiblePortail: number;
  remiseFournisseur: string;
  periodicite: string;
  priorite?: number;

  // Identification client
	tiers: string;
	codeTarif: string;
  categorieClient: string;
	familleClient: string;
	classeRemiseClient: string;

  // Identification article
  articleRef: string;
  fournisseurArticle: string;
  familleArticle: string;
  regroupementArticle: string;
  autreArticle: string;
  classeRemiseArticle: string;

  // Seuil donnant droit
	quantiteSeuil: number;
	uniteSeuil: string;

  // Détail remise
	remiseMontant: number;
	remiseMontantType: string;
  montantRemisePartFournisseur: number;

  //Produits donnés
	quantiteDonnee: number;
	uniteDonnee: string;
  quantiteDonneeDont: string;
  articleRefDonne: string;
  classeRemiseDonnee: string;
  choixListeDonne: string;

	statutOperationBdd: string;
  libelle: string;
}
