import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { ParametreApplicationId } from 'src/app/services/db/parametre-application';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { LoggerService } from 'src/app/services/log/logger.service';
import { EnvironnementApplicationService } from 'src/app/services/environnement-application/environnement-application.service';

@Injectable()
export class AccesApiInterceptor implements HttpInterceptor {
  private urlServeur = '';
  private accesApiDesactive: boolean = false;
  constructor(
    private environnement: EnvironnementApplicationService,
    private loggerService: LoggerService,
    private appConfigService: AppConfigService
  ) {
    this.urlServeur = this.appConfigService.getConfig().urlBaseServeurERP;

    this.environnement
      .getParametreReactif(ParametreApplicationId.ACCES_API_DESACTIVE)
      .subscribe((accesApiDesactive) => (this.accesApiDesactive = accesApiDesactive ?? false));
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      !request ||
      !request.url ||
      (/^http/.test(request.url) && !(this.urlServeur && request.url.startsWith(this.urlServeur)))
    ) {
      return next.handle(request);
    }

    return new Observable((_: Subscriber<HttpEvent<unknown>>) => {
      if (this.accesApiDesactive) {
        // Accès API désactivé par le service (paramètre de l'application), on n'envoie pas la requête
        const objetRequeteRate = new HttpResponse({
          url: request.url,
          status: HttpStatusCode.RequestTimeout,
          statusText: 'Désactivé par le service',
        });

        _.error(objetRequeteRate);
        return;
      } else {
        // On continue la chaîne d'intercepteurs
        next.handle(request).subscribe({
          next: (event: HttpEvent<unknown>) => _.next(event),
          error: (err: unknown) => _.error(err),
          complete: () => _.complete(),
        });

        return;
      }
    });
  }
}
