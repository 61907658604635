import { Injectable } from '@angular/core';
import { DbService } from '../../db/db.service';
import {
  PanierEnteteApp,
  PanierLigneApp,
  PanierCommentaireApp,
  PanierEnteteEtendu,
  PanierLigneEtendu,
  PanierEnteteServeur,
  PanierLigneServeur,
  PanierCommentaireServeur,
  SynchronisationPaniersServeurDTO,
  SynchronisationPaniersAppDTO,
  PanierType,
  PanierNoteApp,
  PanierNoteServeur,
} from './crm-panier';
import { Observable, ReplaySubject, Subscriber, combineLatest, debounceTime, finalize, tap } from 'rxjs';
import { liveQuery } from 'dexie';
import { HttpService } from '../../http/http.service';
import { HttpResponse } from '@angular/common/http';
import { ParametreApplication, ParametreApplicationId } from '../../db/parametre-application';
import { CommercialDTO, ParametresUtilisateurCRM, ProfilUtilisateur } from '../crm';
import { LoggerService } from '../../log/logger.service';
import {
  SynchronisationArticle,
  SynchronisationClient,
  SynchronisationRemisesClient,
  SynchronisationUnite,
  TarifSynchronise,
} from '../../synchronisation/synchronisation';
import { ArticleEtendu } from 'src/app/services/articles/articles.service';
import { Toast } from '../../toast/toast';
import { ConfirmationService } from '../../confirmation/confirmation.service';
import { ToastService } from '../../toast/toast.service';
import { ArticleService } from '../../articles/articles.service';
import { EnvironnementApplicationService } from '../../environnement-application/environnement-application.service';
import { SynchronisationInfoDepot } from '../../synchronisation-quotidienne/synchronisation-quotidienne';
import { CrmService } from '../crm.service';
import { UtilitaireGeneralService } from '../../utilitaire/utilitaire-general.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RechercheClientModalComponent } from 'src/app/modules/fragments/recherche-client-modal/recherche-client-modal.component';
import { ModalService } from '../../modal/modal.service';
import { SynchronisationService } from '../../synchronisation/synchronisation.service';
import { Router } from '@angular/router';
import { PlanningService } from '../../planning/planning.service';
import { RemisesService } from '../../remises/remises.service';
import { PanachageModalComponent } from 'src/app/modules/fragments/panachage-modal/panachage-modal.component';
import { EvenementsService } from '../../evenements/evenements.service';

interface Conditionnement {
  unite: string;
  coefficient: number;
}
@Injectable({
  providedIn: 'root',
})
export class CrmPanierService {
  public singletonSynchroPanier?: Observable<HttpResponse<SynchronisationPaniersServeurDTO>>;
  public listePaniersChargee: ReplaySubject<boolean> = new ReplaySubject(1);
  public listePanierActifChargee: ReplaySubject<boolean> = new ReplaySubject(1);
  public listePaniersValeurs: PanierEnteteEtendu[] = [];
  public listePanierActif!: PanierEnteteEtendu;

  public paniers$ = liveQuery(() => this.dbService.dexie.paniers_entetes.filter((e) => !e.supprimee).toArray());
  public lignes$ = liveQuery(() => this.dbService.dexie.paniers_lignes.filter((e) => !e.supprimee).toArray());
  public commentaires$ = liveQuery(() =>
    this.dbService.dexie.paniers_commentaires.filter((e) => !e.supprimee).toArray()
  );
  public notes$ = liveQuery(() => this.dbService.dexie.paniers_notes.filter((e) => !e.supprimee).toArray());

  public vueListePaniers: boolean = true;
  public parametresUtilisateur: ParametresUtilisateurCRM | undefined;
  public conditionnements: Conditionnement[] = [];
  public unites: SynchronisationUnite[] | undefined;

  private _selectionnerPlusieursPaniers: boolean = false;

  public get selectionnerPlusieursPaniers(): boolean {
    return this._selectionnerPlusieursPaniers;
  }

  public set selectionnerPlusieursPaniers(selectionnerPlusieursPaniers: boolean) {
    this._selectionnerPlusieursPaniers = selectionnerPlusieursPaniers;
  }

  private _choixEnCours: boolean = false;
  private ajoutEnCours: boolean = false;

  public get choixEnCours(): boolean {
    return this._choixEnCours;
  }

  public set choixEnCours(choixEnCours: boolean) {
    this._choixEnCours = choixEnCours;
  }

  constructor(
    private router: Router,
    private dbService: DbService,
    private crmService: CrmService,
    private httpService: HttpService,
    private modalService: ModalService,
    private toastService: ToastService,
    private loggerService: LoggerService,
    private articleService: ArticleService,
    private remisesService: RemisesService,
    private planningService: PlanningService,
    private evenementsService: EvenementsService,
    private confirmationService: ConfirmationService,
    private synchronisationService: SynchronisationService,
    private utilitaireGeneralService: UtilitaireGeneralService,
    private environnementApplicationService: EnvironnementApplicationService
  ) {
    this.init();
  }

  public init(): void {
    this.chargerUnites();
    combineLatest({
      paniers: this.paniers$,
      afficherPanierAvecErreur: liveQuery(() =>
        this.dbService.dexie.parametres_application.get(ParametreApplicationId.AFFICHER_PANIER_AVEC_ERREUR)
      ),
    })
      .pipe(debounceTime(500))
      .subscribe(async (valeurs) => {
        const { paniers } = valeurs;

        const paniersEtendus: PanierEnteteEtendu[] = paniers.map((panier) => ({ ...panier }));
        for (const entete of paniersEtendus) {
          const client: SynchronisationClient | undefined = await this.dbService.dexie.synchronisation_clients.get(
            entete.clientRef
          );
          const depot: SynchronisationInfoDepot | undefined =
            await this.dbService.dexie.synchronisation_info_depots.get(entete.depotRef);
          entete.client = client;
          entete.depot = depot;
        }

        this.listePaniersValeurs = paniersEtendus;
        this.listePaniersChargee.next(true);
      });
  }

  public async rechargeLignesPanier(idPanier: number, forceRecalcule: boolean = false): Promise<void> {
    const panierEntete = await this.dbService.dexie.paniers_entetes.get(idPanier);
    const lignesPanierActuel: PanierLigneEtendu[] = await this.dbService.dexie.paniers_lignes
      .filter((ligne) => !ligne.supprimee && ligne.panierIdIndexedDB == panierEntete?.idIndexedDB)
      .toArray();
    const commentaires = await this.dbService.dexie.paniers_commentaires
      .filter((cmt) => cmt.panierIdIndexedDB == panierEntete?.idIndexedDB)
      .toArray();
    const notes = await this.dbService.dexie.paniers_notes
      .filter((cmt) => cmt.panierIdIndexedDB == panierEntete?.idIndexedDB)
      .toArray();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const panierEtendu: PanierEnteteEtendu = panierEntete!;

    const client: SynchronisationClient | undefined = await this.dbService.dexie.synchronisation_clients.get(
      panierEtendu?.clientRef
    );
    const depot: SynchronisationInfoDepot | undefined = await this.dbService.dexie.synchronisation_info_depots.get(
      panierEtendu?.depotRef
    );
    panierEtendu.client = client;
    panierEtendu.depot = depot;

    for (const commentairePanier of commentaires) {
      if (commentairePanier.panierLigneIdIndexedDB === undefined) {
        panierEtendu.commentaire = commentairePanier;
      }
    }

    for (const notePanier of notes) {
      if (notePanier.panierLigneIdIndexedDB === undefined) {
        panierEtendu.note = notePanier;
      }
    }

    for (const ligne of lignesPanierActuel) {
      if (ligne.aRecalculer != false || forceRecalcule) {
        const article: SynchronisationArticle | undefined = this.articleService.trouverArticleParRef(ligne.articleRef);
        if (article) {
          const tarifs = await this.articleService.obtenirCodeTarifAvecArticleEtClientActif(article, client, undefined);
          if (client) {
            const remise: SynchronisationRemisesClient | null =
              (await this.remisesService.obtenirRemiseDepuisPanier(article, client, 'R')) ?? null;
            if (remise != null) {
              ligne.remiseAppliquee = remise.id;
            }
            const remiseQuantite: SynchronisationRemisesClient | null =
              (await this.remisesService.obtenirRemiseDepuisPanier(article, client, 'Q')) ?? null;
            if (remiseQuantite != null) {
              ligne.remiseQteAppliquee = remiseQuantite.id;
            }

            const tarifArticle = await this.rechargeTarifLignePanier(article, client);
            if (tarifArticle) {
              ligne.prixUniteBase = tarifArticle.prix ?? 0;
              ligne.prixUniteBaseEstNet = tarifArticle.typePrix === 2;
            } else {
              ligne.prixUniteBase = tarifs.tarif?.prix ?? 0;
              ligne.prixUniteBaseEstNet = tarifs.tarif?.typePrix === 2;
            }
          }
          ligne.article = article;
          ligne.tarifPourUnite = tarifs.tarif?.prix ?? 0;
          ligne.consignePourUnite = this.crmService.recupererConsignePourUnite(article, ligne.uniteVente);
          ligne.aRecalculer = false;
          // Mettre à jour la ligne et attendre que la mise à jour soit complète
          await this.dbService.dexie.paniers_lignes.put(ligne);
        }
      }
      for (const commentaireLigne of commentaires) {
        if (commentaireLigne.panierLigneIdIndexedDB !== ligne.idIndexedDB) continue;
        ligne.commentaire = commentaireLigne;
        break;
      }
      for (const noteLigne of notes) {
        if (noteLigne.panierLigneIdIndexedDB !== ligne.idIndexedDB) continue;
        ligne.note = noteLigne;
        break;
      }
    }
    panierEtendu.lignes = lignesPanierActuel;

    this.listePanierActif = panierEtendu;
    this.listePanierActifChargee.next(true);
  }

  private async rechargeTarifLignePanier(
    article: SynchronisationArticle,
    client: SynchronisationClient
  ): Promise<TarifSynchronise | undefined> {
    let tarifArticle = undefined;

    const tarifsMarche = (
      await this.dbService.dexie.synchronisation_tarifs_marche
        .where('[codeMarche+articleRef]')
        .equals([client.codeMarche, article.articleRef])
        .toArray()
    )
      .filter(
        (tarif) =>
          new Date(tarif.dateEffet) <= new Date() &&
          (!tarif.dateFinValidite || new Date(tarif.dateFinValidite) >= new Date())
      )
      .sort((a, b) => new Date(b.dateEffet).getTime() - new Date(a.dateEffet).getTime());

    if (tarifsMarche.length > 0) {
      tarifArticle = tarifsMarche[0];
    }

    if (!tarifArticle) {
      const tarifsSpeciaux = (
        await this.dbService.dexie.synchronisation_tarifs_speciaux
          .where('[clientRef+articleRef]')
          .equals([client.tiers, article.articleRef])
          .toArray()
      )
        .filter((tarif) => !tarif.dateFinValidite || new Date(tarif.dateFinValidite) >= new Date())
        .sort((a, b) => new Date(b.dateEffet).getTime() - new Date(a.dateEffet).getTime());

      if (tarifsSpeciaux.length > 0) {
        if (tarifsSpeciaux.length === 1) {
          //si un seul tarif alors on prend le premier
          tarifArticle = tarifsSpeciaux[0];
        } else if (tarifsSpeciaux.length > 1) {
          // Filtrer les tarifs avec dateEffet inférieure ou égale à aujourd'hui
          const tarifsFiltres = tarifsSpeciaux
            .filter((t) => new Date(t.dateEffet) <= new Date())
            .sort((a, b) => new Date(b.dateEffet).getTime() - new Date(a.dateEffet).getTime());

          // Si des tarifs correspondent aux critères, trier les tarifs filtrés par dateEffet en ordre décroissant
          if (tarifsFiltres.length > 0) {
            tarifArticle = tarifsFiltres.find((t) => !t.dateFinValidite || new Date(t.dateFinValidite) >= new Date());
          }
        }
      }
    }

    return tarifArticle;
  }

  public viderLignesPanier(): void {
    if (this.listePanierActif) {
      this.listePanierActif.lignes = [];
      this.listePanierActifChargee.next(true);
    }
  }

  private async chargerUnites(): Promise<void> {
    this.unites = await this.dbService.dexie.synchronisation_unites.toArray();
  }

  public async validerAjoutRapide(): Promise<void> {
    if (this.articleService.listeArticles) {
      const articlesElligibles: ArticleEtendu[] = this.articleService.listeArticles.filter(
        (article) => article.panierQuantite > 0 && article.panierUnite
      );
      this.validerAjoutArticles(articlesElligibles);
    }
  }

  public async validerAjoutArticles(articles: ArticleEtendu[]): Promise<void> {
    if (this.ajoutEnCours) {
      this.loggerService.warn("Ajout déjà en cours, annulation de l'appel");
      return;
    }
    this.ajoutEnCours = true;

    try {
      articles = articles.filter((article) => article.panierQuantite > 0 && article.panierUnite);
      if (!articles.length) return;

      const [depotActif, depotParDefaut, paramCommercialActif, paramClientActif, paramPanierActif]: [
        string | undefined,
        string | undefined,
        CommercialDTO | undefined,
        string | undefined,
        number | undefined
      ] = await Promise.all([
        this.environnementApplicationService.getParametre(ParametreApplicationId.ENVIRONNEMENT_DEPOT),
        this.environnementApplicationService.getParametre(ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM)
          ?.depotParDefautRef,
        this.environnementApplicationService.getParametre(ParametreApplicationId.COMMERCIAL_ACTIF),
        this.environnementApplicationService.getParametre(ParametreApplicationId.CLIENT_ACTIF),
        this.environnementApplicationService.getParametre(ParametreApplicationId.PANIER_ACTIF),
      ]);

      this.loggerService.info(`Ajout validerAjoutArticles: FIN GET  `);
      let client: SynchronisationClient | undefined = await this.dbService.dexie.synchronisation_clients.get(
        paramClientActif ?? ''
      );
      this.loggerService.info(`Ajout validerAjoutArticles: FIN STEP 01  `);
      const panierActif: PanierEnteteApp | undefined = await this.dbService.dexie.paniers_entetes.get(
        paramPanierActif ?? 0
      );
      this.loggerService.info(`Ajout validerAjoutArticles: FIN STEP 02  `);
      const identifiantPanierActif: number | undefined = panierActif?.idIndexedDB;
      const commercial: CommercialDTO | undefined = paramCommercialActif;

      let depotRef =
        depotActif !== undefined && depotActif !== ''
          ? depotActif
          : depotParDefaut !== undefined && depotParDefaut !== ''
          ? depotParDefaut
          : client
          ? client.depotRef
          : '';

      // TEMPORAIRE =============================================================
      if (!depotRef) {
        const clientFinal = (await this.dbService.dexie.synchronisation_clients.toArray())[0];
        client = clientFinal;
        depotRef = clientFinal.depotRef;
      }
      // TEMPORAIRE =============================================================

      const depot: SynchronisationInfoDepot | undefined = await this.dbService.dexie.synchronisation_info_depots.get(
        depotRef
      );
      this.loggerService.info(`Ajout validerAjoutArticles: FIN STEP 03  `);
      const etablissement: string = depot?.etablissement ?? '';

      this.loggerService.info(`Ajout validerAjoutArticles: FIN STEP 04  `);
      if (!commercial) {
        this.loggerService.warn(`Achat rapide: commercial introuvable [${paramCommercialActif}]`);
      }

      if (!client && !panierActif) {
        this.loggerService.warn(`Achat rapide: client introuvable [${paramClientActif}]`);
        const modalRef: NgbModalRef = this.modalService.open(RechercheClientModalComponent);
        const res = (await modalRef.result) as SynchronisationClient | undefined;
        if (!res) {
          this.toastService.afficher(new Toast('Achat rapide', 'Veuillez sélectionner un client valide'));
          return;
        } else {
          client = res;
        }
      }
      const articlesEnErreur: ArticleEtendu[] = articles.filter(
        (article: ArticleEtendu) => article.panierQuantite <= 0
      );
      if (articlesEnErreur.length) {
        const referencesArticlesEnErreur: string[] = articlesEnErreur.map(
          (article: ArticleEtendu) => article.articleRef
        );
        this.loggerService.warn(`Achat rapide: quantité invalide ${referencesArticlesEnErreur.join(',')}`);
        this.toastService.afficher(new Toast('Achat rapide', 'Veuillez saisir une quantité valide'));
      }

      let identifiantPanier: number = identifiantPanierActif ? identifiantPanierActif : 0;
      if (!identifiantPanier) this.loggerService.warn(`Achat rapide: panier introuvable [${paramPanierActif}]`);

      this.loggerService.info(`Ajout validerAjoutArticles: FIN STEP 1  `);
      for (const article of articles) {
        article.chargement = true;
      }
      this.loggerService.info(`Ajout validerAjoutArticles: FIN STEP 2  `);

      if (client) {
        this.loggerService.info(`Ajout validerAjoutArticles: FIN STEP 3  `);

        const lignesExistantes = await this.dbService.dexie.paniers_lignes
          .where('panierIdIndexedDB')
          .equals(identifiantPanier)
          .toArray();

        // TENTATIVE DE CREATION DE PANIER
        if (!identifiantPanier) {
          try {
            // On ouvre la modale de confirmation pour demander à l'utilisateur s'il souhaite créer un panier
            let corpsConfirmation = `Souhaitez-vous créer un panier pour le client ${client?.nomClient} ?`;

            if (client?.feu === 2) {
              corpsConfirmation = `Attention, ce compte client nécessite votre attention. (feu orange)
              ${corpsConfirmation}`;
            }

            await this.confirmationService.afficher({
              titre: "Création d'un panier",
              corps: corpsConfirmation,
              texteOui: 'Créer',
              texteNon: 'Annuler',
              iconeBoutonOui: '',
              iconeBoutonNon: '',
            });
          } catch (err) {
            // Enlever le chargement si on annule la création du panier
            for (const article of articles) {
              article.chargement = false;
            }
            return;
          }

          this.planningService.marquerPlanningEnCours(client.tiers);

          let panier: PanierEnteteApp;
          if (depot && client && !panierActif) {
            const parametreCrm = (await this.dbService.dexie.parametres_application.get(
              ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM
            )) as ParametreApplication<ParametresUtilisateurCRM> | undefined;
            this.parametresUtilisateur = parametreCrm?.valeur;
            let depot: string = '';
            if (!this.parametresUtilisateur?.saisieDepotAutomatique) {
              depot = depotRef;
            } else {
              depot = client.depotRef;
            }

            panier = await this.creerPanier(
              commercial?.tiers ?? 'CLIENTFINAL',
              client.tiers,
              client.codeTarif,
              depot,
              etablissement
            );
            if (panier.idIndexedDB) identifiantPanier = panier.idIndexedDB;

            this.loggerService.info(`Ajout validerAjoutArticles: FIN STEP 4  `);
            for (const article of articles) {
              this.loggerService.info(`Ajout validerAjoutArticles: FIN STEP 5  `);

              await this.creerLigne(identifiantPanier, lignesExistantes, article, client);
            }
          }
        } else {
          this.loggerService.info(`Ajout validerAjoutArticles: FIN STEP 4  `);
          for (const article of articles) {
            this.loggerService.info(`Ajout validerAjoutArticles: FIN STEP 5  `);
            await this.creerLigne(identifiantPanier, lignesExistantes, article, client);
          }
        }
      }
      // TODOFAB
      await this.dbService.dexie.parametres_application.put({
        id: ParametreApplicationId.PANIER_ACTIF,
        valeur: identifiantPanier as never,
      });

      for (const article of articles) {
        article.panierQuantite = 0;
        article.chargement = false;
      }
      this.rechargeLignesPanier(identifiantPanier, false).then();
    } catch (err) {
      this.loggerService.error(`Erreur achat rapide : ${JSON.stringify(err)}`);
      this.toastService.afficher(new Toast('Achat rapide', "Une erreur est survenue lors de l'ajout des articles"));

      for (const article of articles) {
        article.chargement = false;
      }
    } finally {
      this.loggerService.info('FIN AJOUT EN COURS');
      this.ajoutEnCours = false;
    }
  }

  public synchroniserPaniers(): Observable<HttpResponse<SynchronisationPaniersServeurDTO>> {
    if (this.singletonSynchroPanier) {
      return this.singletonSynchroPanier;
    }

    this.synchronisationService.synchronisationEnCours = true;
    this.singletonSynchroPanier = new Observable(
      (observeur: Subscriber<HttpResponse<SynchronisationPaniersServeurDTO>>) => {
        Promise.all([
          this.dbService.dexie.paniers_entetes.toArray(),
          this.dbService.dexie.paniers_lignes.toArray(),
          this.dbService.dexie.paniers_commentaires.toArray(),
          this.dbService.dexie.paniers_notes.toArray(),
        ]).then(([entetes, lignes, commentaires, notes]) => {
          entetes.forEach((entete) => {
            const lignesCorrespondantes = lignes.filter((ligne) => ligne.panierIdIndexedDB === entete.idIndexedDB);
            if (lignesCorrespondantes.length === 0 && entete.statut === 1) {
              entete.supprimee = true;
            }
          });

          lignes.forEach((ligne) => {
            const enteteCorrespondante = entetes.find((entete) => entete.idIndexedDB === ligne.panierIdIndexedDB);
            if (!enteteCorrespondante || enteteCorrespondante.supprimee) {
              ligne.supprimee = true;
            }
          });

          commentaires.forEach((commentaire) => {
            if (commentaire.panierIdIndexedDB !== undefined) {
              const enteteCorrespondante = entetes.find(
                (entete) => entete.idIndexedDB === commentaire.panierIdIndexedDB
              );
              if (!enteteCorrespondante || enteteCorrespondante.supprimee) {
                commentaire.supprimee = true;
              }
            } else if (commentaire.panierLigneIdIndexedDB !== undefined) {
              const ligneCorrespondante = lignes.find(
                (ligne) => ligne.idIndexedDB === commentaire.panierLigneIdIndexedDB
              );
              if (!ligneCorrespondante || ligneCorrespondante.supprimee) {
                commentaire.supprimee = true;
              }
            }
          });

          notes.forEach((note) => {
            if (note.panierIdIndexedDB !== undefined) {
              const enteteCorrespondante = entetes.find((entete) => entete.idIndexedDB === note.panierIdIndexedDB);
              if (!enteteCorrespondante || enteteCorrespondante.supprimee) {
                note.supprimee = true;
              }
            } else if (note.panierLigneIdIndexedDB !== undefined) {
              const ligneCorrespondante = lignes.find((ligne) => ligne.idIndexedDB === note.panierLigneIdIndexedDB);
              if (!ligneCorrespondante || ligneCorrespondante.supprimee) {
                note.supprimee = true;
              }
            }
          });

          const dto = new SynchronisationPaniersAppDTO();
          dto.entetes = entetes.filter((entete) => !entete.supprimee);
          dto.entetesSupprimees = entetes.filter((entete) => entete.supprimee).map((entete) => entete.id as number);
          dto.lignes = lignes.filter((ligne) => !ligne.supprimee);
          dto.lignesSupprimees = lignes.filter((ligne) => ligne.supprimee).map((ligne) => ligne.id as number);
          dto.commentaires = commentaires.filter((commentaire) => !commentaire.supprimee);
          dto.commentairesSupprimes = commentaires
            .filter((commentaire) => commentaire.supprimee)
            .map((commentaire) => commentaire.id as number);
          dto.notes = notes.filter((note) => !note.supprimee);
          dto.notesSupprimees = notes.filter((note) => note.supprimee).map((note) => note.id as number);

          if (window.navigator.onLine) {
            this.httpService
              .postFromApi<SynchronisationPaniersServeurDTO>('business/crm/paniers', dto)
              .pipe(
                tap({
                  next: (dtoRetour) => {
                    if (dtoRetour.body) {
                      this.merge(
                        dtoRetour.body.entetes,
                        dtoRetour.body.lignes,
                        dtoRetour.body.commentaires,
                        dtoRetour.body.notes
                      );
                      if (this.router.url.includes('/crm/paniers')) {
                        this.router.navigate(['/crm/paniers']);
                      }
                    }
                  },
                })
              )
              .subscribe({
                next: (response: HttpResponse<SynchronisationPaniersServeurDTO>) => observeur.next(response),
                error: (error: unknown) => observeur.error(error),
                complete: () => observeur.complete(),
              });
          } else {
            this.toastService.afficher(new Toast('Connexion internet recquise', 'Vérifiez votre connexion Internet.'));
            this.singletonSynchroPanier = undefined;
            this.synchronisationService.synchronisationEnCours = false;
          }
        });
      }
    ).pipe(
      tap({
        error: () => {
          this.singletonSynchroPanier = undefined;
          this.synchronisationService.synchronisationEnCours = false;
        },
        complete: () => {
          this.singletonSynchroPanier = undefined;
          this.synchronisationService.synchronisationEnCours = false;
        },
      }),
      finalize(async () => {
        await this.evenementsService.synchroniserErp();
      })
    );

    return this.singletonSynchroPanier;
  }

  public recupererJourLivraison(joursLivrables?: string[]): Date | null {
    if (!joursLivrables?.length || joursLivrables.every((j) => !j.trim())) return null;
    const dateAujourdhui: Date = new Date();
    let jourActuel: number = dateAujourdhui.getDay();
    // const aujourdhui: Date = new Date();
    // const jourSemaineActuel = aujourdhui.getDay();
    // const heureActuelle = aujourdhui.getHours();
    const nbJours: number = 1;
    // let nbJours: number = 1;
    // if (jourSemaineActuel === 5) {
    //   if (heureActuelle >= 12) {
    //     nbJours += 3;
    //   }
    //   else {
    //     nbJours += 2;
    //   }
    // }
    // else if (jourSemaineActuel === 6) {
    //   nbJours += 2;
    // }
    // else {
    //   if (heureActuelle >= 12) {
    //     nbJours += 1;
    //   }
    // }

    dateAujourdhui.setDate(dateAujourdhui.getDate() + nbJours);
    jourActuel = dateAujourdhui.getDay();

    for (let i = 0; i < 7; i++) {
      const datePlus1Jour: Date = this.utilitaireGeneralService.decalerJours(dateAujourdhui, i);
      jourActuel = datePlus1Jour.getDay();
      if (joursLivrables.includes('' + this.utilitaireGeneralService.joursJavascriptVersJoursSemaine(jourActuel))) {
        return datePlus1Jour;
      }
    }
    return null;
  }

  public async creerPanier(
    commercialRef: string,
    clientRef: string,
    codeTarif: string,
    depotRef: string,
    etablissement: string,
    autre?: Partial<PanierEnteteApp>
  ): Promise<PanierEnteteApp> {
    let panier: PanierEnteteApp = new PanierEnteteApp(
      commercialRef,
      clientRef,
      codeTarif,
      depotRef,
      PanierType.COMMANDE,
      etablissement
    );

    const client: SynchronisationClient | undefined = await this.dbService.dexie.synchronisation_clients.get(clientRef);
    let joursLivrables: string[] = client?.joursLivraison.split('') ?? [];
    if (client && joursLivrables.length < 1) {
      const tournee =
        (await this.dbService.dexie.synchronisation_tournee
          .where('numero')
          .equals(client.tournee ?? '')
          .first()) ?? null;
      joursLivrables = tournee?.joursLivraison.split('') ?? [];
    }
    const demain: Date = new Date();
    demain.setDate(demain.getDate() + 1);
    const dateLivraison: Date | null = this.recupererJourLivraison(joursLivrables) ?? demain;

    const params: ProfilUtilisateur | undefined = this.environnementApplicationService.getParametre(
      ParametreApplicationId.PROFIL_UTILISATEUR
    );
    if (!params) throw new Error('Paramètres utilisateur introuvables');

    panier = {
      ...panier,
      ...autre,
      dateLivraisonEstimee: dateLivraison.toISOString(),
      utilisateurCreation: params.login,
      utilisateurModif: params.login,
    };
    const id: number = await this.dbService.dexie.paniers_entetes.add(panier);
    panier.idIndexedDB = id;

    return panier;
  }

  public async ajouterLigneAuPanier(
    panierIdIndexedDB: number,
    article: ArticleEtendu,
    options?: {
      remise?: SynchronisationRemisesClient | null;
      remiseQuantite?: SynchronisationRemisesClient | null;
      autre?: Partial<PanierLigneApp>;
    }
  ): Promise<PanierLigneApp> {
    const coefUniteVendu = this.articleService.trouverCoefficientCorrespondant(article);

    let ligne: PanierLigneApp = new PanierLigneApp({
      panierIdIndexedDB,
      articleRef: article.articleRef,
      uniteVente: article.panierUnite,
      quantite: Number(article.panierQuantite),
      qteGratuit: article.panierQuantiteGratuit,
      motifGratuiteQuantite: article.motifQuantiteGratuit,
      prixClavier: article.panierPrixClavier ?? null,
      motifGratuitePrixClavier: article.motifPrixClavier,
      prixUniteBase: article.tarif?.prix ?? 0,
      prixUniteBaseEstNet: article.tarif?.typePrix === 2,
      uniteReference: article.uniteReference,
      coefVente: coefUniteVendu,
      gratuiteAuto: options?.autre?.gratuiteAuto ?? false,
      articleRefGratuitChoisi: options?.autre?.articleRefGratuitChoisi ?? '',
      idParent: options?.autre?.idParent ?? null,
      remiseAppliquee: options?.remise?.id ?? null,
      exclureGratuite: false,
      remiseQteAppliquee: options?.remiseQuantite?.id ?? null,
    });

    const params: ProfilUtilisateur | undefined = this.environnementApplicationService.getParametre(
      ParametreApplicationId.PROFIL_UTILISATEUR
    );
    if (!params) throw new Error('Paramètres utilisateur introuvables');

    ligne = {
      ...ligne,
      ...options?.autre,
      utilisateurCreation: params?.login ?? '',
      utilisateurModif: params?.login ?? '',
    };
    const id: number = await this.dbService.dexie.paniers_lignes.add(ligne);
    ligne.idIndexedDB = id;
    ligne.idCreation = id;

    await this.dbService.dexie.paniers_lignes.update(id, {
      idIndexedDB: ligne.idIndexedDB,
      idCreation: ligne.idCreation,
    });

    return ligne;
  }

  public async creerLigne(
    panierIdIndexedDB: number,
    lignesExistantes: PanierLigneApp[],
    article: ArticleEtendu,
    client: SynchronisationClient
  ): Promise<PanierLigneApp> {
    this.loggerService.info(
      `Ajout d'une ligne au panier: [${panierIdIndexedDB}] [${article.articleRef}] [${article.panierUnite}] [${article.panierQuantite}]`
    );

    // Vérifier si une ligne existe déjà avec le même articleRef et uniteVente dans le panier
    const ligneExistante: PanierLigneApp[] = lignesExistantes.filter(
      (item) =>
        item.articleRef === article.articleRef &&
        item.uniteVente === article.panierUnite &&
        !item.supprimee &&
        !item.gratuiteAuto
    );

    // Créer ou mettre à jour la ligne principale
    let lignePrincipale: PanierLigneApp;
    const remise: SynchronisationRemisesClient | null =
      (await this.remisesService.obtenirRemiseDepuisPanier(article, client, 'R')) ?? null;
    const remiseQuantite: SynchronisationRemisesClient | null =
      (await this.remisesService.obtenirRemiseDepuisPanier(article, client, 'Q')) ?? null;

    if (ligneExistante.length > 0) {
      // Une ligne existe déjà, on l'augmente en quantité
      lignePrincipale = ligneExistante[0];
      lignePrincipale.quantite = Number(lignePrincipale.quantite) + Number(article.panierQuantite);
      lignePrincipale.qteGratuit = Number(lignePrincipale.qteGratuit) + Number(article.panierQuantiteGratuit);
      lignePrincipale.motifGratuiteQuantite = article.motifQuantiteGratuit;
      lignePrincipale.prixClavier = article.panierPrixClavier;
      lignePrincipale.motifGratuitePrixClavier = article.motifPrixClavier;
      lignePrincipale.aRecalculer = true;

      lignePrincipale.remiseAppliquee = remise?.id ?? null;
      lignePrincipale.remiseQteAppliquee = remiseQuantite?.id ?? null;

      // Mettre à jour la ligne et attendre que la mise à jour soit complète
      await this.dbService.dexie.paniers_lignes.put(lignePrincipale);
      await this.ajouterNouvelleLigne(panierIdIndexedDB, article, client, lignePrincipale, remise, remiseQuantite);
      return lignePrincipale;
    } else {
      lignePrincipale = await this.ajouterLigneAuPanier(panierIdIndexedDB, article, { remise, remiseQuantite });
      await this.ajouterNouvelleLigne(panierIdIndexedDB, article, client, lignePrincipale, remise, remiseQuantite);
      return lignePrincipale;
    }
  }

  private async ajouterNouvelleLigne(
    panierIdIndexedDB: number,
    article: ArticleEtendu,
    client: SynchronisationClient,
    lignePrincipale: PanierLigneApp,
    remise: SynchronisationRemisesClient | null,
    remiseQuantite: SynchronisationRemisesClient | null
  ): Promise<void> {
    const lignePrincipaleId = lignePrincipale.idCreation ?? lignePrincipale.idIndexedDB;

    const articleDonne: string | undefined = remiseQuantite?.articleRefDonne;
    let gratuiteAjoutee = false;

    const lignesPanierCumulees: PanierLigneApp[] = [];
    if (remise) {
      lignePrincipale.remiseAppliquee = remise.id;
    }

    if (remiseQuantite) {
      lignePrincipale.remiseQteAppliquee = remiseQuantite?.id;
      await this.dbService.dexie.paniers_lignes.put(lignePrincipale);

      await this.obtenirConditionnements(article);
      const coefVente = this.obtenirCoefficientParUnite(article.panierUnite) ?? 1;
      const coefRemise = this.obtenirCoefficientParUnite(remiseQuantite.uniteSeuil);
      const coefDonne = this.obtenirCoefficientParUnite(remiseQuantite.uniteDonnee) ?? 1;
      if (!coefRemise) return;

      let quantiteTotale = 0;

      // Si remise cumulée, récupérer toutes les lignes et cumuler les quantités selon le critère de la remise
      if (remiseQuantite.remiseCumul === '2') {
        const lignesPanier: PanierLigneApp[] = await this.dbService.dexie.paniers_lignes
          .where('panierIdIndexedDB')
          .equals(panierIdIndexedDB)
          .and((item) => !item.supprimee)
          .and((item) => !item.gratuiteAuto)
          .and((item) => item.remiseQteAppliquee === lignePrincipale.remiseQteAppliquee)
          .toArray();

        // Cumuler les quantités selon le critère défini par la remise
        for (const ligne of lignesPanier) {
          const articleLigne = await this.dbService.dexie.synchronisation_articles
            .where('articleRef')
            .equals(ligne.articleRef)
            .first();
          const coefLigne = this.obtenirCoefficientParUnite(ligne.uniteVente) ?? 1;

          if (
            articleLigne &&
            this.estRemiseAppliquable(remiseQuantite, articleLigne) &&
            (ligne.prixClavier === null || (ligne.prixClavier !== '' && Number(ligne.prixClavier) !== 0))
          ) {
            lignesPanierCumulees.push(ligne);
            if (!ligne.exclureGratuite) {
              quantiteTotale += Number(ligne.quantite) * coefLigne;
            }
          }
        }
      } else {
        quantiteTotale = Number(lignePrincipale.quantite) * coefVente;
      }

      const nbRemiseApplique = Math.floor(quantiteTotale / coefRemise / remiseQuantite.quantiteSeuil) ?? 1;
      const qtt = nbRemiseApplique * (remiseQuantite.quantiteDonnee ?? 1);

      if (qtt === 0) return;
      // Gestion des lignes gratuites si remise non cumulée
      if (remiseQuantite.articleRefDonne) {
        const articleDonneData = await this.dbService.dexie.synchronisation_articles
          .where('articleRef')
          .equals(remiseQuantite.articleRefDonne)
          .first();

        if (articleDonneData && this.unites) {
          const articleEtendu = await this.articleService.etendreArticle(articleDonneData, []);

          const ligneGratuiteExistante = await this.dbService.dexie.paniers_lignes
            .where('panierIdIndexedDB')
            .equals(panierIdIndexedDB)
            .and((item) => !item.supprimee)
            .and((item) => item.gratuiteAuto)
            .and(
              (item) =>
                (remiseQuantite?.remiseCumul !== '2' && item.idParent === lignePrincipale.idCreation) ||
                item.articleRef === lignePrincipale.articleRefGratuitChoisi
            )
            .and((item) => item.remiseQteAppliquee === lignePrincipale.remiseQteAppliquee)
            .first();

          if (ligneGratuiteExistante) {
            ligneGratuiteExistante.quantite = qtt;
            await this.dbService.dexie.paniers_lignes.put(ligneGratuiteExistante);
          } else {
            await this.ajouterLigneAuPanier(panierIdIndexedDB, articleEtendu, {
              autre: {
                quantite: qtt,
                coefVente: coefDonne,
                uniteVente: remiseQuantite.uniteDonnee,
                gratuiteAuto: true,
                idParent: lignePrincipaleId,
                remiseQteAppliquee: lignePrincipale.remiseQteAppliquee,
              },
            });
          }
          gratuiteAjoutee = true;
        }
      }
      // Cas où choixListeDonne === '2' pour remise avec liste d'articles donnés
      else if (remiseQuantite.classeRemiseDonnee) {
        if (remiseQuantite.choixListeDonne === '2') {
          const articles = await this.trouverListeArticlesChoixGratuites(
            remiseQuantite.uniteDonnee,
            remiseQuantite.classeRemiseDonnee
          );
          const lignesGratuitesExistantes = await this.dbService.dexie.paniers_lignes
            .where('panierIdIndexedDB')
            .equals(panierIdIndexedDB)
            .and((item) => !item.supprimee)
            .and((item) => item.gratuiteAuto)
            .and((item) => item.remiseQteAppliquee === lignePrincipale.remiseQteAppliquee)
            .and((item) => remiseQuantite?.remiseCumul === '2' || item.idParent === lignePrincipale.idCreation)
            .toArray();

          for (const article of articles) {
            const ligneGratuiteExistante = lignesGratuitesExistantes.find(
              (ligne) => ligne.articleRef === article.articleRef
            );
            if (ligneGratuiteExistante) {
              ligneGratuiteExistante.quantite = qtt;
              await this.dbService.dexie.paniers_lignes.put(ligneGratuiteExistante);
            } else {
              await this.ajouterLigneAuPanier(panierIdIndexedDB, article, {
                autre: {
                  quantite: qtt,
                  coefVente: coefDonne,
                  uniteVente: remiseQuantite.uniteDonnee,
                  gratuiteAuto: true,
                  idParent: lignePrincipaleId,
                  remiseQteAppliquee: lignePrincipale.remiseQteAppliquee,
                },
              });
            }
          }
        } else {
          // CHOIX D'UN ARTICLE
          const ligneGratuiteExistante = await this.dbService.dexie.paniers_lignes
            .where('panierIdIndexedDB')
            .equals(panierIdIndexedDB)
            .and((item) => !item.supprimee)
            .and((item) => item.gratuiteAuto)
            .and((item) => item.remiseQteAppliquee === lignePrincipale.remiseQteAppliquee)
            .and((item) => remiseQuantite.remiseCumul === '2' || item.idParent === lignePrincipale.idCreation)
            .first();

          if (ligneGratuiteExistante) {
            ligneGratuiteExistante.quantite = Number(qtt);
            await this.dbService.dexie.paniers_lignes.put(ligneGratuiteExistante);
            lignePrincipale.articleRefGratuitChoisi = ligneGratuiteExistante.articleRef;
            await this.dbService.dexie.paniers_lignes.put(lignePrincipale);
          } else {
            await this.ouvrirModalChoixGratuite(lignePrincipale, lignesPanierCumulees, null, remiseQuantite, qtt);
          }
          gratuiteAjoutee = true;
        }
      }
    }

    if (gratuiteAjoutee && articleDonne) {
      lignePrincipale.articleRefGratuitChoisi = articleDonne;
      await this.dbService.dexie.paniers_lignes.put(lignePrincipale);
    }
  }

  private estRemiseAppliquable(remiseQuantite: SynchronisationRemisesClient, article: SynchronisationArticle): boolean {
    return (
      (remiseQuantite.articleRef !== '' && article?.articleRef === remiseQuantite.articleRef) ||
      (remiseQuantite.fournisseurArticle !== '' &&
        article?.fournisseurHabituelRef === remiseQuantite.fournisseurArticle) ||
      (remiseQuantite.familleArticle !== '' &&
        (article?.familleStat1Ref === remiseQuantite.familleArticle ||
          article?.familleStat1N1Ref === remiseQuantite.familleArticle)) ||
      (remiseQuantite.regroupementArticle !== '' && article?.familleStat2Ref === remiseQuantite.regroupementArticle) ||
      (remiseQuantite.autreArticle !== '' && article?.familleStat3Ref === remiseQuantite.autreArticle) ||
      (remiseQuantite.classeRemiseArticle !== '' && article?.classeRemise === remiseQuantite.classeRemiseArticle)
    );
  }

  public async ouvrirModalChoixGratuite(
    ligne: PanierLigneEtendu,
    lignesCumulees: PanierLigneApp[] | null,
    lignesFilles: PanierLigneApp[] | null,
    remiseQuantite: SynchronisationRemisesClient,
    qtt: number
  ): Promise<boolean> {
    if (this.choixEnCours) return false;
    if (ligne.article) {
      await this.obtenirConditionnements(ligne.article);
    }
    this.choixEnCours = true;
    let articleModifie = false;
    const panachageModal = this.modalService.open(PanachageModalComponent, { size: 'md' });
    panachageModal.componentInstance.classeRemise = remiseQuantite.classeRemiseDonnee;
    panachageModal.componentInstance.uniteRemise = remiseQuantite.uniteDonnee;
    panachageModal.componentInstance.articleRefGratuitChoisi = ligne.articleRefGratuitChoisi;

    try {
      const articleSelectionne: ArticleEtendu = await panachageModal.result;
      if (articleSelectionne.articleRef !== ligne.articleRefGratuitChoisi) {
        articleModifie = true;
        lignesFilles?.forEach((ligne) => {
          if (ligne.idIndexedDB) {
            this.supprimerLigne(ligne);
          }
        });

        if (lignesCumulees && lignesCumulees?.length > 0) {
          lignesCumulees.forEach((ligne) => {
            ligne.articleRefGratuitChoisi = articleSelectionne.articleRef;
            this.dbService.dexie.paniers_lignes.put(ligne);
          });
        } else {
          ligne.articleRefGratuitChoisi = articleSelectionne.articleRef;
          this.dbService.dexie.paniers_lignes.put(ligne);
        }

        if (ligne.panierIdIndexedDB && this.unites) {
          const coefDonne = this.obtenirCoefficientParUnite(remiseQuantite.uniteDonnee) ?? 1;
          this.ajouterLigneAuPanier(ligne.panierIdIndexedDB, articleSelectionne, {
            autre: {
              quantite: qtt,
              coefVente: coefDonne,
              uniteVente: remiseQuantite.uniteDonnee,
              gratuiteAuto: true,
              prixUniteBaseRemise: 0,
              prixUniteBase: 0,
              idParent: ligne.idCreation,
              remiseQteAppliquee: ligne.remiseQteAppliquee,
            },
          });
        }
      }
    } finally {
      this.choixEnCours = false;
    }
    return articleModifie;
  }

  public async trouverListeArticlesChoixGratuites(unite: string, classeRemise: string): Promise<ArticleEtendu[]> {
    this.unites = await this.dbService.dexie.synchronisation_unites.toArray();

    const depotActif: string =
      this.environnementApplicationService.getParametre(ParametreApplicationId.ENVIRONNEMENT_DEPOT) ?? '';
    const depot: SynchronisationInfoDepot | undefined = await this.dbService.dexie.synchronisation_info_depots.get(
      depotActif || ''
    );

    const articles = (
      await this.dbService.dexie.synchronisation_articles.where('classeRemise').equals(classeRemise).toArray()
    ).filter((article) => {
      const tableauDatesFinValiditeDepots = article.listeDatesFinValiditesDepots.split(';');
      const ajd: Date = new Date();
      const annee = ajd.getFullYear();
      const mois = (ajd.getMonth() + 1).toString().padStart(2, '0');
      const jour = ajd.getDate().toString().padStart(2, '0');
      const aujourdhui: string = `${annee}${mois}${jour}`;
      const dateFinValidite = Number(
        tableauDatesFinValiditeDepots[(depot?.indiceValidite ?? 1) - 1].replaceAll('-', '')
      );

      return (
        true &&
        (article.uniteAchat === unite ||
          article.uniteDivers1 === unite ||
          article.uniteDivers2 === unite ||
          article.uniteReference === unite ||
          article.uniteStockage === unite ||
          article.uniteVente === unite ||
          article.unitePalette === unite) &&
        (depot && depot.indiceValidite && dateFinValidite ? dateFinValidite > Number(aujourdhui) : true)
      );
    });

    const articlesEtendus: ArticleEtendu[] = [];

    articles.forEach((article) => {
      if (this.unites) {
        const articleEtendu = this.articleService.etendreArticle(article, []);
        articlesEtendus.push(articleEtendu);
      }
    });
    return articlesEtendus;
  }

  private obtenirConditionnements(article: SynchronisationArticle): void {
    this.conditionnements = [];
    this.ajouterConditionnementUnique(article.uniteAchat, article.uniteAchatCoefficient);
    this.ajouterConditionnementUnique(article.uniteReference, article.uniteReferenceCoefficient);
    this.ajouterConditionnementUnique(article.uniteStockage, article.uniteStockageCoefficient);
    this.ajouterConditionnementUnique(article.uniteVente, article.uniteVenteCoefficient);
    this.ajouterConditionnementUnique(article.uniteDivers1, article.uniteDivers1Coefficient);
    this.ajouterConditionnementUnique(article.uniteDivers2, article.uniteDivers2Coefficient);
    this.ajouterConditionnementUnique(article.unitePalette, article.unitePaletteCoefficient);
    this.conditionnements.sort((a, b) => a.coefficient - b.coefficient);
  }

  private ajouterConditionnementUnique(unite: string | undefined, coefficient: number | undefined): void {
    if (unite && coefficient !== undefined && !this.conditionnements.some((cond) => cond.unite === unite)) {
      this.conditionnements.push({ unite, coefficient });
    }
  }

  private obtenirCoefficientParUnite(unite: string): number | undefined {
    const conditionnement = this.conditionnements.find((cond) => cond.unite === unite);
    return conditionnement ? conditionnement.coefficient : undefined;
  }

  public async supprimerLigne(ligne: PanierLigneEtendu): Promise<void> {
    if (this.listePanierActif.lignes) {
      const indexDansPanierActif = this.listePanierActif.lignes.indexOf(ligne);
      if (indexDansPanierActif >= 0) {
        this.listePanierActif.lignes.splice(indexDansPanierActif, 1);
        this.listePanierActif.lignes = [...this.listePanierActif.lignes];
      }
    }

    if (ligne.idIndexedDB) {
      const ligneId = ligne.idIndexedDB;
      await this.dbService.dexie.transaction(
        'rw',
        this.dbService.dexie.paniers_lignes,
        this.dbService.dexie.paniers_commentaires,
        this.dbService.dexie.paniers_notes,
        async () => {
          const ligneBDD: PanierLigneApp | undefined = await this.dbService.dexie.paniers_lignes.get(ligneId);
          if (ligneBDD?.id) {
            await this.dbService.dexie.paniers_lignes.put({ ...ligneBDD, supprimee: true });
            await this.dbService.dexie.paniers_commentaires
              .where({ panierLigneIdIndexedDB: ligneId })
              .modify({ supprimee: true });
            await this.dbService.dexie.paniers_notes
              .where({ panierLigneIdIndexedDB: ligneId })
              .modify({ supprimee: true });
          } else {
            await this.dbService.dexie.paniers_lignes.delete(ligneId);
            await this.dbService.dexie.paniers_commentaires.where({ panierLigneIdIndexedDB: ligneId }).delete();
            await this.dbService.dexie.paniers_notes.where({ panierLigneIdIndexedDB: ligneId }).delete();
          }
        }
      );
    }
  }

  public async supprimerCommentaire(commentaireIdIndexedDB: number): Promise<void> {
    return this.dbService.dexie.paniers_commentaires.get(commentaireIdIndexedDB).then((commentaire) => {
      const commentaireBDD: PanierCommentaireApp | undefined = commentaire;
      if (commentaireBDD?.id) {
        const date: string = new Date().toISOString();
        this.dbService.dexie.paniers_commentaires.put({ ...commentaireBDD, supprimee: true, dateModif: date });
      } else {
        this.dbService.dexie.paniers_commentaires.delete(commentaireIdIndexedDB);
      }
    });
  }

  public async supprimerNote(noteIdIndexedDB: number): Promise<void> {
    return this.dbService.dexie.paniers_notes.get(noteIdIndexedDB).then((note) => {
      const noteBDD: PanierNoteApp | undefined = note;
      if (noteBDD?.id) {
        const date: string = new Date().toISOString();
        this.dbService.dexie.paniers_notes.put({ ...noteBDD, supprimee: true, dateModif: date });
      } else {
        this.dbService.dexie.paniers_notes.delete(noteIdIndexedDB);
      }
    });
  }

  public supprimerPanier(panierIdIndexedDB: number): Promise<void> {
    return this.dbService.dexie.transaction(
      'rw',
      this.dbService.dexie.paniers_entetes,
      this.dbService.dexie.paniers_lignes,
      this.dbService.dexie.paniers_commentaires,
      this.dbService.dexie.paniers_notes,
      async () => {
        const panierBDD: PanierEnteteApp | undefined = await this.dbService.dexie.paniers_entetes.get(
          panierIdIndexedDB
        );
        if (panierBDD?.id) {
          const date: string = new Date().toISOString();
          await this.dbService.dexie.paniers_entetes.put({ ...panierBDD, supprimee: true, dateModif: date });
          await this.dbService.dexie.paniers_lignes
            .where({ panierIdIndexedDB: panierIdIndexedDB })
            .modify({ supprimee: true, dateModif: date });
          await this.dbService.dexie.paniers_commentaires
            .where({ panierIdIndexedDB: panierIdIndexedDB })
            .modify({ supprimee: true, dateModif: date });
          await this.dbService.dexie.paniers_notes
            .where({ panierIdIndexedDB: panierIdIndexedDB })
            .modify({ supprimee: true, dateModif: date });
        } else {
          await this.dbService.dexie.paniers_entetes.delete(panierIdIndexedDB);
          await this.dbService.dexie.paniers_lignes.where({ panierIdIndexedDB: panierIdIndexedDB }).delete();
          await this.dbService.dexie.paniers_commentaires.where({ panierIdIndexedDB: panierIdIndexedDB }).delete();
          await this.dbService.dexie.paniers_notes.where({ panierIdIndexedDB: panierIdIndexedDB }).delete();
        }
      }
    );
  }

  public async creerCommentaire(
    panierIdIndexedDB: number,
    texte: string,
    autre?: Partial<PanierCommentaireApp>
  ): Promise<PanierCommentaireApp> {
    let commentaire: PanierCommentaireApp = new PanierCommentaireApp(panierIdIndexedDB, texte);

    const params: ProfilUtilisateur | undefined = this.environnementApplicationService.getParametre(
      ParametreApplicationId.PROFIL_UTILISATEUR
    );
    if (!params) throw new Error('Paramètres utilisateur introuvables');

    commentaire = { ...commentaire, ...autre, utilisateurCreation: params.login, utilisateurModif: params.login };
    const id: number = await this.dbService.dexie.paniers_commentaires.add(commentaire);
    commentaire.idIndexedDB = id;

    return commentaire;
  }

  public async creerNote(
    panierIdIndexedDB: number,
    texte: string,
    autre?: Partial<PanierNoteApp>
  ): Promise<PanierNoteApp> {
    let note: PanierNoteApp = new PanierNoteApp(panierIdIndexedDB, texte);

    const params: ProfilUtilisateur | undefined = this.environnementApplicationService.getParametre(
      ParametreApplicationId.PROFIL_UTILISATEUR
    );
    if (!params) throw new Error('Paramètres utilisateur introuvables');

    note = { ...note, ...autre, utilisateurCreation: params.login, utilisateurModif: params.login };
    const id: number = await this.dbService.dexie.paniers_notes.add(note);
    note.idIndexedDB = id;

    return note;
  }

  public merge(
    entetes: PanierEnteteServeur[],
    lignes: PanierLigneServeur[],
    commentaires: PanierCommentaireServeur[],
    notes: PanierNoteServeur[]
  ): Promise<void> {
    return this.dbService.dexie.transaction(
      'rw',
      this.dbService.dexie.paniers_entetes,
      this.dbService.dexie.paniers_lignes,
      this.dbService.dexie.paniers_commentaires,
      this.dbService.dexie.paniers_notes,
      async () => {
        await this.mergeEntetes(entetes);
        await this.mergeLignes(entetes, lignes);
        await this.mergeCommentaires(entetes, lignes, commentaires);
        await this.mergeNotes(entetes, lignes, notes);

        const idLocauxEntetes: number[] = entetes.map((entete) => entete.idIndexedDB as number);
        const idLocauxLignes: number[] = lignes.map((ligne) => ligne.idIndexedDB as number);
        const idLocauxCommentaires: number[] = commentaires.map((commentaire) => commentaire.idIndexedDB as number);
        const idLocauxNotes: number[] = notes.map((note) => note.idIndexedDB as number);
        this.dbService.dexie.paniers_entetes
          .filter((entete) => !idLocauxEntetes.includes(entete.idIndexedDB as number))
          .delete();
        this.dbService.dexie.paniers_lignes
          .filter((ligne) => !idLocauxLignes.includes(ligne.idIndexedDB as number))
          .delete();
        this.dbService.dexie.paniers_commentaires
          .filter((commentaire) => !idLocauxCommentaires.includes(commentaire.idIndexedDB as number))
          .delete();
        this.dbService.dexie.paniers_notes
          .filter((note) => !idLocauxNotes.includes(note.idIndexedDB as number))
          .delete();
      }
    );
  }

  private async mergeEntetes(entetes: PanierEnteteServeur[]): Promise<void> {
    for (const enteteServeur of entetes) {
      const enteteLocale: PanierEnteteApp | undefined = await this.dbService.dexie.paniers_entetes
        .where({
          commercialRef: enteteServeur.commercialRef,
          clientRef: enteteServeur.clientRef,
          dateCreation: enteteServeur.createdDate,
          utilisateurCreation: enteteServeur.createdBy,
        })
        .first();

      if (enteteLocale) {
        enteteLocale.id = enteteServeur.id;
        enteteLocale.supprimee = false;
        enteteLocale.dateModif = enteteServeur.lastModifiedDate;
        enteteLocale.utilisateurModif = enteteServeur.lastModifiedBy;
        enteteLocale.codeAdresse = enteteServeur.codeAdresse;
        enteteLocale.adresseComplement1 = enteteServeur.adresseComplement1;
        enteteLocale.adresseComplement2 = enteteServeur.adresseComplement2;
        enteteLocale.rue = enteteServeur.rue;
        enteteLocale.codePostal = enteteServeur.codePostal;
        enteteLocale.ville = enteteServeur.ville;
        enteteLocale.dateLivraisonEstimee = enteteServeur.dateLivraisonEstimee;
        enteteLocale.statut = enteteServeur.statut;
        enteteLocale.typePiece = enteteServeur.typePiece;
        enteteLocale.tournee = enteteServeur.tournee;
        enteteLocale.commandeRef = enteteServeur.commandeRef;
        enteteLocale.typePiece = enteteServeur.typePiece;
        enteteLocale.depotRef = enteteServeur.depotRef;
        enteteLocale.codeTarif = enteteServeur.codeTarif;
        enteteLocale.etablissement = enteteServeur.etablissement;
        enteteLocale.dateSaisie = enteteServeur.dateSaisie;
        enteteLocale.nomClient = enteteServeur.nomClient;
        enteteServeur.idIndexedDB = enteteLocale.idIndexedDB;
        await this.dbService.dexie.paniers_entetes.put(enteteLocale);
      } else {
        const id: number = await this.dbService.dexie.paniers_entetes.put({
          id: enteteServeur.id,
          clientRef: enteteServeur.clientRef,
          commercialRef: enteteServeur.commercialRef,
          dateCreation: enteteServeur.createdDate,
          dateModif: enteteServeur.lastModifiedDate,
          utilisateurCreation: enteteServeur.createdBy,
          utilisateurModif: enteteServeur.lastModifiedBy,
          codeAdresse: enteteServeur.codeAdresse,
          adresseComplement1: enteteServeur.adresseComplement1,
          adresseComplement2: enteteServeur.adresseComplement2,
          rue: enteteServeur.rue,
          codePostal: enteteServeur.codePostal,
          ville: enteteServeur.ville,
          dateLivraisonEstimee: enteteServeur.dateLivraisonEstimee,
          statut: enteteServeur.statut,
          typePiece: enteteServeur.typePiece,
          tournee: enteteServeur.tournee,
          commandeRef: enteteServeur.commandeRef,
          supprimee: false,
          depotRef: enteteServeur.depotRef,
          codeTarif: enteteServeur.codeTarif,
          etablissement: enteteServeur.etablissement,
          dateSaisie: enteteServeur.dateSaisie,
          nomClient: enteteServeur.nomClient,
          selectionne: false,
        });
        enteteServeur.idIndexedDB = id;
      }
    }
  }

  private async mergeLignes(entetes: PanierEnteteServeur[], lignes: PanierLigneServeur[]): Promise<void> {
    for (const ligneServeur of lignes) {
      const panierLocal: PanierEnteteServeur | undefined = entetes.find(
        (entete) => entete.id === ligneServeur.panierEnteteId
      );
      if (!panierLocal) throw new Error('Panier introuvable');
      const ligneLocale: PanierLigneApp | undefined = await this.dbService.dexie.paniers_lignes
        .where({
          panierId: ligneServeur.panierEnteteId,
          articleRef: ligneServeur.articleRef,
          uniteVente: ligneServeur.uniteVente,
          dateCreation: ligneServeur.createdDate,
          utilisateurCreation: ligneServeur.createdBy,
        })
        .first();
      if (ligneLocale) {
        ligneLocale.id = ligneServeur.id;
        ligneLocale.supprimee = false;
        ligneLocale.dateModif = ligneServeur.lastModifiedDate;
        ligneLocale.utilisateurModif = ligneServeur.lastModifiedBy;
        ligneLocale.quantite = ligneServeur.quantite;
        ligneLocale.qteGratuit = ligneServeur.qteGratuit;
        ligneLocale.prixClavier = ligneServeur.prixClavier;
        ligneLocale.prixUniteBase = ligneServeur.prixUniteBase;
        ligneLocale.uniteReference = ligneServeur.uniteReference;
        ligneLocale.coefVente = ligneServeur.coefVente;
        ligneLocale.motifGratuiteQuantite = ligneServeur.motifGratuiteQuantite;
        ligneLocale.motifGratuitePrixClavier = ligneServeur.motifGratuitePrixClavier;
        ligneServeur.idIndexedDB = ligneLocale.idIndexedDB;
        ligneServeur.panierIdIndexedDB = ligneLocale.panierIdIndexedDB;
        ligneLocale.aRecalculer = true;

        await this.dbService.dexie.paniers_lignes.put(ligneLocale);
      } else {
        const id: number = await this.dbService.dexie.paniers_lignes.put({
          id: ligneServeur.id,
          panierIdIndexedDB: panierLocal.idIndexedDB,
          panierId: ligneServeur.panierEnteteId,
          articleRef: ligneServeur.articleRef,
          uniteVente: ligneServeur.uniteVente,
          quantite: ligneServeur.quantite,
          dateCreation: ligneServeur.createdDate,
          dateModif: ligneServeur.lastModifiedDate,
          utilisateurCreation: ligneServeur.createdBy,
          utilisateurModif: ligneServeur.lastModifiedBy,
          supprimee: false,
          qteGratuit: ligneServeur.qteGratuit,
          prixClavier: ligneServeur.prixClavier,
          prixUniteBase: ligneServeur.prixUniteBase ?? 0,
          prixUniteBaseEstNet: false,
          uniteReference: ligneServeur.uniteReference ?? '',
          coefVente: ligneServeur.coefVente ?? 0,
          montantTotalLigne: ligneServeur.montantTotalLigne ?? 0,
          prixUniteBaseRemise: 0,
          motifGratuiteQuantite: ligneServeur.motifGratuiteQuantite ?? '',
          motifGratuitePrixClavier: ligneServeur.motifGratuitePrixClavier ?? '',
          gratuiteAuto: ligneServeur.gratuiteAuto,
          articleRefGratuitChoisi: ligneServeur.articleRefGratuitChoisi,
          idParent: ligneServeur.idParent,
          idCreation: ligneServeur.idCreation ?? null,
          remiseAppliquee: ligneServeur.remiseAppliquee ?? null,
          exclureGratuite: ligneServeur.exclureGratuite ?? false,
          aRecalculer: true,
          quantiteGratuitEtendu: null,
          remiseQteAppliquee: ligneServeur.remiseQteAppliquee ?? null,
          tarifPourUnite: null,
          tarifPourUniteRemise: null,
          consignePourUnite: null,
          articleDesignation: '',
        });
        ligneServeur.idIndexedDB = id;
        ligneServeur.idCreation = id;
        ligneServeur.panierIdIndexedDB = panierLocal?.idIndexedDB;
      }
    }
  }

  private async mergeCommentaires(
    entetes: PanierEnteteServeur[],
    lignes: PanierLigneServeur[],
    commentaires: PanierCommentaireServeur[]
  ): Promise<void> {
    for (const commentaireServeur of commentaires) {
      const panierLocal: PanierEnteteServeur | undefined = entetes.find(
        (entete) => entete.id === commentaireServeur.panierEnteteId
      );
      if (!panierLocal) throw new Error('Panier introuvable');
      let ligneLocale: PanierLigneServeur | undefined | null = null;
      if (commentaireServeur.panierLigneId) {
        ligneLocale = lignes.find((ligne) => ligne.id === commentaireServeur.panierLigneId);
        if (!ligneLocale) throw new Error('Ligne introuvable');
      }

      const commentairesLocaux: PanierCommentaireApp[] = await this.dbService.dexie.paniers_commentaires
        .where({
          panierId: commentaireServeur.panierEnteteId,
          dateCreation: commentaireServeur.createdDate,
          utilisateurCreation: commentaireServeur.createdBy,
        })
        .toArray();
      const commentaireLocal: PanierCommentaireApp | undefined = commentaireServeur.panierLigneId
        ? commentairesLocaux.find((commentaire) => commentaire.panierLigneId === commentaireServeur.panierLigneId)
        : commentairesLocaux.find((commentaire) => !commentaire.panierLigneId);

      if (commentaireLocal) {
        commentaireLocal.id = commentaireServeur.id;
        commentaireLocal.supprimee = false;
        commentaireLocal.dateModif = commentaireServeur.lastModifiedDate;
        commentaireLocal.utilisateurModif = commentaireServeur.lastModifiedBy;
        commentaireLocal.texte = commentaireServeur.texte;
        commentaireServeur.idIndexedDB = commentaireLocal.idIndexedDB;
        commentaireServeur.panierIdIndexedDB = commentaireLocal.panierIdIndexedDB;
        commentaireServeur.panierLigneIdIndexedDB = commentaireLocal.panierLigneIdIndexedDB;
        await this.dbService.dexie.paniers_commentaires.put(commentaireLocal);
      } else {
        const id: number = await this.dbService.dexie.paniers_commentaires.put({
          id: commentaireServeur.id,
          panierIdIndexedDB: panierLocal.idIndexedDB,
          panierLigneIdIndexedDB: ligneLocale?.idIndexedDB,
          panierId: commentaireServeur.panierEnteteId,
          panierLigneId: commentaireServeur.panierLigneId,
          texte: commentaireServeur.texte,
          dateCreation: commentaireServeur.createdDate,
          dateModif: commentaireServeur.lastModifiedDate,
          utilisateurCreation: commentaireServeur.createdBy,
          utilisateurModif: commentaireServeur.lastModifiedBy,
          supprimee: false,
        });
        commentaireServeur.idIndexedDB = id;
      }
    }
  }

  private async mergeNotes(
    entetes: PanierEnteteServeur[],
    lignes: PanierLigneServeur[],
    notes: PanierNoteServeur[]
  ): Promise<void> {
    for (const noteServeur of notes) {
      const panierLocal: PanierEnteteServeur | undefined = entetes.find(
        (entete) => entete.id === noteServeur.panierEnteteId
      );
      if (!panierLocal) throw new Error('Panier introuvable');
      let ligneLocale: PanierLigneServeur | undefined | null = null;
      if (noteServeur.panierLigneId) {
        ligneLocale = lignes.find((ligne) => ligne.id === noteServeur.panierLigneId);
        if (!ligneLocale) throw new Error('Ligne introuvable');
      }

      const notesLocales: PanierNoteApp[] = await this.dbService.dexie.paniers_notes
        .where({
          panierId: noteServeur.panierEnteteId,
          dateCreation: noteServeur.createdDate,
          utilisateurCreation: noteServeur.createdBy,
        })
        .toArray();
      const noteLocale: PanierCommentaireApp | undefined = noteServeur.panierLigneId
        ? notesLocales.find((note) => note.panierLigneId === noteServeur.panierLigneId)
        : notesLocales.find((note) => !note.panierLigneId);

      if (noteLocale) {
        noteLocale.id = noteServeur.id;
        noteLocale.supprimee = false;
        noteLocale.dateModif = noteServeur.lastModifiedDate;
        noteLocale.utilisateurModif = noteServeur.lastModifiedBy;
        noteLocale.texte = noteServeur.texte;
        noteServeur.idIndexedDB = noteLocale.idIndexedDB;
        noteServeur.panierIdIndexedDB = noteLocale.panierIdIndexedDB;
        noteServeur.panierLigneIdIndexedDB = noteLocale.panierLigneIdIndexedDB;
        await this.dbService.dexie.paniers_notes.put(noteLocale);
      } else {
        const id: number = await this.dbService.dexie.paniers_notes.put({
          id: noteServeur.id,
          panierIdIndexedDB: panierLocal.idIndexedDB,
          panierLigneIdIndexedDB: ligneLocale?.idIndexedDB,
          panierId: noteServeur.panierEnteteId,
          panierLigneId: noteServeur.panierLigneId,
          texte: noteServeur.texte,
          dateCreation: noteServeur.createdDate,
          dateModif: noteServeur.lastModifiedDate,
          utilisateurCreation: noteServeur.createdBy,
          utilisateurModif: noteServeur.lastModifiedBy,
          supprimee: false,
        });
        noteServeur.idIndexedDB = id;
      }
    }
  }

  public async mettreAJourEntetePanier(panierIdIndexedDB: number, entete: Partial<PanierEnteteApp>): Promise<void> {
    const params: ProfilUtilisateur | undefined = this.environnementApplicationService.getParametre(
      ParametreApplicationId.PROFIL_UTILISATEUR
    );
    if (!params) throw new Error('Paramètres utilisateur introuvables');
    await this.dbService.dexie.paniers_entetes.update(panierIdIndexedDB, {
      ...entete,
      dateModif: new Date().toISOString(),
      utilisateurModif: params.login,
    });
    return;
  }

  public calculerTotalLignePanier(ligne: PanierLigneEtendu): number {
    const coefUnite = this.articleService.trouverCoefficientCorrespondantPanierLigne(ligne.article, ligne.uniteVente);
    const consigne: number = ligne.consignePourUnite ?? 0;
    let tarif: number = 0;
    if (!ligne.gratuiteAuto) {
      if (ligne.prixClavier !== null && ligne.prixClavier !== '') {
        tarif = Number(ligne.prixClavier);
      } else if (ligne.prixUniteBase) {
        tarif = Number(ligne.prixUniteBase);
      } else if (ligne.tarifPourUnite) {
        tarif = Number(ligne.tarifPourUnite);
      }
    }
    const totalLigne = ligne.quantite * tarif * coefUnite + ligne.quantite * consigne;
    return totalLigne;
  }

  public calculerTotalLignePanierAvecRemise(ligne: PanierLigneEtendu): number {
    const coefUnite = this.articleService.trouverCoefficientCorrespondantPanierLigne(ligne.article, ligne.uniteVente);
    const consigne: number = ligne.consignePourUnite ?? 0;
    let tarif: number = 0;
    if (!ligne.gratuiteAuto) {
      if (ligne.prixClavier !== null && ligne.prixClavier !== '') {
        tarif = Number(ligne.prixClavier);
      } else if (ligne.prixUniteBaseRemise && !ligne.exclureGratuite && !ligne.prixUniteBaseEstNet) {
        tarif = Number(ligne.prixUniteBaseRemise);
      } else if (ligne.tarifPourUniteRemise && !ligne.exclureGratuite && !ligne.prixUniteBaseEstNet) {
        tarif = Number(ligne.tarifPourUniteRemise);
      } else {
        tarif = Number(ligne.prixUniteBase);
      }
    }
    const totalLigne = (ligne.quantite - ligne.qteGratuit) * tarif * coefUnite + ligne.quantite * consigne;
    return totalLigne >= 0 ? totalLigne : -1 * totalLigne;
  }

  public calculerGratuitLignePanier(ligne: PanierLigneEtendu): number {
    const coefUnite = this.articleService.trouverCoefficientCorrespondantPanierLigne(ligne.article, ligne.uniteVente);
    const tarif: number =
      ligne.prixClavier !== null && ligne.prixClavier !== '' ? +ligne.prixClavier : ligne.tarifPourUnite ?? 0;
    const totalGratuitLigne: number = Number(ligne.qteGratuit) * tarif * coefUnite;
    return totalGratuitLigne;
  }

  public calculerGratuitLignePanierAvecRemise(ligne: PanierLigneEtendu): number {
    const coefUnite = this.articleService.trouverCoefficientCorrespondantPanierLigne(ligne.article, ligne.uniteVente);
    const tarif: number =
      ligne.prixClavier !== null && ligne.prixClavier !== '' ? +ligne.prixClavier : ligne.tarifPourUniteRemise ?? 0;
    const totalGratuitLigne: number = Number(ligne.qteGratuit) * tarif * coefUnite;
    return totalGratuitLigne;
  }

  public calculerConsigneLigne(ligne: PanierLigneEtendu): number {
    return (ligne.consignePourUnite ?? 0) * ligne.quantite;
  }
}
