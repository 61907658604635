import { Injectable } from '@angular/core';
import { LogLevel } from './level.model';
import { DbService } from '../db/db.service';
import { ParametreApplicationId } from '../db/parametre-application';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  public logLevelApp = LogLevel.Verbose;
  public logLevelConsole = LogLevel.Error;
  public logLevelWebHttp = LogLevel.Verbose;
  private logNatif = false;

  private logLevelConsoleEnv = environment.logLevelConsole;

  public logLevelConsoleReactif: Subject<LogLevel>;

  constructor(private dbService: DbService) {
    this.logNatif = Capacitor.isNativePlatform();

    if (this.logLevelConsoleEnv) {
      this.logLevelConsole = this.logLevelConsoleEnv;
    }
    this.logLevelConsoleReactif = new BehaviorSubject<LogLevel>(this.logLevelConsole);

    dbService.dexie.parametres_application
      .where('id')
      .anyOf([
        ParametreApplicationId.LOG_LEVEL_APP,
        ParametreApplicationId.LOG_LEVEL_CONSOLE,
        ParametreApplicationId.LOG_LEVEL_WEB_HTTP,
      ])
      .toArray()
      .then((parametres) => {
        for (const parametre of parametres) {
          switch (parametre.id) {
            case ParametreApplicationId.LOG_LEVEL_APP:
              this.logLevelApp = (parametre.valeur as LogLevel) || LogLevel.Verbose;
              break;
            case ParametreApplicationId.LOG_LEVEL_CONSOLE:
              this.logLevelConsole = (parametre.valeur as LogLevel) || LogLevel.Verbose;
              this.logLevelConsoleReactif.next(this.logLevelConsole);
              break;
            case ParametreApplicationId.LOG_LEVEL_WEB_HTTP:
              this.logLevelWebHttp = (parametre.valeur as LogLevel) || LogLevel.Verbose;
              break;
          }
        }
      });
  }

  // Objectif Ensuite
  // - Faire un composant pour gérer
  //          le niveau de log
  //          l'écriture ou non dans un fichier externe dans dans le local storage (attention épuration)
  // Un item par date et par type de log ?
  //         Stocker les logs dans une variable
  // - Faire un composant pour afficher le contenu du localStorage pour afficher les logs ?

  public verbose(msg: string | (() => string)): void {
    this.logWith(LogLevel.Verbose, msg);
  }

  public info(msg: string | (() => string)): void {
    this.logWith(LogLevel.Info, msg);
  }

  public warn(msg: string | (() => string)): void {
    this.logWith(LogLevel.Warn, msg);
  }

  public error(msg: string | (() => string)): void {
    this.logWith(LogLevel.Error, msg);
  }

  private formaterMessage(level: LogLevel, msg: string | (() => string)): [string, string] {
    if (typeof msg === 'function') {
      msg = msg();
    }

    const date = new Date();
    const dateStr = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    let expressionNiveauLog = '';
    switch (level) {
      case LogLevel.Info:
        expressionNiveauLog = '   INFO';
        break;
      case LogLevel.Warn:
        expressionNiveauLog = '   WARN';
        break;
      case LogLevel.Error:
        expressionNiveauLog = '  ERROR';
        break;
      case LogLevel.Verbose:
        expressionNiveauLog = 'VERBOSE';
        break;
    }

    return [`[${dateStr}] [${expressionNiveauLog}]`, msg];
  }

  private logWith(level: LogLevel, msg: string | (() => string)): void {
    let message: [string, string] | null = null;

    if (level <= this.logLevelApp) {
      if (!message) message = this.formaterMessage(level, msg);
      this.logToLocalStorage(level, ...message);
    }

    // Envoi à un WS
    if (level <= this.logLevelWebHttp) {
      if (!message) message = this.formaterMessage(level, msg);
      this.logToHttp(level, ...message);
    }

    // Log dans la console si activé
    if (level <= this.logLevelConsole) {
      if (!message) message = this.formaterMessage(level, msg);
      if (this.logNatif) {
        this.logToConsoleSansStyle(level, ...message);
      } else {
        this.logToConsole(level, ...message);
      }
    }
  }

  private logToLocalStorage(level: LogLevel, pre: string, msg: string): void {
    return;
  }

  private logToHttp(level: LogLevel, pre: string, msg: string): void {
    return;
  }

  private logToConsole(level: LogLevel, pre: string, msg: string): void {
    switch (level) {
      case LogLevel.None:
        return;
      case LogLevel.Info:
        return console.log(
          '%c' + pre,
          'background-color: #2196F3; color: white; padding: 2px 6px; border-radius: 2px; font-weight: bold;',
          msg
        );
      case LogLevel.Warn:
        return console.log(
          '%c' + pre,
          'background-color: #FFC107; color: black; padding: 2px 6px; border-radius: 2px; font-weight: bold;',
          msg
        );
      case LogLevel.Error:
        return console.log(
          '%c' + pre,
          'background-color: #FF5722; color: white; padding: 2px 6px; border-radius: 2px; font-weight: bold;',
          msg
        );
      case LogLevel.Verbose:
        return console.log(
          '%c' + pre,
          'background-color: #9E9E9E; color: white; padding: 2px 6px; border-radius: 2px; font-weight: bold;',
          msg
        );
    }
  }

  private logToConsoleSansStyle(level: LogLevel, pre: string, msg: string): void {
    switch (level) {
      case LogLevel.None:
        return;
      case LogLevel.Info:
        return console.info(pre + ' ' + msg);
      case LogLevel.Warn:
        return console.warn(pre + ' ' + msg);
      case LogLevel.Error:
        return console.error(pre + ' ' + msg);
      case LogLevel.Verbose:
        return console.log(pre + ' ' + msg);
    }
  }

  public async setLogLevelConsole(niveau: LogLevel): Promise<void> {
    this.logLevelConsole = niveau;
    this.logLevelConsoleReactif.next(niveau);
    try {
      await this.dbService.dexie.parametres_application.put({
        id: ParametreApplicationId.LOG_LEVEL_CONSOLE,
        valeur: niveau,
      });
    } catch (e) {
      this.error("Impossible d'enregistrer le niveau de log : " + e);
    }
  }
}
