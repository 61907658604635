import { Injectable } from '@angular/core';

export type ObjetIndexable = { [index: string]: unknown };

@Injectable({
  providedIn: 'root'
})
export class UtilitaireGeneralService {

  public strNormaliser(str: string): string {
    if (!str) return "";
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().trim();
  }

  public strEgalSouple(str1: string, str2: string): boolean {
    return this.strNormaliser(str1) === this.strNormaliser(str2);
  }

  public strContientSouple(str1: string, recherche: string): boolean {
    const zoneRecherchee: string = this.strNormaliser(str1);
    const motsRecherche: string[] = recherche.split(" ").map(this.strNormaliser);

    if (!zoneRecherchee || motsRecherche.length === 0) return false;
    for (const motRecherche of motsRecherche) {
        if (!zoneRecherchee.includes(motRecherche)) {
          return false;
        }
    }
    return true;
  }

  public strContientSoupleBidirectionnel(str1: string, str2: string): boolean {
    const str1Norm = this.strNormaliser(str1);
    const str2Norm = this.strNormaliser(str2);

    if (!str1Norm && !str2Norm) return false;
    if (!str1Norm && str2Norm) return false;
    if (str1Norm && !str2Norm) return false;

    return str1Norm.includes(str2Norm) || str2Norm.includes(str1Norm);
  }

  public transformerStringEnBlob(data: string): Blob {
    return new Blob([data], { type: 'application/json' });
  }

  public convertirTailleOctetsEnString(tailleOctets: number): string {
    if (!tailleOctets) return "";
    const unite: string[] = [" octets", "Ko", "Mo", "Go", "To"];
    let index: number = 0;
    let octets: number = tailleOctets;

    while (octets >= 1024 && index < unite.length - 1) {
      octets /= 1024;
      index++;
    }

    return octets.toFixed(2) + unite[index];
  }

  public groupBy<T, X>(tab: T[], methode: (obj: T) => string, methodeMap?: (obj: T) => X): Map<string, X[]> {
    const map: Map<string, X[]> = new Map();
    for (let i = 0; i < tab.length; i++) {
      const tabElement: T = tab[i];
      const key: string = methode(tabElement);
      if (!map.has(key)) {
        map.set(key, []);
      } else {
        if (methodeMap) map.get(key)?.push(methodeMap(tabElement));
        else map.get(key)?.push(tabElement as unknown as X);
      }
    }
    return map;
  }

  public joursJavascriptVersJoursSemaine(jour: number): number {
    return jour === 0 ? 7 : jour;
  }

  public decalerJours(date: Date, nbJours: number): Date {
    const nouvelleDate: Date = new Date(date.getTime());
    nouvelleDate.setDate(nouvelleDate.getDate() + nbJours);
    return nouvelleDate;
  }

  public formatDateStr(dateStr: string): string {
    const date = new Date(dateStr);
    return date.toDateString();
  }

  public formatHeureStr(date: string): string {
    const heure = date.substring(0, 2);
    const minute = date.substring(2, 4);
    return heure + ':' + minute;
  }

  public formatHeureStrEvtNonSynchronise(date: string): string {
    const heure = date.substring(11, 16);
    return heure;
  }

  public formatHeure(date: Date): string {
    const heures = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const secondes = date.getSeconds().toString().padStart(2, '0');
    return `${heures}${minutes}${secondes}`;
  }

  public formatDate(date: Date): string {
    const annee = date.getFullYear();
    const mois = (date.getMonth() + 1).toString().padStart(2, '0');
    const jour = date.getDate().toString().padStart(2, '0');
    return `${annee}-${mois}-${jour}`;
  }

  public estNavigateurMobile(): boolean {
    const ua = navigator.userAgent || (navigator as { vendor?: string })['vendor'] || (window as { opera?: string })['opera'] || ''
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(ua)
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(ua.substring(0,4));
  }
}
