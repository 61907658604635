import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { SynchronisationArticle, SynchronisationClient, SynchronisationUnite, TarifSynchronise } from '../synchronisation/synchronisation';
import { UtilitaireGeneralService } from '../utilitaire/utilitaire-general.service';
import { SynchronisationStockArticle } from '../synchronisation-quotidienne/synchronisation-quotidienne';
import { UtilitaireCrmService } from '../utilitaire/utilitaire-crm.service';
import { AppConfigService } from '../configuration/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  constructor(
    private httpService: HttpService,
    private appConfig: AppConfigService,
    private utilitaireGeneralService: UtilitaireGeneralService,
    private utilitaireCrmService: UtilitaireCrmService,
  ) { }

  public urlImage(image?: string): string {
    if (!image) return "";
    return this.httpService.urlBaseRessources + "business/crm/image/" + (image ? image : "");
  }

  public urlFichier(typeFichier: string, codeFichier: string | number): string {
    if (!typeFichier) return "";
    return `${this.httpService.urlBase}business/crm/fichier/${typeFichier}/${codeFichier}`;
  }

  public urlImageMiseEnAvant(mea?: number): string {
    return this.httpService.urlBaseRessources + "business/crm/image-mise-en-avant/" + mea;
  }

  public filtreClient(client: SynchronisationClient, recherche: string): boolean {
    return this.utilitaireGeneralService.strContientSouple(`${client.titreRef} ${client.nomClient}`, recherche)
      || this.utilitaireGeneralService.strContientSouple(client.ville, recherche)
      || this.utilitaireGeneralService.strContientSouple(client.codePostal, recherche)
      || this.utilitaireGeneralService.strContientSouple(client.rue, recherche)
      || this.utilitaireGeneralService.strContientSouple(client.tiers, recherche)
      || this.utilitaireGeneralService.strContientSouple(client.nomAbrege, recherche)
  }

  public recupererTarifPourUnite(article: SynchronisationArticle, unite: string, tarif?: TarifSynchronise): number | undefined {
    if(!tarif) return undefined;
    const coefficients = {
      [article.uniteReference]: 1,
      [article.uniteAchat]: article.uniteAchatCoefficient,
      [article.uniteVente]: article.uniteVenteCoefficient,
      [article.uniteStockage]: article.uniteStockageCoefficient,
      [article.uniteDivers1]: article.uniteDivers1Coefficient,
      [article.uniteDivers2]: article.uniteDivers2Coefficient,
      [article.unitePalette]: article.unitePaletteCoefficient,
    }

    const coefficient: number | undefined = coefficients[unite];
    if (!coefficient) return undefined;

    return tarif.prix * coefficient;
  }

  public recupererConsignePourUnite(article: SynchronisationArticle, unite: string): number | undefined {
    const prix = {
      [article.uniteReference]: article.uniteReferenceConsigne,
      [article.uniteAchat]: article.uniteAchatConsigne,
      [article.uniteVente]: article.uniteVenteConsigne,
      [article.uniteStockage]: article.uniteStockageConsigne,
      [article.uniteDivers1]: article.uniteDivers1Consigne,
      [article.uniteDivers2]: article.uniteDivers2Consigne,
      [article.unitePalette]: article.unitePaletteConsigne,
    }

    return prix[unite];
  }
}
