export const dateVersTexteISOAvecGMT = (date: Date): string => {
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
};

export const dateVersTexteISOAvecGMTAmeliore = (date: Date): string => {
  const offsetMinutes = date.getTimezoneOffset();
  const offsetMilliSeconds = offsetMinutes * 60000;
  const dateAvecOffset = new Date(date.getTime() - offsetMilliSeconds);
  const dateString = dateAvecOffset.toISOString();
  const correctedDateString = dateString.substring(0, 19);
  return correctedDateString;
};



export const dateAvecHeureDiva = (date: string, heure: string) => {
  let heureSplit = "";
  if (heure.trim() !== "" && heure !== "0") {
    const h = heure.split("");
    heureSplit = `${h[0]}${h[1]}:${h[2]}${h[3]}`;
  } else {
    heureSplit = "00:00"
  }
  return new Date(`${date}T${heureSplit}`);
}

export function inspecterObjet(obj: unknown, prop: string | string[]): unknown {
  const tableauProp = Array.isArray(prop) ? prop : [prop];
  if (tableauProp.length == 0) return obj;
  if (typeof obj === 'object' && obj !== null) {
    if (tableauProp[0] in obj) {
      return inspecterObjet((obj as Record<string, unknown>)[tableauProp[0]], tableauProp.slice(1));
    }
  }
  return undefined;
}