export interface InfosArticlesFamille {
  familleRef: string;
  indiceValiditeDepot: number;
  niveau: number;
  articlesDisponibles: boolean;
  contenance?: Fourchette;
  volumeAlcool?: Fourchette;
  couleur?: string[];
  millesime?: number[];
  typeTeteFut?: string[];
}

export function etendreEnsemble<T>(ensemble: T[] | undefined, valeur: T | undefined): T[] | undefined {
  if (valeur === undefined) {
    return ensemble;
  }

  if (ensemble === undefined) {
    ensemble = [valeur];
  } else {
    insererDansEnsemble(ensemble, valeur, 0, ensemble.length);
  }
  return ensemble;
}

function insererDansEnsemble<T>(ensemble: T[], valeur: T, debut: number, fin: number): void {
  if (debut === fin) {
    ensemble.splice(debut, 0, valeur);
  } else {
    const midx = debut + Math.floor((fin - debut) / 2);
    const mval = ensemble[midx];
    if (valeur > mval) {
      insererDansEnsemble(ensemble, valeur, midx + 1, fin);
    } else if (valeur < mval) {
      insererDansEnsemble(ensemble, valeur, debut, midx);
    }
  }
}

export interface Fourchette {
  mini: number;
  maxi: number;
}

export function etendreFourchette(
  fourchette: Fourchette | undefined,
  valeur: number | undefined
): Fourchette | undefined {
  if (valeur === undefined) {
    return fourchette;
  }

  if (fourchette === undefined) {
    fourchette = { mini: valeur, maxi: valeur };
  } else {
    fourchette.mini = Math.min(fourchette.mini, valeur);
    fourchette.maxi = Math.max(fourchette.maxi, valeur);
  }
  return fourchette;
}

export function ecart(fourchette: Fourchette | undefined): number {
  if (fourchette === undefined) {
    return 0;
  } else {
    return fourchette.maxi - fourchette.mini;
  }
}

export function estEntre(valeur: number | undefined, fourchette: Fourchette | undefined): boolean {
  if (fourchette === undefined) {
    return true; // pas de fourchette, on accepte n'importe quelle valeur
  } else if (valeur === undefined) {
    return false; // pas de valeur, elle n'est jamais prise dans la fourchette
  } else {
    return fourchette.mini <= valeur && valeur <= fourchette.maxi;
  }
}
