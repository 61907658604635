type FonctionCalcul<T, A extends Array<unknown>> = (...args: A) => T;

function argumentsIdentiques<A extends Array<unknown>>(args1: A, args2: A): boolean {
  if (args1.length !== args2.length) return false;
  return args1.every((element, index) => element === args2[index]);
}

export class Lazy<T, A extends Array<unknown>> {
  private _calcul: FonctionCalcul<T, A>;
  private _valeur?: T;
  private _parametres?: A;

  public constructor(calcul: FonctionCalcul<T, A>) {
    this._calcul = calcul;
  }

  public pour(...args: A): T {
    if (this._parametres === undefined || !argumentsIdentiques(this._parametres, args)) {
      // premier calcul ou parametres modifiés
      this._parametres = args;
      this._valeur = this._calcul(...args);
    }
    return this._valeur as T; // le type T peut accepter undefined
  }
}
