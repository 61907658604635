import { registerPlugin } from '@capacitor/core';

export interface PluginKalico {
  ping(options: { ping: string }): Promise<{ pong: string }>;

  recupererRefreshToken(): Promise<{ refreshToken: string | null }>;
  enregistrerRefreshToken(options: { refreshToken: string }): Promise<void>;
  effacerRefreshToken(): Promise<void>;
}

export const OutilsNatifsKalico = registerPlugin<PluginKalico>('Kalico', {
  web: () => import('../specifique/web').then((mod) => new mod.PluginKalicoWeb()),
});
