import { Injectable } from '@angular/core';
import type { Token } from '../login/dto/token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public recupererToken(): string | null {
    return localStorage.getItem('token');
  }

  public enregistrerToken(token: Token | null): void {
    if (!token?.id_token) {
      localStorage.removeItem('token');
    } else {
      localStorage.setItem('token', token.id_token);
    }
  }
}
