import { ContactsService } from './../../../services/contacts/contacts.service';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { EvenementsService } from 'src/app/services/evenements/evenements.service';
import { EvenementApp } from 'src/app/services/evenements/evenements';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { StatusTache, SynchronisationClient, SynchronisationEvenement } from 'src/app/services/synchronisation/synchronisation';
import { DbService } from 'src/app/services/db/db.service';
import { dateVersTexteISOAvecGMT, dateVersTexteISOAvecGMTAmeliore } from 'src/app/tools/Fonctions/fonctions';
import { QuillModule } from 'ngx-quill';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { RechercheClientComponent } from '../recherche-client/recherche-client.component';
import { ParametresUtilisateurCRM } from 'src/app/services/crm/crm';
import { SynchronisationInfoDepot } from 'src/app/services/synchronisation-quotidienne/synchronisation-quotidienne';
import { subDays } from 'date-fns';
import { ContactApp } from 'src/app/services/contacts/contacts';
import { ContactEtendu } from '../../crm/clients/detail-client/detail-client-contacts/detail-client-contacts.component';

enum Vue {
  Tache,
  Contact
}

export enum TypeAffichage {
  TACHE,
  RENDEZ_VOUS
}
@Component({
  selector: 'app-ajout-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, QuillModule, RechercheClientComponent],
  templateUrl: './ajout-modal.component.html',
  styleUrls: ['./ajout-modal.component.scss']
})

export class AjoutModalComponent implements OnInit, AfterViewInit {
  @ViewChild("ajoutModifTache") public ajoutModifTache!: TemplateRef<void>;
  @ViewChild("ajoutModifContact") public ajoutModifContact!: TemplateRef<void>;

  @Input() referenceModal!: NgbModalRef;
  @Input() tacheInput!: SynchronisationEvenement;
  @Input() contactInput!: ContactEtendu;
  @Input() vue: Vue = Vue.Tache;
  @Input() dateDebutInput?: Date;
  @Input() clientRapide?: SynchronisationClient;
  @Input() typeEvenement?: number;

  @Output() modificationEffectuee: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() suppresionEffectuee: EventEmitter<boolean> = new EventEmitter<boolean>();

  public parametresCrm: ParametresUtilisateurCRM | undefined;

  public aujourdhui: string = dateVersTexteISOAvecGMT(new Date()).substring(0, 16);
  public minDateDebut: string = dateVersTexteISOAvecGMT(subDays(new Date(), 15)).substring(0, 16);
  public minDateFin: string = "";

  public formEvenement: FormGroup<{
    typeEvenement: FormControl,
    libelleEvenement: FormControl,
    prioriteEvenement: FormControl,
    statusEvenement: FormControl,
    journeeEntiere: FormControl,
    dateDebut: FormControl,
    dateFin: FormControl,
    tierConcerne: FormControl,
  }>;
  selectedClient: SynchronisationClient | null = null;

  public listeStatus = StatusTache;

  public client = new FormControl<SynchronisationClient | null>(null);

  public emailValidator = (control: AbstractControl): { [key: string]: unknown } | null => {
    const value = control.value as string;
    if (value && !/^.+@.+\..+$/.test(value)) {
      return { 'invalidEmailFormat': true };
    }
    return null;
  };

  public telValidator = (control: AbstractControl): { [key: string]: unknown } | null => {
    const value = control.value as string;
    if (value && !/^\d+$/.test(value)) {
      return { 'chiffresSeulement': true };
    }
    return null;
  };

  public formContact = new FormGroup({
    titreContact: new FormControl(""),
    nomContact: new FormControl("", Validators.required),
    prenomContact: new FormControl(""),
    telContact: new FormControl("", this.telValidator),
    telMobileContact: new FormControl("", this.telValidator),
    emailContact: new FormControl("", this.emailValidator),
    tiersConcerne: new FormControl("", Validators.required),
    anniversaireContact: new FormControl(""),
    fonctionContact: new FormControl(""),
    destFacture: new FormControl(false),
    destReleve: new FormControl(false),
    destAvisExpe: new FormControl(false),
    destAccuseLiv: new FormControl(false),
    remarqueContact: new FormControl(""),
  });

  public action: string = "creation";

  public texteDescriptionModel = new FormControl("")

  public modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ "color": [] }],
    ]
  };

  public tache!: SynchronisationEvenement;
  public contact!: ContactEtendu;

  constructor(
    private dbService: DbService,
    private cdr: ChangeDetectorRef,
    private contactService: ContactsService,
    private evenementService: EvenementsService,
    private confirmationService: ConfirmationService,
    public toastService: ToastService,
  ) {
    this.formEvenement = new FormGroup({
      typeEvenement: new FormControl(TypeAffichage.TACHE),
      libelleEvenement: new FormControl("", Validators.required),
      prioriteEvenement: new FormControl(3),
      statusEvenement: new FormControl(1),
      journeeEntiere: new FormControl(false),
      dateDebut: new FormControl("", Validators.required),
      dateFin: new FormControl(""),
      tierConcerne: new FormControl(""),
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.tacheInput) {
      this.tache = { ...this.tacheInput };
    }

    if (this.contactInput) {
      this.contact = { ...this.contactInput };
    }

    this.texteDescriptionModel.valueChanges.pipe(distinctUntilChanged(), debounceTime(300))
      .subscribe((html) => {
        if (this.tache) this.tache.noteBlob = html as string;
      });

    this.minDateFin = this.dateMinCalendrier();

    this.formEvenement.controls.prioriteEvenement.valueChanges.subscribe((priorite) => {
      if (this.tache) {
        this.tache.priorite = Number(priorite);
      }
    });

    this.formEvenement.controls.statusEvenement.valueChanges.subscribe((status) => {
      if (this.tache && status) {
        const statusSelectionne = Object.values(this.listeStatus).find(obj => obj.valeur === +status);
        if (statusSelectionne) {
          this.changementStatus(statusSelectionne.valeur);
        }
      }
    });

    this.formEvenement.controls.libelleEvenement.valueChanges.subscribe((libelle) => {
      if (this.tache && libelle) {
        this.tache.libelleEvenement = libelle;
      }
    });

    this.formEvenement.controls.journeeEntiere.valueChanges.subscribe((jourEntier) => {
      if (!this.tache && jourEntier) {
        const now = new Date();
        const dateDebut = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 1);
        const dateFin = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59);
        this.formEvenement.controls.dateDebut.setValue(dateVersTexteISOAvecGMTAmeliore(dateDebut));
        this.formEvenement.controls.dateFin.setValue(dateVersTexteISOAvecGMTAmeliore(dateFin));
      }
      else if (this.tache && jourEntier) {
        const dateDebutActuelle = new Date(this.formEvenement.controls.dateDebut.value);
        const dateFinActuelle = new Date(this.formEvenement.controls.dateFin.value);
        const dateDebut = new Date(dateDebutActuelle.getFullYear(), dateDebutActuelle.getMonth(), dateDebutActuelle.getDate(), 0, 1);
        const dateFin = new Date(dateFinActuelle.getFullYear(), dateFinActuelle.getMonth(), dateFinActuelle.getDate(), 23, 59);
        this.formEvenement.controls.dateDebut.setValue(dateVersTexteISOAvecGMTAmeliore(dateDebut));
        this.formEvenement.controls.dateFin.setValue(dateVersTexteISOAvecGMTAmeliore(dateFin));
      }
    });

    this.formEvenement.controls.dateDebut.valueChanges.subscribe((dateDebut) => {
      if (this.tache && dateDebut) {
        this.tache.dateDebut = dateDebut.substring(0, 10);
        this.tache.heureDebut = dateDebut.substring(11, 13) + dateDebut.substring(14, 16);
      }
    });

    this.formEvenement.controls.dateFin.valueChanges.subscribe((dateFin) => {
      if (this.tache) {
        if (dateFin) {
          this.tache.finEvenement = dateFin;
        } else {
          this.tache.finEvenement = this.tache.dateDebut;
        }
      }
    });

    this.formEvenement.controls.typeEvenement.valueChanges.subscribe((type) => {
      if (this.tache && type) {
        if (type === TypeAffichage.RENDEZ_VOUS) {
          this.tache.indicationRealise = 3;
          this.tache.indicationRdv = 1;
        } else if (type === TypeAffichage.TACHE) {
          this.tache.indicationRealise = 1;
          this.tache.indicationRdv = 3;
        }
      }
    });

    this.setValeurParDefautContact();
    this.setValeurParDefautEvenement();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public async setValeurParDefautContact(): Promise<void> {
    const contact = this.contact;
    this.formContact.controls.tiersConcerne.setValue(await this.tiersParDefautContact());
    this.formContact.controls.nomContact.setValue(contact.nom ?? "");
    this.formContact.controls.prenomContact.setValue(contact.prenom ?? "");
    this.formContact.controls.titreContact.setValue(contact.titre ?? "");
    this.formContact.controls.telContact.setValue(contact.telephone ?? "");
    this.formContact.controls.telMobileContact.setValue(contact.telephoneMobile ?? "");
    this.formContact.controls.emailContact.setValue(contact.email ?? "");
    this.formContact.controls.anniversaireContact.setValue(contact.anniversaire ?? "");
    this.formContact.controls.fonctionContact.setValue(contact.libelleContact ?? "");
    this.formContact.controls.destFacture.setValue(contact.fonction1 ? true : false);
    this.formContact.controls.destReleve.setValue(contact.fonction2 ? true : false);
    this.formContact.controls.destAvisExpe.setValue(contact.fonction8 ? true : false);
    this.formContact.controls.destAccuseLiv.setValue(contact.fonction9 ? true : false);
    this.formContact.controls.remarqueContact.setValue(contact.remarque ?? "");
  }

  public async tiersParDefautContact(): Promise<string> {
    if (this.contact) {
      const client: SynchronisationClient | undefined = await this.dbService.dexie.synchronisation_clients.get(this.contact.tiers);
      this.client.setValue(client ?? null);
      return this.contact.tiers;
    } else if (this.clientRapide) {
      this.client.setValue(this.clientRapide);
      return this.clientRapide.tiers;
    }
    return "";
  }

  private async setValeurParDefautEvenement(): Promise<void> {
    this.formEvenement.controls.dateDebut.setValue(this.dateParDefautDebutEvenement());
    this.formEvenement.controls.dateFin.setValue(this.dateParDefautFinTache());
    this.formEvenement.controls.journeeEntiere.setValue(this.journeeEntiereParDefautTache());
    this.formEvenement.controls.libelleEvenement.setValue(this.libelleParDefautTache());
    this.formEvenement.controls.typeEvenement.setValue(this.typeEvenementParDefaut());
    this.formEvenement.controls.tierConcerne.setValue(await this.tiersParDefautTache());
    this.formEvenement.controls.prioriteEvenement.setValue(this.prioriteParDefautTache());
    this.formEvenement.controls.statusEvenement.setValue(this.statusParDefautTache());

    if (this.tacheInput && this.tacheInput.noteBlob) {
      this.texteDescriptionModel.setValue(this.tacheInput.noteBlob);
    }
  }

  private dateParDefautDebutEvenement(): string {
    if (this.dateDebutInput)
      return dateVersTexteISOAvecGMT(this.dateDebutInput).substring(0, 16);
    if (this.tacheInput) {
      return this.dateDebutTache();
    }
    else {
      return this.aujourdhui;
    }
  }

  private dateDebutTache(): string {
    if (this.tacheInput.dateDebut && this.tacheInput.heureDebut) {
      let jour: string = "";
      jour += this.tacheInput.dateDebut.substring(0, 10);
      jour += "T";
      jour += this.tacheInput.heureDebut.substring(0, 2);
      jour += ":";
      jour += this.tacheInput.heureDebut.substring(2, 4);
      return jour;
    }
    return "";
  }

  private dateParDefautFinTache(): string {
    if (this.tacheInput) {
      const dateF = new Date(this.tacheInput.finEvenement);
      const dateD = new Date(this.dateDebutTache());
      if (dateF < dateD) {
        return dateVersTexteISOAvecGMT(new Date(this.dateDebutTache()));
      }
      return dateVersTexteISOAvecGMT(new Date(this.tacheInput.finEvenement)).substring(0, 16);
    }
    else {
      return "";
    }
  }

  private journeeEntiereParDefautTache(): boolean {
    return false;
  }

  public libelleParDefautTache(): string {
    if (this.tacheInput) {
      return this.tacheInput.libelleEvenement;
    }
    return "";
  }

  public typeEvenementParDefaut(): number {
    if (this.tacheInput) {
      return this.convertirTypeEvenement(this.tacheInput);
    }
    else if (this.typeEvenement) {
      return this.typeEvenement;
    }
    else {
      return TypeAffichage.TACHE;
    }
  }

  public async tiersParDefautTache(): Promise<string> {
    if (this.tacheInput) {
      const client: SynchronisationClient | undefined = await this.dbService.dexie.synchronisation_clients.get(this.tacheInput.tiersRef);
      this.client.setValue(client ?? null);
      return this.tacheInput.tiersRef;
    } else if (this.clientRapide) {
      this.client.setValue(this.clientRapide);
      return this.clientRapide.tiers;
    }
    return "";
  }

  public prioriteParDefautTache(): number {
    if (this.tacheInput) {
      return this.tacheInput.priorite;
    }
    return 3;
  }

  public statusParDefautTache(): number {
    if (this.tacheInput) {
      if (this.tacheInput.indicationRealise === 3 && this.tacheInput.indicationRdv === 1 && this.tacheInput.indicationAnnule === 1) {
        return 0;
      }
      else if (this.tacheInput.indicationRealise === 3 && this.tacheInput.indicationRdv === 2 && this.tacheInput.indicationAnnule === 1) {
        return 1;
      }
      else if (this.tacheInput.indicationRealise === 1 && this.tacheInput.indicationRdv === 3 && this.tacheInput.indicationAnnule === 1) {
        return 2;
      }
      else if (this.tacheInput.indicationRealise === 2 && this.tacheInput.indicationRdv === 3 && this.tacheInput.indicationAnnule === 1) {
        return 3;
      }
      else if (this.tacheInput.indicationRealise === 3 && this.tacheInput.indicationRdv === 3 && this.tacheInput.indicationAnnule === 1) {
        return 4;
      }
      else {
        return 2;
      }
    }
    else {
      return 2;
    }
  }

  private convertirTypeEvenement(evenement: SynchronisationEvenement): number {
    if (this.evenementService.estRdv(evenement)) {
      return TypeAffichage.RENDEZ_VOUS;
    }
    if (this.evenementService.estTache(evenement)) {
      return TypeAffichage.TACHE;
    }
    return TypeAffichage.TACHE;
  }

  public saisieDateDebut(): void {
    this.minDateFin = this.dateMinCalendrier();
    const dateDebut = this.recupererDateFormulaire("dateDebut") as Date;

    if (!this.formEvenement.controls.journeeEntiere.value || this.formEvenement.controls.dateDebut.value === this.formEvenement.controls.dateFin.value) {
      dateDebut.setMinutes(dateDebut.getMinutes() + 5);
    }

    this.formEvenement.controls.dateFin.setValue(dateVersTexteISOAvecGMT(dateDebut).substring(0, 16));
  }

  public saisieDateFin(): void {
    const dateDebut = this.recupererDateFormulaire("dateDebut") as Date;
    const dateFin = this.recupererDateFormulaire("dateFin") as Date;
    if (dateDebut.getTime() > dateFin.getTime()) {
      dateDebut.setMinutes(dateDebut.getMinutes() + 5);
      this.formEvenement.controls.dateFin.setValue(dateVersTexteISOAvecGMT(dateDebut).substring(0, 16));
    }
  }

  public dateMinCalendrier(): string {
    const dateDebutValue = this.formEvenement.get("dateDebut")?.value;
    if (dateDebutValue) {
      return dateDebutValue;
    } else {
      return dateVersTexteISOAvecGMT(subDays(new Date(this.aujourdhui), 15)).substring(0, 16);
    }
  }

  public reinitiliaser(): void {
    switch (this.vue) {
      case Vue.Tache:
        this.setValeurParDefautEvenement();
        break;
      case Vue.Contact:
        this.setValeurParDefautContact();
        break;
      default:
        break;
    }
  }

  public fermer(): void {
    switch (this.vue) {
      case Vue.Tache:
        this.referenceModal.close();
        break;
      case Vue.Contact:
        this.referenceModal.close();
        break;
      default:
        this.referenceModal.close();
        break;
    }
  }

  public valider(): void {
    switch (this.vue) {
      case Vue.Tache:
        if (this.formEvenement.status === 'VALID') this.validerEvenement();
        break;
      case Vue.Contact:
        if (this.formContact.status === 'VALID') this.validerContact();
        break;
      default:
        this.referenceModal.close();
        break;
    }
  }

  public afficherVue(): TemplateRef<void> {
    switch (this.vue) {
      case Vue.Tache:
        return this.ajoutModifTache;
      case Vue.Contact:
        return this.ajoutModifContact;
      default:
        return this.ajoutModifTache;
    }
  }

  public estActif(valeur: number): boolean {
    const typeEvenement = this.formEvenement.controls.typeEvenement.getRawValue();
    switch (valeur) {
      case TypeAffichage.TACHE:
        return typeEvenement === valeur;
      case TypeAffichage.RENDEZ_VOUS:
        return typeEvenement === valeur;

      default:
        return false;
    }
  }

  public changerTypeEvenement(valeur: number): void {
    if (this.formEvenement.controls.typeEvenement.getRawValue() === valeur) return;
    if (this.tache) {
      if (valeur === TypeAffichage.TACHE) {
        this.tache.indicationRealise = 1;
        this.tache.indicationRdv = 3;
      } else if (valeur === TypeAffichage.RENDEZ_VOUS) {
        this.tache.indicationRealise = 3;
        this.tache.indicationRdv = 1;
      }
    }
    this.formEvenement.controls.typeEvenement.setValue(valeur);
  }

  private recupererDateFormulaire(nomChamp: string): Date | null {
    const valeur: string = this.formEvenement.get(nomChamp)?.value as string;
    return (valeur == "" ? null : new Date(valeur));
  }

  private recupererTiersFormulaire(nom: string | undefined): string {
    const valeur: string | undefined = nom;
    if (nom !== "" && this.formEvenement.get("tierConcerne")) {
      return valeur === "" ? "" : (nom ? nom ?? "" : "");
    } else {
      return "";
    }
  }

  public mettreAJourClient(client: SynchronisationClient | null, valeur: string) {
    if (client) {
      if (valeur === 'evenement') {
        this.formEvenement.controls.tierConcerne.setValue(client.tiers);
        if (this.tache) {
          this.tache.tiersNom = client.nomClient;
          this.tache.tiersRef = client.tiers;
        }
      }
      else if (valeur === 'contact') {
        this.formContact.controls.tiersConcerne.setValue(client.tiers);
      }
    } else {
      this.formEvenement.controls.tierConcerne.setValue("");
      this.formContact.controls.tiersConcerne.setValue("");
      if (this.tache) {
        this.tache.tiersNom = "";
        this.tache.tiersRef = "";
      }
    }
  }

  public changementStatus(valeur: number): void {
    switch (valeur) {
      case StatusTache["RDV_A_CONFIRMER"].valeur:
        this.tache.indicationRealise = 3;
        this.tache.indicationRdv = 1;
        break;

      case StatusTache["RDV_CONFIRMER"].valeur:
        this.tache.indicationRealise = 3;
        this.tache.indicationRdv = 2;
        break;

      case StatusTache["TACHE_A_REALISER"].valeur:
        this.tache.indicationRealise = 1;
        this.tache.indicationRdv = 3;
        break;

      case StatusTache["TACHE_REALISER"].valeur:
        this.tache.indicationRealise = 2;
        this.tache.indicationRdv = 3;
        break;

      case StatusTache["AUTRE"].valeur:
        this.tache.indicationRealise = 3;
        this.tache.indicationRdv = 3;
        break;

      default:
        break;
    }
  }

  public enregistrer(): void {
    if (this.tache.noteBlob) {
      this.tache.noteBlob = this.conversionBaliseHtml(this.tache.noteBlob as string);
    }
    this.evenementService.enregistrerEvenement(this.tache, this.modificationEffectuee);
    this.referenceModal.close();
  }

  public async validerEvenement(): Promise<void> {
    const depotParDefaut: SynchronisationInfoDepot | undefined = await this.dbService.dexie.synchronisation_info_depots.get(this.parametresCrm?.depotParDefautRef ?? "");
    const typeEvenement = this.formEvenement.get("typeEvenement")?.value;
    const libelleEvenement = this.formEvenement.get("libelleEvenement")?.value as string;
    const prioriteEvenement = Number(this.formEvenement.get("prioriteEvenement")?.value);
    const dateDebut = this.recupererDateFormulaire("dateDebut") as Date;
    const dateFin = this.recupererDateFormulaire("dateFin") as Date;
    const tierConcerne = this.formEvenement.get("tierConcerne")?.value as string;

    const texteDescriptionhtml = this.texteDescriptionModel.value ?? "";
    const texteDescriptionhtmlConvertie = this.conversionBaliseHtml(texteDescriptionhtml);

    if (this.formEvenement.valid) {
      const evenement: EvenementApp = new EvenementApp(
        typeEvenement === TypeAffichage.RENDEZ_VOUS,
        libelleEvenement,
        prioriteEvenement,
        dateDebut,
        dateFin,
        tierConcerne,
        texteDescriptionhtmlConvertie,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        depotParDefaut?.etablissement
      );
      await this.evenementService.validerEvenement(evenement, typeEvenement === TypeAffichage.RENDEZ_VOUS);
      this.referenceModal.close();
    }
  }

  public async validerContact(): Promise<void> {

    const action = this.action;
    let contac: string;
    if (this.contact) {
      contac = this.contact.contact;
    }
    else {
      contac = "";
    }
    const nom = this.formContact.get("nomContact")?.value ?? "";
    const prenom = this.formContact.get("prenomContact")?.value ?? "";
    const tel = this.formContact.get("telContact")?.value ?? "";
    const telMobile = this.formContact.get("telMobileContact")?.value ?? "";
    const mail = this.formContact.get("emailContact")?.value ?? "";
    const tiers = this.formContact.get("tiersConcerne")?.value ?? "";
    const anniversaire = this.formContact.get("anniversaireContact")?.value?.replaceAll("-", "") ?? "";
    const fonction = this.formContact.get("fonctionContact")?.value ?? "";
    const destFacture = this.formContact.get("destFacture")?.value === true ? "oui" : "non";
    const destReleve = this.formContact.get("destReleve")?.value === true ? "oui" : "non";
    const destAvisExpe = this.formContact.get("destAvisExpe")?.value === true ? "oui" : "non";
    const destAccuseLiv = this.formContact.get("destAccuseLiv")?.value === true ? "oui" : "non";
    const remarque = this.formContact.get("remarqueContact")?.value ?? "";

    if (this.formContact.valid) {
      const contact: ContactApp = new ContactApp(
        action,
        contac,
        tiers,
        nom,
        prenom,
        mail,
        tel,
        telMobile,
        anniversaire,
        fonction,
        destFacture,
        destReleve,
        destAvisExpe,
        destAccuseLiv,
        remarque,
      );

      if (action === "supprimer") {
        this.confirmerModificationContact().then(async () => {
          if (this.contact) {
            await this.contactService.validerContact(contact);
            this.referenceModal.close();
          }
        }).catch();
      }
      else {
        await this.contactService.validerContact(contact);
        this.referenceModal.close();
      }
    }
  }

  public annulationEvenement(valeurAnnule: number): void {
    this.confirmationService.afficher({
      titre: `${valeurAnnule == 1 ? "Restaurer" : "Supprimer"} l'évènement`,
      corps: `Êtes-vous sûr de vouloir ${valeurAnnule == 1 ? "restaurer" : "supprimer"} cet évènement ?`
    }).then(() => {
      this.tache.indicationAnnule = valeurAnnule;
      this.evenementService.enregistrerEvenement(this.tache, this.suppresionEffectuee);
      this.referenceModal.close();
    })
  }

  private conversionBaliseHtml(html: string): string {
    const htmlSansStrong = html.replace(/strong>/g, "b>");
    const htmlSansItalic = htmlSansStrong.replace(/em>/g, "i>");
    return htmlSansItalic;
  }

  public async supprimerContact(): Promise<void> {
    this.confirmerSuppressionContact().then(async () => {
      if (this.contact) {
        const contact = ContactApp.fromContactsSynchro(this.contact, "fermer");
        contact.anniversaire = contact.anniversaire ? contact.anniversaire.replaceAll("-", "") : "";
        await this.contactService.validerContact(contact);
        this.dbService.dexie.synchronisation_contacts_clients.filter((cont) => cont.tiers === this.contact.tiers && cont.contact === this.contact.contact).delete();
        this.referenceModal.close();
      }
    }).catch();
  }

  public confirmerSuppressionContact(): Promise<unknown> {
    return this.confirmationService.afficher({
      titre: "Suppression d'un contact",
      corps: "Êtes-vous sûr de vouloir supprimer ce contact ?",
      texteOui: "Supprimer",
      texteNon: "Annuler",
      iconeBoutonOui: "",
      iconeBoutonNon: "",
    })
  }

  public confirmerModificationContact(): Promise<unknown> {
    return this.confirmationService.afficher({
      titre: "Modification d'un contact",
      corps: "Êtes-vous sûr de vouloir modifier ce contact ?",
      texteOui: "Modifier",
      texteNon: "Annuler",
      iconeBoutonOui: "",
      iconeBoutonNon: "",
    })
  }

}
