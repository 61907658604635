<form *ngIf="!finalisation" [formGroup]="formulaireDemande" class="bg-primary">
  <div class="formulaire modal-body p-0 d-flex flex-column align-items-center" (submit)="demanderReinitialisation()">
    <div class="flex-grow-1"></div>

    <div class="mb-3">
      <h1 class="text-light fw-bold">Mot de passe oublié</h1>
    </div>

    <div class="conteneur-formulaire-clientfinal px-3 py-4 d-flex flex-column gap-3 ombre rounded">
      <div class="input-group" *ngIf="!mailEnvoye">
        <div class="input-group-text">
          <span class="fa fa-fw fa-at"></span>
        </div>
        <input type="email" class="form-control" id="username" formControlName="username" placeholder="Adresse mail" />
      </div>

      <button
        tabindex="1"
        type="submit"
        [disabled]="!formulaireDemande.valid || chargement"
        *ngIf="!mailEnvoye"
        class="btn btn-secondary align-self-center mt-2"
        (click)="demanderReinitialisation()"
      >
        <span *ngIf="!chargement" class="fa fa-fw fa-paper-plane me-2"></span>
        <span *ngIf="chargement" class="fa fa-fw fa-spinner fa-spin me-2"></span>
        <span>Réinitialiser mon mot de passe</span>
      </button>
      <div class="alert alert-secondary text-center mb-0" *ngIf="mailEnvoye">
        Vous allez recevoir un e-mail contenant les instructions pour réinitialiser votre mot de passe.
      </div>
      <button class="btn btn-secondary align-self-center" *ngIf="mailEnvoye" (click)="fermer()">
        <span class="fa fa-fw fa-arrow-left me-2"></span>
        <span>Revenir à la page de connexion</span>
      </button>
    </div>

    <div class="flex-grow-1"></div>
  </div>
</form>
<form *ngIf="finalisation" [formGroup]="formulaireFinalisation" class="bg-primary">
  <div class="formulaire modal-body p-0 d-flex flex-column align-items-center" (submit)="finaliserReinitialisation()">
    <div class="flex-grow-1"></div>

    <div class="mb-3">
      <h1 class="text-light fw-bold text-center">Réinitialiser mon mot de passe</h1>
    </div>

    <div *ngIf="cleValide === null" class="text-light"><span class="fa fa-spinner fa-spin"></span> Chargement...</div>

    <div *ngIf="cleValide === false" class="text-light text-center">
      <p class="my-3">Le lien que vous avez suivi semble avoir expiré.</p>
      <button tabindex="1" class="btn btn-secondary text-light align-self-center mt-2" (click)="demanderANouveau()">
        <span>Recevoir un nouveau lien</span>
      </button>
    </div>

    <div
      *ngIf="cleValide === true"
      class="conteneur-formulaire-clientfinal px-3 py-4 d-flex flex-column gap-3 ombre rounded"
    >
      <div *ngIf="!mailEnvoye">
        <label for="new-password" class="form-label text-light">Nouveau mot de passe</label>
        <input
          type="password"
          class="form-control"
          id="new-password"
          formControlName="newPassword"
          autocomplete="new-password"
        />
      </div>
      <div *ngIf="!mailEnvoye">
        <label for="new-password-again" class="form-label text-light">Répétez le mot de passe</label>
        <input
          type="password"
          class="form-control"
          id="new-password-again"
          formControlName="newPasswordAgain"
          autocomplete="off"
        />
      </div>

      <div *ngIf="erreurCorrespondance && !chargement && !mailEnvoye" class="invalid-feedback d-block text-danger mt-2">
        Les mots de passe ne correpondent pas.
      </div>

      <button
        tabindex="1"
        type="submit"
        *ngIf="!mailEnvoye"
        class="btn btn-secondary text-light align-self-center mt-2"
        (click)="finaliserReinitialisation()"
      >
        <span *ngIf="chargement" class="fa fa-fw fa-spinner fa-spin me-2"></span>
        <span>Modifier mon mot de passe</span>
      </button>
      <div class="alert alert-secondary text-center mb-0" *ngIf="mailEnvoye">
        Votre mot de passe a bien été modifié.
      </div>
      <button class="btn btn-secondary align-self-center" *ngIf="mailEnvoye" (click)="fermer()">
        <span class="fa fa-fw fa-arrow-left me-2"></span>
        <span>Revenir à la page de connexion</span>
      </button>
      <button class="btn btn-secondary align-self-center" *ngIf="mailEnvoye && navigateurMobile" (click)="redirigerVersAppli()">
        <span class="fa fa-fw fa-arrow-right me-2"></span>
        <span>Retourner sur l'application</span>
      </button>
    </div>

    <div class="flex-grow-1"></div>
  </div>
</form>
