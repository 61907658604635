import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Table } from 'dexie';
import { lastValueFrom } from 'rxjs';
import { ParametreApplication, ParametreApplicationId } from '../db/parametre-application';
import { DbService } from '../db/db.service';
import { HttpService } from '../http/http.service';
import { DemandeSynchronisation, SynchronisationQuotidienne } from './synchronisation-quotidienne';
import { ProfilUtilisateur, ParametresUtilisateurCRM } from '../crm/crm';
import { SynchronisationService } from '../synchronisation/synchronisation.service';
import { majDerniereSynchro } from 'src/app/tools/Fonctions/synchro';

@Injectable({
  providedIn: 'root'
})
export class SynchronisationQuotidienneService {

  public etapeActuelleSynchroQtd: number = 0;
  public nombreEtapesTotalSynchroQtd = 31;
  public etapeEnCoursQtd: string = "";

  constructor(
    private http: HttpService,
    private dbService: DbService,
    private synchronisationService: SynchronisationService,
  ) { }

  public async synchroniser(dtoSynchro?: DemandeSynchronisation): Promise<SynchronisationQuotidienne> {
    this.synchronisationService.synchronisationEnCours = true;
    this.etapeActuelleSynchroQtd = 0;
    this.etapeEnCoursQtd = "";

    const parametresUtilisateurCRM = await this.dbService.dexie.parametres_application.get(ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM) as ParametreApplication<ParametresUtilisateurCRM> | undefined;
    const parametresUtilisateur = await this.dbService.dexie.parametres_application.get(ParametreApplicationId.PROFIL_UTILISATEUR) as ParametreApplication<ProfilUtilisateur> | undefined;
    const derniereDateSynchroParametresUtilisateurCRM: Date | null = parametresUtilisateurCRM?.valeur?.lastModifiedDate ? new Date(parametresUtilisateurCRM?.valeur?.lastModifiedDate) : null;
    const derniereDateSynchroParametresUtilisateur: Date | null = parametresUtilisateur?.valeur?.lastModifiedDate ? new Date(parametresUtilisateur?.valeur?.lastModifiedDate) : null;

    const vals: string = await this.getIds("articles");
    const valsClients: string = await this.getIds("clients");
    const remises: string = await this.getIds("remises");
    const remisesQuantite: string = await this.getIds("remisesQuantite");
    const ristournes: string = await this.getIds("ristournes");
    const odrds: string = await this.getIds("odrd");
    const tarifsSpeciaux: string = await this.getIds("tarifsSpeciaux");

    if (!dtoSynchro) {
      dtoSynchro = {
        PARAMETRES_CRM_UTILISATEUR: derniereDateSynchroParametresUtilisateurCRM ? derniereDateSynchroParametresUtilisateurCRM.toISOString() : null,
        PROFIL_UTILISATEUR: derniereDateSynchroParametresUtilisateur ? derniereDateSynchroParametresUtilisateur.toISOString() : null,
        ENCOURS_VENTE: "",
        ENCOURS_FACTURE: "",
        INDICATEURS_STAT: "",
        INDICATEURS_STAT_PROGRESSION: "",
        STOCK_ARTICLES: "",
        DEPOTS: "",
        DEPOTS_VISU: "",
        COMMERCIAUX: "",
        FICJOINT_ART: "",
        FICJOINT_CLI: "",
        STAT_SIMPLE: "",
        REFERENCEMENT: "",
        PLANNING: "",
        TOURNEE: "",
        MOTIFS_GRATUITE: "",
        FRAIS_SUPPLEMENTAIRES: "",
        LIBELLES_TARIFS: "",
        UNITES: "",
        ADRESSES_CLIENTS: "",
        EXCEPTIONS_TARIFAIRES: "",
        HISTORIQUE_ARTICLE: "",
        CLASSES_REMISES: "",
        DECONSIGNES_ATTENTE: "",
        MISES_EN_AVANT: "",
        SUPPRESSION_ARTICLES: vals,
        SUPPRESSION_CLIENTS: valsClients,
        SUPPRESSION_REMISES: remises,
        SUPPRESSION_REMISES_QUANTITE: remisesQuantite,
        SUPPRESSION_RISTOURNES: ristournes,
        SUPPRESSION_ODRD: odrds,
        SUPPRESSION_TARIFS_SPECIAUX: tarifsSpeciaux,
      };
    }

    if (!dtoSynchro.PARAMETRES_CRM_UTILISATEUR) {
      dtoSynchro.PARAMETRES_CRM_UTILISATEUR = derniereDateSynchroParametresUtilisateurCRM ? derniereDateSynchroParametresUtilisateurCRM.toISOString() : null;
    }

    if (!dtoSynchro.PROFIL_UTILISATEUR) {
      dtoSynchro.PROFIL_UTILISATEUR = derniereDateSynchroParametresUtilisateur ? derniereDateSynchroParametresUtilisateur.toISOString() : null;
    }

    const reponse: HttpResponse<SynchronisationQuotidienne> = await lastValueFrom(this.http.postFromApi<SynchronisationQuotidienne>("business/crm/synchronisation-quotidienne", dtoSynchro));
    const donnees: SynchronisationQuotidienne = reponse.body ?? new SynchronisationQuotidienne();
    await this.enregistrerDonneesSynchro(donnees);
    majDerniereSynchro(this.dbService);
    this.synchronisationService.synchronisationEnCours = false;
    return donnees;
  }

  private async enregistrerDonneesSynchro(synchronisation: Partial<SynchronisationQuotidienne>): Promise<void> {

    this.etapeActuelleSynchroQtd = 0;

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Paramètres utilisateur Crm";
    if (synchronisation.PARAMETRES_CRM_UTILISATEUR) {
      console.time('synchronisationParametresCrmUtilisateur');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM, valeur: synchronisation.PARAMETRES_CRM_UTILISATEUR.valeurs[0] as never });
      console.timeEnd('synchronisationParametresCrmUtilisateur');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Paramètres utilisateur";
    if (synchronisation.PROFIL_UTILISATEUR) {
      console.time('synchronisationParametresUtilisateur');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.PROFIL_UTILISATEUR, valeur: synchronisation.PROFIL_UTILISATEUR.valeurs[0] as never });
      console.timeEnd('synchronisationParametresUtilisateur');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Ventes";
    if (synchronisation.ENCOURS_VENTE) {
      console.time('synchronisationEncoursVente');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_ENCOURS, valeur: synchronisation.ENCOURS_VENTE.horodatage as never });
      await this.gererAjoutSuppression(synchronisation.ENCOURS_VENTE.valeurs, this.dbService.dexie.synchronisation_encours_vente);
      console.timeEnd('synchronisationEncoursVente');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Facture";
    if (synchronisation.ENCOURS_FACTURE) {
      // C'est ENCOURS_VENTE qui écrit l'horodatage
      console.time('synchronisationEncoursFacture');
      await this.gererAjoutSuppression(synchronisation.ENCOURS_FACTURE.valeurs, this.dbService.dexie.synchronisation_encours_facture);
      console.timeEnd('synchronisationEncoursFacture');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Indicateurs statistiques";
    if (synchronisation.INDICATEURS_STAT) {
      console.time('synchronisationIndicateursStat');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_STAT, valeur: synchronisation.INDICATEURS_STAT.horodatage as never });
      await this.gererAjoutSuppression(synchronisation.INDICATEURS_STAT.valeurs, this.dbService.dexie.synchronisation_indicateurs_stat);
      console.timeEnd('synchronisationIndicateursStat');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Indicateurs statistiques progression";
    if (synchronisation.INDICATEURS_STAT_PROGRESSION) {
      // C'est INDICATEURS_STAT qui écrit l'horodatage
      console.time('synchronisationIndicateursStatProgression');
      await this.gererAjoutSuppression(synchronisation.INDICATEURS_STAT_PROGRESSION.valeurs, this.dbService.dexie.synchronisation_indicateurs_stat_progression);
      console.timeEnd('synchronisationIndicateursStatProgression');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Stock articles";
    if (synchronisation.STOCK_ARTICLES) {
      console.time('synchronisationStockArticles');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_STOCK_ARTICLES, valeur: synchronisation.STOCK_ARTICLES.horodatage as never });
      await this.gererAjoutSuppression(synchronisation.STOCK_ARTICLES.valeurs, this.dbService.dexie.synchronisation_stock_articles);
      console.timeEnd('synchronisationStockArticles');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Depots";
    if (synchronisation.DEPOTS) {
      console.time('synchronisationDepots');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_DEFINITIONS, valeur: synchronisation.DEPOTS.horodatage as never });
      await this.gererAjoutSuppression(synchronisation.DEPOTS.valeurs, this.dbService.dexie.synchronisation_info_depots);
      console.timeEnd('synchronisationDepots');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Depots visualisation";
    if (synchronisation.DEPOTS_VISU) {
      console.time('synchronisationDepotsVisu');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_DEFINITIONS, valeur: synchronisation.DEPOTS_VISU.horodatage as never });
      await this.gererAjoutSuppression(synchronisation.DEPOTS_VISU.valeurs, this.dbService.dexie.synchronisation_info_depots_visu);
      console.timeEnd('synchronisationDepotsVisu');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Commerciaux";
    if (synchronisation.COMMERCIAUX) {
      console.time('synchronisationCommerciaux');
      await this.gererAjoutSuppression(synchronisation.COMMERCIAUX.valeurs, this.dbService.dexie.synchronisation_info_commerciaux);
      console.timeEnd('synchronisationCommerciaux');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Fichiers joints articles";
    if (synchronisation.FICJOINT_ART) {
      console.time('synchronisationFichiersJointsArticles');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_FICJOINTS, valeur: synchronisation.FICJOINT_ART.horodatage as never });
      await this.gererAjoutSuppression(synchronisation.FICJOINT_ART.valeurs, this.dbService.dexie.synchronisation_ficjoint_articles);
      console.timeEnd('synchronisationFichiersJointsArticles');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Fichiers joints clients";
    if (synchronisation.FICJOINT_CLI) {
      console.time('synchronisationFichiersJointsClients');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_FICJOINTS, valeur: synchronisation.FICJOINT_CLI.horodatage as never });
      await this.gererAjoutSuppression(synchronisation.FICJOINT_CLI.valeurs, this.dbService.dexie.synchronisation_ficjoint_clients);
      console.timeEnd('synchronisationFichiersJointsClients');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Statistiques simples";
    if (synchronisation.STAT_SIMPLE) {
      console.time('synchronisationStatsSimples');
      await this.gererAjoutSuppression(synchronisation.STAT_SIMPLE.valeurs, this.dbService.dexie.synchronisation_stat_simple);
      console.timeEnd('synchronisationStatsSimples');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Référencement";
    if (synchronisation.REFERENCEMENT) {
      console.time('synchronisationReferencement');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_REFERENCEMENT, valeur: synchronisation.REFERENCEMENT.horodatage as never });
      await this.gererAjoutSuppression(synchronisation.REFERENCEMENT.valeurs, this.dbService.dexie.synchronisation_referencement);
      console.timeEnd('synchronisationReferencement');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Planning";
    if (synchronisation.PLANNING) {
      console.time('synchronisationPlanning');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_PLANNING, valeur: synchronisation.PLANNING.horodatage as never });
      const valeurs = synchronisation.PLANNING.valeurs.map(e => { return { ...e, modifier: false } });
      await this.gererAjoutSuppression(valeurs, this.dbService.dexie.synchronisation_planning);
      console.timeEnd('synchronisationPlanning');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Tournée";
    if (synchronisation.TOURNEE) {
      console.time('synchronisationTournee');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_TOURNEE, valeur: synchronisation.TOURNEE.horodatage as never });
      const valeurs = synchronisation.TOURNEE.valeurs.map(e => { return { ...e, modifier: false } });
      await this.gererAjoutSuppression(valeurs, this.dbService.dexie.synchronisation_tournee);
      console.timeEnd('synchronisationTournee');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Motifs de gratuité";
    if (synchronisation.MOTIFS_GRATUITE) {
      console.time('synchronisationMotifsGratuite');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_MOTIFS_GRATUITE, valeur: synchronisation.MOTIFS_GRATUITE.horodatage as never });
      const valeurs = synchronisation.MOTIFS_GRATUITE.valeurs.map(e => { return { ...e, modifier: false } });
      await this.gererAjoutSuppression(valeurs, this.dbService.dexie.synchronisation_motifs_gratuite);
      console.timeEnd('synchronisationMotifsGratuite');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Frais supplémentaires";
    if (synchronisation.FRAIS_SUPPLEMENTAIRES) {
      console.time('Frais supplémentaires');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_FRAIS_SUPPLEMENTAIRES, valeur: synchronisation.FRAIS_SUPPLEMENTAIRES.horodatage as never });
      const valeurs = synchronisation.FRAIS_SUPPLEMENTAIRES.valeurs.map(e => { return { ...e, modifier: false } });
      await this.gererAjoutSuppression(valeurs, this.dbService.dexie.synchronisation_frais_supplementaires);
      console.timeEnd('Frais supplémentaires');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Libellés tarifs";
    if (synchronisation.LIBELLES_TARIFS) {
      console.time('synchronisationLibellesTarifs');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_LIBELLES_TARIFS, valeur: synchronisation.LIBELLES_TARIFS.horodatage as never });
      const valeurs = synchronisation.LIBELLES_TARIFS.valeurs.map(e => { return { ...e, modifier: false } });
      await this.gererAjoutSuppression(valeurs, this.dbService.dexie.synchronisation_libelles_tarifs);
      console.timeEnd('synchronisationLibellesTarifs');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Unités";
    if (synchronisation.UNITES) {
      console.time('synchronisationUnites');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_UNITES, valeur: synchronisation.UNITES.horodatage as never });
      const valeurs = synchronisation.UNITES.valeurs.map(e => { return { ...e, modifier: false } });
      await this.gererAjoutSuppression(valeurs, this.dbService.dexie.synchronisation_unites);
      console.timeEnd('synchronisationUnites');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Adresses clients";
    if (synchronisation.ADRESSES_CLIENTS) {
      console.time('synchronisationAdressesClients');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_ADRESSES_CLIENTS, valeur: synchronisation.ADRESSES_CLIENTS.horodatage as never });
      const valeurs = synchronisation.ADRESSES_CLIENTS.valeurs.map(e => { return { ...e, modifier: false } });
      await this.gererAjoutSuppression(valeurs, this.dbService.dexie.synchronisation_adresses);
      console.timeEnd('synchronisationAdressesClients');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Exceptions tarifaires";
    if (synchronisation.EXCEPTIONS_TARIFAIRES) {
      console.time('synchronisationExceptionsTarifaires');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_EXCEPTIONS_TARIFAIRES, valeur: synchronisation.EXCEPTIONS_TARIFAIRES.horodatage as never });
      const valeurs = synchronisation.EXCEPTIONS_TARIFAIRES.valeurs.map(e => { return { ...e, modifier: false } });
      await this.gererAjoutSuppression(valeurs, this.dbService.dexie.synchronisation_exceptions_tarifaires);
      console.timeEnd('synchronisationExceptionsTarifaires');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Historique article";
    if (synchronisation.HISTORIQUE_ARTICLE) {
      console.time('Historique article');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_HISTORIQUE_ARTICLE, valeur: synchronisation.HISTORIQUE_ARTICLE.horodatage as never });
      const valeurs = synchronisation.HISTORIQUE_ARTICLE.valeurs.map(e => { return { ...e, modifier: false } });
      await this.gererAjoutSuppression(valeurs, this.dbService.dexie.synchronisation_historique_article);
      console.timeEnd('Historique article');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Classes remises";
    if (synchronisation.CLASSES_REMISES) {
      console.time('Classes remises');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_CLASSES_REMISES, valeur: synchronisation.CLASSES_REMISES.horodatage as never });
      const valeurs = synchronisation.CLASSES_REMISES.valeurs.map(e => { return { ...e, modifier: false } });
      await this.gererAjoutSuppression(valeurs, this.dbService.dexie.synchronisation_classe_remise);
      console.timeEnd('Classes Remises');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Déconsignes en attente";
    if (synchronisation.DECONSIGNES_ATTENTE) {
      console.time('Déconsignes en attente');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_DECONSIGNES_ATTENTE, valeur: synchronisation.DECONSIGNES_ATTENTE.horodatage as never });
      const valeurs = synchronisation.DECONSIGNES_ATTENTE.valeurs.map(e => { return { ...e } });
      await this.gererAjoutSuppression(valeurs, this.dbService.dexie.synchronisation_deconsignes_attente);
      console.timeEnd('Déconsignes en attente');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Mises en avant";
    if (synchronisation.MISES_EN_AVANT) {
      console.time('MEA');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_MISES_EN_AVANT, valeur: synchronisation.MISES_EN_AVANT.horodatage as never });
      await this.gererAjoutSuppression(synchronisation.MISES_EN_AVANT.valeurs, this.dbService.dexie.synchronisation_mise_en_avant);
      console.timeEnd('MEA');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Suppresion des articles";
    if (synchronisation.SUPPRESSION_ARTICLES) {
      console.time('Suppression des articles');
      const valeurs = synchronisation.SUPPRESSION_ARTICLES.valeurs.map(e => { return { ...e, modifier: false } });
      await this.supprimerValeurs(valeurs,"articles");
      console.timeEnd('Suppression des articles');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Suppresion des clients";
    if (synchronisation.SUPPRESSION_CLIENTS) {
      console.time('Suppression des clients');
      const valeurs = synchronisation.SUPPRESSION_CLIENTS.valeurs.map(e => { return { ...e, modifier: false } });
      await this.supprimerValeurs(valeurs,"clients");
      console.timeEnd('Suppression des clients');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Suppresion des remises";
    if (synchronisation.SUPPRESSION_REMISES) {
      console.time('Suppression des remises');
      const valeurs = synchronisation.SUPPRESSION_REMISES.valeurs.map(e => { return { ...e, modifier: false } });
      await this.supprimerValeurs(valeurs,"remises");
      console.timeEnd('Suppression des remises');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Suppresion des remises quantité";
    if (synchronisation.SUPPRESSION_REMISES_QUANTITE) {
      console.time('Suppression des remises quantité');
      const valeurs = synchronisation.SUPPRESSION_REMISES_QUANTITE.valeurs.map(e => { return { ...e, modifier: false } });
      await this.supprimerValeurs(valeurs,"remisesQuantite");
      console.timeEnd('Suppression des remises quantité');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Suppresion des ristournes";
    if (synchronisation.SUPPRESSION_RISTOURNES) {
      console.time('Suppression des ristournes');
      const valeurs = synchronisation.SUPPRESSION_RISTOURNES.valeurs.map(e => { return { ...e, modifier: false } });
      await this.supprimerValeurs(valeurs,"ristournes");
      console.timeEnd('Suppression des ristournes');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Suppresion des offres de remboursement différées";
    if (synchronisation.SUPPRESSION_ODRD) {
      console.time('Suppression des offres de remboursement différées');
      const valeurs = synchronisation.SUPPRESSION_ODRD.valeurs.map(e => { return { ...e, modifier: false } });
      await this.supprimerValeurs(valeurs,"odrd");
      console.timeEnd('Suppression des offres de remboursement différées');
    }

    this.etapeActuelleSynchroQtd++;
    this.etapeEnCoursQtd = "Suppresion des tarifs spéciaux";
    if (synchronisation.SUPPRESSION_TARIFS_SPECIAUX) {
      console.time('Suppression des tarifs spéciaux');
      const valeurs = synchronisation.SUPPRESSION_TARIFS_SPECIAUX.valeurs.map(e => { return { ...e, modifier: false } });
      await this.supprimerValeurs(valeurs,"tarifsSpeciaux");
      console.timeEnd('Suppression des tarifs spéciaux');
    }
  }

  private async supprimerValeurs(valeurs: { modifier: boolean, id: number, idStr: string }[], synchronisation: string) {
    for (const valeur of valeurs) {
      if(synchronisation === "articles") {
        this.dbService.dexie.synchronisation_articles.where('id').equals(valeur.id).delete();
      }
      else if(synchronisation === "clients") {
        this.dbService.dexie.synchronisation_clients.where('tiers').equals(valeur.idStr).delete();
      }
      else if(synchronisation === "remises") {
        this.dbService.dexie.synchronisation_remises.where('id').equals(valeur.id).delete();
      }
      else if(synchronisation === "remisesQuantite") {
        this.dbService.dexie.synchronisation_remises_quantite.where('id').equals(valeur.id).delete();
      }
      else if(synchronisation === "ristournes") {
        this.dbService.dexie.synchronisation_ristournes.where('id').equals(valeur.id).delete();
      }
      else if(synchronisation === "odrd") {
        this.dbService.dexie.synchronisation_odrd.where('id').equals(valeur.id).delete();
      }
      else if(synchronisation === "tarifsSpeciaux") {
        this.dbService.dexie.synchronisation_tarifs_speciaux.where('id').equals(valeur.id).delete();
      }
    }
  }

  private async getIds(synchronisation: string): Promise<string> {
    try {
      let ids: string[] = [];
      let listeIds: string = "";
      if (synchronisation === "articles") {
        ids = await this.dbService.dexie.synchronisation_articles.toCollection().primaryKeys();
      }
      else if (synchronisation === "clients") {
        ids = await this.dbService.dexie.synchronisation_clients.toCollection().primaryKeys();
      }
      else if (synchronisation === "remises") {
        ids = await this.dbService.dexie.synchronisation_remises.toCollection().primaryKeys();
      }
      else if (synchronisation === "remisesQuantite") {
        ids = await this.dbService.dexie.synchronisation_remises_quantite.toCollection().primaryKeys();
      }
      else if (synchronisation === "ristournes") {
        ids = await this.dbService.dexie.synchronisation_ristournes.toCollection().primaryKeys();
      }
      else if (synchronisation === "odrd") {
        ids = await this.dbService.dexie.synchronisation_odrd.toCollection().primaryKeys();
      }
      else if (synchronisation === "tarifsSpeciaux") {
        ids = await this.dbService.dexie.synchronisation_tarifs_speciaux.toCollection().primaryKeys();
      }

      if(synchronisation === "clients") {
        listeIds = ids.map(id => `'${id}'`).join(",");
      }
      else {
        listeIds = ids.map(id => `${id}`).join(",");
      }
      return listeIds;
    } catch (error) {
      console.error("Impossible d'obtenir les ids des articles: ", error);
      return "";
    }
  }

  private async gererAjoutSuppression<T>(donneesTable: T[], table: Table<T, unknown>): Promise<void> {
    await table.clear();
    await table.bulkPut(donneesTable);
  }

}
