import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { LoggerService } from '../log/logger.service';
import type { ConfigPlateforme, EstTactile, PlateformeSpecifique } from './specifique';
import { HttpService } from '../http/http.service';
import { HttpParams } from '@angular/common/http';
import { recupererJetonFirebase } from './outils/notifications-push';
import {
  CRMEnregistrementAppareilDTO,
  recupererIdentifiantAppareil,
  recupererInformationsComplementairesAppareil,
} from './outils/appareil';
import packageJson from 'package.json';
import { Identifiants } from '../login/dto/identifiants';
import { AppLauncher } from '@capacitor/app-launcher';
import { OutilsNatifsKalico } from './outils/natif-kalico';
import { firstValueFrom } from 'rxjs';
import { LogLevel } from '../log/level.model';

export type Fichier = FichierLocal | FichierDistant;

export interface FichierDistant {
  nom: string;
  url: string;
  type?: string;
  params?: HttpParams;
  chargeUtile?: unknown;
}

export interface FichierLocal {
  nom: string;
  donnees: Blob;
  type?: string;
}

const LISTE_BLANCHE_PROTOCOLE_URL = ['https', 'maboutique'];

@Injectable({
  providedIn: 'root',
})
export class PlateformeService {
  private specifique?: PlateformeSpecifique;
  private initialisation: Promise<void>;
  private config: ConfigPlateforme;

  // pour debug, a enlever plus tard
  private __TEST_flag_init: boolean = false;
  private __TEST_first_call: Set<string> = new Set();

  constructor(private loggerService: LoggerService, private httpService: HttpService) {
    this.initialisation = this.init();
    this.config = {};
  }

  private async init(): Promise<void> {
    if (this.loggerService.logLevelConsole >= LogLevel.Verbose) {
      OutilsNatifsKalico.ping({ ping: 'TEST' }).then((res) =>
        this.loggerService.verbose(`PLUGIN KALICO : PONG ${res.pong}`)
      );
    }

    switch (Capacitor.getPlatform()) {
      case 'ios':
        this.specifique = await import('./specifique/ios').then((mod) => new mod.PlateformeIOS(this.loggerService));
        break;
      case 'android':
        this.specifique = await import('./specifique/android').then(
          (mod) => new mod.PlateformeAndroid(this.loggerService)
        );
        break;
      default:
        this.specifique = await import('./specifique/web').then((mod) => new mod.PlateformeWeb(this.loggerService));
        break;
    }
    this.__TEST_flag_init = true;
    this.loggerService.info(`Utilisation des services plateforme ${this.specifique.nom}`);
  }

  private __TEST_check_async(method: string): void {
    if (!this.__TEST_first_call.has(method)) {
      this.__TEST_first_call.add(method);
      if (this.__TEST_flag_init) {
        this.loggerService.warn(
          `La méthode ${method} n'a jamais été appelée avant l'initialisation des services plateforme ! Mauvaise utilisation de avecPlateformeSpecifiqueAsync() ?`
        );
      } else {
        this.loggerService.warn(
          `La méthode ${method} a été appelée avant l'initialisation des services plateforme, le __TEST_check_async peut être enlevé.`
        );
      }
    }
  }

  private avecPlateformeSpecifique<R>(action: (ps: PlateformeSpecifique) => R, alternative?: () => R): R {
    if (this.specifique !== undefined) {
      return action(this.specifique);
    } else if (alternative !== undefined) {
      return alternative();
    } else {
      throw new Error('Services plateforme non initialisés');
    }
  }

  private async avecPlateformeSpecifiqueAsync<R>(action: (ps: PlateformeSpecifique) => R | Promise<R>): Promise<R> {
    await this.initialisation;
    if (this.specifique === undefined) {
      this.loggerService.error('Aucun service spécifique défini à la sortie de PlateformeService.init()');
      throw new Error();
    }
    return action(this.specifique);
  }

  public async estNatif(): Promise<boolean> {
    this.__TEST_check_async('estNatif()');
    return this.avecPlateformeSpecifiqueAsync((ps) => ps.estNative);
  }

  public async configurer(): Promise<void> {
    this.__TEST_check_async('configurer()');
    return this.avecPlateformeSpecifiqueAsync(async (ps) => {
      try {
        this.config = await ps.configurer();
      } catch (e) {
        this.loggerService.error(`Erreur lors de la configuration des services plateforme: ${e}`);
      }
    });
  }

  public async telechargerEtOuvrir(fichier: Fichier): Promise<boolean> {
    const fichierTelecharge = await this.telecharger(fichier);
    if (fichierTelecharge === null) {
      return false;
    } else {
      this.__TEST_check_async('telechargerEtOuvrir()');
      await this.avecPlateformeSpecifiqueAsync((ps) =>
        ps.enregistrerEtOuvrir(fichier.nom, fichier.type, fichierTelecharge.donnees)
      );
      return true;
    }
  }

  public async telechargerSansOuvrir(fichier: Fichier): Promise<boolean> {
    const fichierTelecharge = await this.telecharger(fichier);
    if (fichierTelecharge === null) {
      return false;
    } else {
      this.__TEST_check_async('telechargerSansOuvrir()');
      await this.avecPlateformeSpecifiqueAsync((ps) =>
        ps.enregistrerSeulement(fichier.nom, fichier.type, fichierTelecharge.donnees)
      );
      return true;
    }
  }

  private async telecharger(fichier: Fichier): Promise<FichierLocal | null> {
    if ('donnees' in fichier) {
      return fichier;
    } else {
      let appelApi;
      if (fichier.chargeUtile === undefined) {
        appelApi = this.httpService.getBlobFromApi(fichier.url, fichier.params);
      } else {
        appelApi = this.httpService.postBlobFromApi(fichier.url, fichier.chargeUtile, fichier.params);
      }
      const reponse = await firstValueFrom(appelApi);
      if (!reponse || !reponse.body || reponse.body.size === 0) {
        return null;
      } else {
        return { nom: fichier.nom, type: fichier.type, donnees: reponse.body };
      }
    }
  }

  /**
   * @deprecated Utiliser telechargerSansOuvrir à la place !
   */
  public async enregistrerSeulement(nom: string, blob: Blob, type?: string): Promise<void> {
    this.loggerService.warn('Méthode enregistrerSeulement dépréciée, utiliser telechargerSansOuvrir à la place');
    await this.avecPlateformeSpecifiqueAsync((ps) => ps.enregistrerSeulement(nom, type, blob));
  }

  private recupererJetonFirebase(): Promise<string> {
    return recupererJetonFirebase(this.config.optionsEnregistrementFirebase);
  }

  public async enregistrerAppareil(): Promise<void> {
    this.__TEST_check_async('enregistrerAppareil()');
    await this.avecPlateformeSpecifiqueAsync(async (ps) => {
      let jetonFirebaseMessaging;
      if (await ps.verifierPermissionsNotifications()) {
        jetonFirebaseMessaging = await this.recupererJetonFirebase();
        this.loggerService.verbose(`Notifications OK, jeton firebase messaging: [${jetonFirebaseMessaging}]`);
      } else {
        jetonFirebaseMessaging = 'KAL-DESACTIVE';
        this.loggerService.verbose(`Notifications desactivées`);
      }

      const identifiantAppareil = await recupererIdentifiantAppareil();
      this.loggerService.info(`L'identifiant de cet appareil est [${identifiantAppareil}]`);

      const infosComplementaires = await recupererInformationsComplementairesAppareil();
      this.loggerService.info(`Informations de l'appareil: ${JSON.stringify(infosComplementaires)}`);

      const appareil: CRMEnregistrementAppareilDTO = {
        identifiantAppareil,
        plateforme: ps.codePlateforme,
        infosComplementaires,
        versionCRM: packageJson.version,
        jetonFirebaseMessaging,
      };

      try {
        await this.httpService.postFromApiDiscret('business/crm/enregistrement-appareil', appareil);
        this.loggerService.info('Appareil enregistré avec succès');
      } catch (err) {
        this.loggerService.error(`Erreur lors de l'enregistrement de l'appareil: ${JSON.stringify(err)}`);
      }
    });
  }

  public async inviterEnregistrerMotDePasse(identifiants: Identifiants): Promise<void> {
    this.__TEST_check_async('inviterEnregistrerMotDePasse()');
    await this.avecPlateformeSpecifiqueAsync((ps) => ps.inviterAEnregistrerMotDePasse(identifiants));
  }

  public async ouvrirUrlExterne(url: string | null | undefined): Promise<boolean> {
    if (!url) return false;
    if (LISTE_BLANCHE_PROTOCOLE_URL.every((protocole) => !url.startsWith(protocole + '://'))) {
      this.loggerService.warn(`Ouverture de l'URL "${url}" bloquée car ce protocole n'est pas autorisé.`);
      return false;
    }
    const resultat = await AppLauncher.openUrl({ url });
    return resultat.completed;
  }

  public async getNomPlateforme(): Promise<string> {
    this.__TEST_check_async('getNomPlateforme()');
    return this.avecPlateformeSpecifiqueAsync((ps) => ps.nom);
  }

  public estTactile(): EstTactile {
    return this.avecPlateformeSpecifique((ps) => ps.estTactile, () => 'defaut-non');
  }

  public peutGererRefreshToken(): boolean {
    return this.avecPlateformeSpecifique(
      (ps) => ps.peutGererRefreshToken,
      () => false
    );
  }

  public async recupererRefreshToken(): Promise<string | null> {
    const resultat = await OutilsNatifsKalico.recupererRefreshToken();
    return resultat.refreshToken;
  }

  public async enregistrerRefreshToken(refreshToken: string): Promise<boolean> {
    this.loggerService.verbose('Enregisrement du refresh token');
    try {
      await OutilsNatifsKalico.enregistrerRefreshToken({ refreshToken });
      this.loggerService.verbose('Le refresh token a été enregistré avec succès');
      return true;
    } catch (err) {
      this.loggerService.verbose("Impossible d'enregistrer le refresh token : " + err);
      return false;
    }
  }

  public async effacerRefreshToken(): Promise<void> {
    await OutilsNatifsKalico.effacerRefreshToken();
  }
}
