import { Observable, tap } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../services/auth/auth.service';
import { LoginService } from '../../services/login/login.service';
import { LoggerService } from 'src/app/services/log/logger.service';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';

export class AuthInterceptor implements HttpInterceptor {
    private urlServeur = "";

    constructor(
        private authService: AuthService,
        private loginService: LoginService,
        private loggerService: LoggerService,
        private appConfigService: AppConfigService,
    ) {

        this.urlServeur = this.appConfigService.getConfig().urlBaseServeurERP;
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!request || !request.url || (/^http/.test(request.url) && !(this.urlServeur
            && request.url.startsWith(this.urlServeur)))) {
            return next.handle(request);
        }

        const token: string | null = this.authService.recupererToken();
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token,
                },
                url: request.url
            });
        }

        return next
            .handle(request)
            .pipe(tap({
                next: () => { return },
                error: (err: unknown) => {
                    console.log(err);
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                          this.loginService.ouvrirModale();
                          this.loggerService.warn(`Essai appel ${request.url} mais problème d'authentification, déconnexion et ouverture de la modale de login.`);
                          this.loginService.authentificationEnCours = true;
                        }
                    }
                }
            }));
    }
}
