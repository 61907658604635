export const environment = {
  parametreSpecifique: {
    urlBaseServeurERP: "https://kalico.pro:39485/api/",
    urlBaseServeurRessources: "https://kalico.pro:39485/api/",
    clientFinal: true,
  },
  production: true,
  logLevelApp: 0,
  logLevelConsole: 0,
  logLevelWebHttp: 0,
};