import { Injectable } from '@angular/core';
import {
  SynchronisationArticle,
  SynchronisationClient,
  SynchronisationUnite,
  TarifSynchronise,
} from '../synchronisation/synchronisation';
import { combineLatest, from, Observable, ReplaySubject, take } from 'rxjs';
import { DbService } from '../db/db.service';
import { liveQuery } from 'dexie';
import { EnvironnementApplicationService } from '../environnement-application/environnement-application.service';
import {
  SynchronisationInfoDepot,
  SynchronisationStockArticle,
} from '../synchronisation-quotidienne/synchronisation-quotidienne';
import { ParametreApplicationId } from '../db/parametre-application';
import { TarifLigneEtendu } from '../crm/crm-panier/crm-panier';
import { Lazy } from 'src/app/tools/lazy';
import { FavorisArticle } from '../favoris/favoris';
import { CrmService } from '../crm/crm.service';
import { AppConfigService } from '../configuration/app-config.service';
import { liveQueryReactifV2 } from '../db/liveQueryReactif';

export interface ArticleEtendu extends SynchronisationArticle {
  estFavori: boolean;
  panierQuantite: number;
  panierQuantiteGratuit: number;
  motifQuantiteGratuit: string;
  panierPrixClavier: number | null;
  motifPrixClavier: string;
  panierUnite: string;
  panierUnites: string[];
  chargement: boolean;
  uniteStockConverti: string;
  stockConverti: number;
  stockConvertiCoefficient: number;
  uniteStockReserveConverti: string;
  stockReserveConverti: number;
  stockReserveConvertiCoefficient: number;
  tarif: TarifSynchronise | undefined;
  tarifReference: number | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  private _listeArticlesReactive: ReplaySubject<ArticleEtendu[]> = new ReplaySubject(1);
  private _listeArticles: ArticleEtendu[] | undefined;
  private _listeArticlesMap: Map<string, ArticleEtendu> = new Map<string, ArticleEtendu>();

  private _afficherDetailListeArticle: boolean = true;
  private _dernierArticle: string = '';

  public forcerPremierArticle: boolean = false;
  public afficherTarif: boolean = true;
  public _modeTactile: boolean = true;

  // Dans la liste des articles, affiche le panier en cours à la place du détail de l'article
  private _afficherPanierDansListe: boolean = false;

  private _exceptionsTarifaires = new Lazy(async (clientRef: string) => {
    const toutesExceptionsTarifaires = await this.dbService.dexie.synchronisation_exceptions_tarifaires
      .where('tiers')
      .equals(clientRef)
      .toArray();
    return new Map(toutesExceptionsTarifaires.map((ex) => [`${ex.numeroFamille},${ex.famille}`, ex.codeTarif]));
  });

  private static __TEST_COMPTEUR_CALCUL: number = 0;
  private static __TEST_COMPTEUR_YIELD: number = 0;

  public get afficherPanierDansListe(): boolean {
    return this._afficherPanierDansListe;
  }
  public set afficherPanierDansListe(value: boolean) {
    this._afficherPanierDansListe = value;
  }

  public get afficherTarifDansListe(): boolean {
    return this.afficherTarif;
  }

  public set afficherTarifDansListe(afficher: boolean) {
    this.afficherTarif = afficher;
  }

  public get chargementPremierArticle(): boolean {
    return this.forcerPremierArticle;
  }

  public set chargementPremierArticle(forcerPremierArticle: boolean) {
    this.forcerPremierArticle = forcerPremierArticle;
  }

  public get afficherDetailListeArticle(): boolean {
    return this._afficherDetailListeArticle;
  }

  public set afficherDetailListeArticle(afficherDetail: boolean) {
    this._afficherDetailListeArticle = afficherDetail;
  }

  public get dernierArticle(): string {
    return this._dernierArticle;
  }

  public set dernierArticle(dernierArticle: string) {
    this._dernierArticle = dernierArticle;
  }

  public get modeTactile(): boolean {
    return this._modeTactile;
  }

  public set modeTactile(modeTactile: boolean) {
    this._modeTactile = modeTactile;
  }

  public get listeArticles(): ArticleEtendu[] | undefined {
    return this._listeArticles;
  }

  public get listeArticlesReactive(): Observable<ArticleEtendu[]> {
    return this._listeArticlesReactive;
  }

  private unites: Map<string, SynchronisationUnite> = new Map();

  constructor(
    private dbService: DbService,
    private environnementApplicationService: EnvironnementApplicationService,
    private crmService: CrmService,
    private appConfig: AppConfigService
  ) {
    this.init();
  }

  private async init() {
    const afficherTarifs = localStorage.getItem('afficherTarifs');
    this.afficherTarif = afficherTarifs && afficherTarifs === 'non' ? false : true;

    liveQuery(() => this.dbService.dexie.synchronisation_unites.toArray()).subscribe(
      (unites: SynchronisationUnite[]) => {
        this.unites = new Map(unites.map((unite) => [unite.uniteVente, unite]));
      }
    );

    combineLatest({
      articles: liveQuery(() => this.dbService.dexie.synchronisation_articles.toArray()),
      favoris: liveQuery(() => this.dbService.dexie.favoris_articles.toArray()),
      stocks: liveQueryReactifV2(
        {
          depot: this.environnementApplicationService.getParametreReactif(ParametreApplicationId.ENVIRONNEMENT_DEPOT),
          parametresUtilisateur: this.environnementApplicationService.getParametreReactif(
            ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM
          ),
        },
        ({ depot, parametresUtilisateur }) =>
          this.dbService.dexie.synchronisation_stock_articles
            .where('depotRef')
            .equals(depot || parametresUtilisateur?.depotParDefautRef || '')
            .toArray()
      ),
    }).subscribe(({ articles, favoris, stocks }) => {
      this.etendreEtTrierArticles(articles, favoris, stocks);
    });

    this._modeTactile = this.environnementApplicationService.getParametre(ParametreApplicationId.MODE_TACTILE) ?? false;
  }

  private etendreEtTrierArticles(
    articles: SynchronisationArticle[],
    favoris: FavorisArticle[],
    stocks: SynchronisationStockArticle[]
  ): void {
    const articlesEtendus = articles.map((article) => this.etendreArticle(article, stocks));

    for (const article of articlesEtendus) {
      article.estFavori = favoris.some((fav) => fav.articleRef === article.articleRef && fav.statut);
    }

    const familleArticleDefaut = this.environnementApplicationService.getParametre(
      ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM
    )?.familleArticleDefaut;
    const articlesTries = articlesEtendus.sort((a, b) => {
      if (a.estFavori && !b.estFavori) return -1;
      if (!a.estFavori && b.estFavori) return 1;

      let comparaisonFamille;
      if (familleArticleDefaut === '3') {
        comparaisonFamille = a.familleStat3Ref.localeCompare(b.familleStat3Ref);
      } else if (familleArticleDefaut === '2') {
        comparaisonFamille = a.familleStat2Ref.localeCompare(b.familleStat2Ref);
      } else {
        comparaisonFamille = a.familleStat1Ref.localeCompare(b.familleStat1Ref);
      }
      if (comparaisonFamille !== 0) return comparaisonFamille;

      return a.designation.localeCompare(b.designation);
    });

    const articlesMap: Map<string, ArticleEtendu> = new Map(
      articlesTries.map((article) => [article.articleRef, article])
    );
    this._listeArticlesMap = articlesMap;
    this._listeArticles = articlesTries;
    console.log(`${articlesTries.length} articles étendus et triés`);
    this._listeArticlesReactive.next(articlesTries);
  }

  public etendreArticle(article: SynchronisationArticle, stocks: SynchronisationStockArticle[]): ArticleEtendu {
    const unitesPrisesEnCompte: { unite: string; coefficient: number }[] = this.appConfig.modeClientFinal
      ? [{ unite: article.uniteVente, coefficient: article.uniteVenteCoefficient }]
      : [
          { unite: article.uniteAchat, coefficient: article.uniteAchatCoefficient },
          { unite: article.uniteVente, coefficient: article.uniteVenteCoefficient },
          { unite: article.uniteStockage, coefficient: article.uniteStockageCoefficient },
          { unite: article.uniteReference, coefficient: article.uniteReferenceCoefficient },
          { unite: article.uniteDivers1, coefficient: article.uniteDivers1Coefficient },
          { unite: article.uniteDivers2, coefficient: article.uniteDivers2Coefficient },
          { unite: article.unitePalette, coefficient: article.unitePaletteCoefficient },
        ];

    const unitesArticle = [
      ...new Set(
        unitesPrisesEnCompte
          .filter((a) => a.unite !== '')
          .sort((a, b) => a.coefficient - b.coefficient)
          .map((a) => a.unite)
      ),
    ];

    const stock: SynchronisationStockArticle | undefined = stocks.find(
      (stock: SynchronisationStockArticle) => stock.articleRef === article.articleRef
    );
    const stockDispoConverti = this.conversionUniteRefVersStock(article, stock?.qteStock ?? 0);
    const stockReserveConverti = this.conversionUniteRefVersStock(article, stock?.qteStockReserve ?? 0);

    return {
      ...article,
      panierQuantite: 0,
      panierQuantiteGratuit: 0,
      motifQuantiteGratuit: '',
      panierPrixClavier: null,
      motifPrixClavier: '',
      panierUnite: article.uniteVente,
      panierUnites: unitesArticle,
      estFavori: false,
      chargement: false,
      uniteStockConverti: stockDispoConverti.unite,
      stockConverti: stockDispoConverti.qte,
      stockConvertiCoefficient: stockDispoConverti.coefficient,
      uniteStockReserveConverti: stockReserveConverti.unite,
      stockReserveConverti: stockReserveConverti.qte,
      stockReserveConvertiCoefficient: stockReserveConverti.coefficient,
      tarif: undefined,
      tarifReference: undefined,
    };
  }

  public obtenirCoefficient(article: SynchronisationArticle, unite: string): number {
    switch (unite) {
      case article.uniteAchat:
        return article.uniteAchatCoefficient ?? 1;
      case article.uniteVente:
        return article.uniteVenteCoefficient ?? 1;
      case article.uniteStockage:
        return article.uniteStockageCoefficient ?? 1;
      case article.uniteReference:
        return article.uniteReferenceCoefficient ?? 1;
      case article.uniteDivers1:
        return article.uniteDivers1Coefficient ?? 1;
      case article.uniteDivers2:
        return article.uniteDivers2Coefficient ?? 1;
      case article.unitePalette:
        return article.unitePaletteCoefficient ?? 1;
      default:
        return 1;
    }
  }

  public obtenirCoefficientPanier(article: ArticleEtendu): number {
    return this.obtenirCoefficient(article, article.panierUnite);
  }

  /** @deprecated utiliser obtenirCoefficientPanier à la place */
  public trouverCoefficientCorrespondant(article: ArticleEtendu): number {
    let coefUniteVendu = 1;
    switch (article.panierUnite) {
      case article.uniteAchat:
        coefUniteVendu = article.uniteAchatCoefficient ?? 1;
        break;
      case article.uniteReference:
        coefUniteVendu = article.uniteReferenceCoefficient ?? 1;
        break;
      case article.uniteStockage:
        coefUniteVendu = article.uniteStockageCoefficient ?? 1;
        break;
      case article.uniteVente:
        coefUniteVendu = article.uniteVenteCoefficient ?? 1;
        break;
      case article.uniteDivers1:
        coefUniteVendu = article.uniteDivers1Coefficient ?? 1;
        break;
      case article.uniteDivers2:
        coefUniteVendu = article.uniteDivers2Coefficient ?? 1;
        break;
      case article.unitePalette:
        coefUniteVendu = article.unitePaletteCoefficient ?? 1;
        break;
      default:
        coefUniteVendu = 1;
    }
    return coefUniteVendu;
  }

  /** @deprecated utiliser obtenirCoefficient à la place */
  public trouverCoefficientCorrespondantPanierLigne(
    article: ArticleEtendu | SynchronisationArticle | undefined,
    unite: string
  ): number {
    if (article === undefined) return 1;
    let coefUniteVendu = 1;
    if (article) {
      switch (unite) {
        case article.uniteAchat:
          coefUniteVendu = article.uniteAchatCoefficient ?? 1;
          break;
        case article.uniteReference:
          coefUniteVendu = article.uniteReferenceCoefficient ?? 1;
          break;
        case article.uniteStockage:
          coefUniteVendu = article.uniteStockageCoefficient ?? 1;
          break;
        case article.uniteVente:
          coefUniteVendu = article.uniteVenteCoefficient ?? 1;
          break;
        case article.uniteDivers1:
          coefUniteVendu = article.uniteDivers1Coefficient ?? 1;
          break;
        case article.uniteDivers2:
          coefUniteVendu = article.uniteDivers2Coefficient ?? 1;
          break;
        case article.unitePalette:
          coefUniteVendu = article.unitePaletteCoefficient ?? 1;
          break;
        default:
          coefUniteVendu = 1;
      }
    }
    return coefUniteVendu;
  }

  public conversionUniteRefVersStock(
    article: SynchronisationArticle,
    qteRef: number
  ): {
    unite: string;
    qte: number;
    coefficient: number;
  } {
    if (
      !article.uniteStockage ||
      article.uniteStockageConvertieEn !== article.uniteReference ||
      !article.uniteStockageCoefficient
    ) {
      return {
        unite: article.uniteReference,
        qte: qteRef,
        coefficient: 1,
      };
    } else {
      return {
        unite: article.uniteStockage,
        qte: qteRef / article.uniteStockageCoefficient,
        coefficient: article.uniteStockageCoefficient,
      };
    }
  }

  public async obtenirCodeTarifAvecArticleEtClientActif(
    article: ArticleEtendu | SynchronisationArticle | undefined,
    client: SynchronisationClient | undefined,
    filtreCodeTarif: string | undefined
  ): Promise<TarifLigneEtendu> {
    let codeTarifApplique: string = '';
    let codeTarifExceptionTarifaire: string | undefined = undefined;

    const tarifRetour: TarifLigneEtendu = new TarifLigneEtendu();
    tarifRetour.tarif = undefined;
    tarifRetour.exceptionTarifairePresente = false;

    if (client && article) {
      const exceptionTarifaires = await this._exceptionsTarifaires.pour(client.tiers);
      for (const famille of [article.familleStat1N1Ref, article.familleStat1N2Ref]) {
        if (!codeTarifExceptionTarifaire) codeTarifExceptionTarifaire = exceptionTarifaires.get('1,' + famille);
      }
      for (const famille of [article.familleStat2N1Ref, article.familleStat2N2Ref]) {
        if (!codeTarifExceptionTarifaire) codeTarifExceptionTarifaire = exceptionTarifaires.get('2,' + famille);
      }
      for (const famille of [article.familleStat3N1Ref, article.familleStat3N2Ref]) {
        if (!codeTarifExceptionTarifaire) codeTarifExceptionTarifaire = exceptionTarifaires.get('3,' + famille);
      }

      if (codeTarifExceptionTarifaire) {
        codeTarifApplique = codeTarifExceptionTarifaire;
        tarifRetour.exceptionTarifairePresente = true;
      } else if (client) {
        codeTarifApplique = client.codeTarif;
      }
    } else if (filtreCodeTarif) {
      codeTarifApplique = filtreCodeTarif;
    }
    // Sinon on prend le code tarif du dépot sélectionné, sinon le dépot par défault
    else {
      if (filtreCodeTarif) {
        codeTarifApplique = filtreCodeTarif;
      }
    }

    if (codeTarifApplique) {
      console.log(`yield bdd ${ArticleService.__TEST_COMPTEUR_YIELD++}`);
      let tarifs = await this.dbService.dexie.synchronisation_tarifs
        .where('[codeTarif+articleRef]')
        .equals([codeTarifApplique, article?.articleRef ?? ''])
        .toArray();

      if (!tarifs.length) {
        const codeTarifDepotApplique = await this.recupererCodeTarifDepot();
        if (codeTarifDepotApplique) {
          console.log(`yield bdd ${ArticleService.__TEST_COMPTEUR_YIELD++}`);
          tarifs = await this.dbService.dexie.synchronisation_tarifs
            .where('[codeTarif+articleRef]')
            .equals([codeTarifDepotApplique, article?.articleRef ?? ''])
            .toArray();
        }
      }
      if (tarifs) {
        if (tarifs.length === 1) {
          //si un seul tarif alors on prend le premier
          tarifRetour.tarif = tarifs[0];

          return tarifRetour;
        } else if (tarifs.length > 1) {
          // Filtrer les tarifs avec dateEffet inférieure ou égale à aujourd'hui
          const tarifsFiltres = tarifs
            .filter((t) => new Date(t.dateEffet) <= new Date())
            .sort((a, b) => new Date(b.dateEffet).getTime() - new Date(a.dateEffet).getTime());

          // Si des tarifs correspondent aux critères, trier les tarifs filtrés par dateEffet en ordre décroissant
          if (tarifsFiltres.length > 0) {
            tarifRetour.tarif = tarifsFiltres.find(
              (t) => !t.dateFinValidite || new Date(t.dateFinValidite) >= new Date()
            );
            return tarifRetour;
          }
        }
      }
      return tarifRetour;
    } else {
      return tarifRetour;
    }
  }

  private async recupererCodeTarifDepot(): Promise<string> {
    let codeTarifDepotApplique = '';
    const depotActif: string =
      this.environnementApplicationService.getParametre(ParametreApplicationId.ENVIRONNEMENT_DEPOT) ?? '';
    const depotParDefaut: string | undefined = this.environnementApplicationService.getParametre(
      ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM
    )?.depotParDefautRef;
    console.log(`yield bdd ${ArticleService.__TEST_COMPTEUR_YIELD++}`);
    const depot: SynchronisationInfoDepot | undefined = await this.dbService.dexie.synchronisation_info_depots.get(
      depotActif || depotParDefaut || ''
    );
    codeTarifDepotApplique = depot?.codeTarif ? depot?.codeTarif : '';
    return codeTarifDepotApplique;
  }

  public async calculPrixArticle(
    article: ArticleEtendu,
    client: SynchronisationClient | undefined,
    codeTarif: string | undefined
  ): Promise<{ tarif: TarifSynchronise | undefined; exceptionTarifaireAppliquee: boolean }> {
    let exceptionTarifaireAppliquee = false;
    let tarifs: TarifLigneEtendu;
    let tarif: TarifSynchronise | undefined = undefined;

    if (client) {
      console.log(`yield bdd ${ArticleService.__TEST_COMPTEUR_YIELD++}`);
      const tarifsMarche = (
        await this.dbService.dexie.synchronisation_tarifs_marche
          .where('[codeMarche+articleRef]')
          .equals([client.codeMarche, article.articleRef])
          .toArray()
      )
        .filter(
          (tarif) =>
            new Date(tarif.dateEffet) <= new Date() &&
            (!tarif.dateFinValidite || new Date(tarif.dateFinValidite) >= new Date())
        )
        .sort((a, b) => new Date(b.dateEffet).getTime() - new Date(a.dateEffet).getTime());

      if (tarifsMarche.length > 0) {
        tarif = tarifsMarche[0];
      } else {
        console.log(`yield bdd ${ArticleService.__TEST_COMPTEUR_YIELD++}`);
        const tarifsSpeciaux = (
          await this.dbService.dexie.synchronisation_tarifs_speciaux
            .where('[clientRef+articleRef]')
            .equals([client.tiers, article.articleRef])
            .toArray()
        )
          .filter((tarif) => !tarif.dateFinValidite || new Date(tarif.dateFinValidite) >= new Date())
          .sort((a, b) => new Date(b.dateEffet).getTime() - new Date(a.dateEffet).getTime());

        if (tarifsSpeciaux.length > 0) {
          tarif = tarifsSpeciaux[0];
        } else {
          console.log(`yield bdd ${ArticleService.__TEST_COMPTEUR_YIELD++}`);
          tarifs = await this.obtenirCodeTarifAvecArticleEtClientActif(article, client, codeTarif);
          if (tarifs.tarif) {
            tarif = tarifs.tarif;
            exceptionTarifaireAppliquee = tarifs.exceptionTarifairePresente ?? false;
          }
        }
      }
    } else if (codeTarif) {
      console.log(`yield bdd ${ArticleService.__TEST_COMPTEUR_YIELD++}`);
      tarifs = await this.obtenirCodeTarifAvecArticleEtClientActif(article, undefined, codeTarif);
      if (tarifs.tarif) {
        tarif = tarifs.tarif;
        exceptionTarifaireAppliquee = tarifs.exceptionTarifairePresente ?? false;
      }
    }

    console.log(`calcul prix ${ArticleService.__TEST_COMPTEUR_CALCUL++}`);
    return { tarif, exceptionTarifaireAppliquee };
  }

  public trouverArticleParRef(articleRef: string | undefined): ArticleEtendu | undefined {
    if (articleRef === undefined) return undefined;
    return this._listeArticlesMap.get(articleRef);
  }

  public libellePourUnite(unite: string | null | undefined): string {
    if (!unite) return 'U';
    return this.unites.get(unite)?.uniteLibelle ?? unite;
  }
}
