import { EventEmitter, Injectable } from "@angular/core";
import { DbService } from "../db/db.service";
import { HttpService } from "../http/http.service";
import { SynchronisationService } from "../synchronisation/synchronisation.service";
import { EnvironnementApplicationService } from "../environnement-application/environnement-application.service";
import { Subject, lastValueFrom } from "rxjs";
import { CommercialDTO, ParametresUtilisateurCRM } from "../crm/crm";
import { ParametreApplication, ParametreApplicationId } from "../db/parametre-application";
import { ContactApp, SynchronisationContactAppDTO, SynchronisationContactServeurDTO } from "./contacts";
import { DemandeSynchronisation, SynchronisationContactClient } from "../synchronisation/synchronisation";
import { AuthService } from "../auth/auth.service";
import { AppConfigService } from "../configuration/app-config.service";

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  public rechargementListe = new Subject<void>();

  constructor(
    private authService: AuthService,
    private dbService: DbService,
    private httpService: HttpService,
    private synchronisationService: SynchronisationService,
    private environnementApplicationService: EnvironnementApplicationService,
    private appConfig: AppConfigService
  ) { }

  private async recupererInfosCRM(): Promise<ParametresUtilisateurCRM | undefined> {
    const parametresCrm = await this.dbService.dexie.parametres_application.get(ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM) as  ParametreApplication<ParametresUtilisateurCRM> | undefined;
    return parametresCrm?.valeur;
  }

  public async validerContact(creationContact: ContactApp): Promise<void> {
    const paramsCrm: ParametresUtilisateurCRM | undefined = await this.recupererInfosCRM();
    const paramsUtilisateur: CommercialDTO | undefined = this.environnementApplicationService.getParametre(ParametreApplicationId.COMMERCIAL_ACTIF);
    if (!paramsCrm || (this.appConfig.modeCommercial && !paramsUtilisateur)) return;
    const contact: ContactApp = {
      ...creationContact,
    }
    await this.dbService.dexie.contacts.put(contact);
    const token = this.authService.recupererToken();
    const estConnecte = navigator.onLine;
    if(token && estConnecte) {
      this.synchroniserErp();
    }
  }

  public async synchroniserErp(emitter?: EventEmitter<boolean>): Promise<void> {
    this.synchronisationService.synchronisationEnCours = true;
    const contacts: ContactApp[] = await this.dbService.dexie.contacts.toArray();
    if (!contacts || contacts.length == 0) {
      const horodatageContacts = await this.dbService.dexie.parametres_application.get(ParametreApplicationId.DERNIERE_SYNCHRO_CONTACTS_CLIENTS) as ParametreApplication<string> | undefined;
      const dtoSynchro: DemandeSynchronisation = { CONTACTS_CLIENTS: horodatageContacts?.valeur ?? null };
      try {
        await this.synchronisationService.synchroniser(dtoSynchro);
        this.synchronisationService.synchronisationEnCours = false;
      } catch (error) {
        this.synchronisationService.synchronisationEnCours = false;
      }
      return;
    }

    const dto = new SynchronisationContactAppDTO();
    dto.contacts = contacts.concat();

    try {
      await lastValueFrom(this.httpService.postFromApi<SynchronisationContactServeurDTO>(("business/crm/contact"), dto));
      this.suppressionContactTemporaire(dto);
      const horodatageContacts = await this.dbService.dexie.parametres_application.get(ParametreApplicationId.DERNIERE_SYNCHRO_CONTACTS_CLIENTS) as ParametreApplication<string> | undefined;
      const dtoSynchro: DemandeSynchronisation = { CONTACTS_CLIENTS: horodatageContacts?.valeur ?? null };
      await this.synchronisationService.synchroniser(dtoSynchro);

      this.synchronisationService.synchronisationEnCours = false;
      this.rechargementListe.next();
      if (emitter) emitter.emit(true);
    } catch (e) {
      this.synchronisationService.synchronisationEnCours = false;
      if (emitter) emitter.emit(false);
    }
    this.synchronisationService.synchronisationEnCours = false;
  }

  private suppressionContactTemporaire(dto: SynchronisationContactAppDTO) {
    dto.contacts.forEach(element => {
      this.dbService.dexie.contacts.delete(element.idIndexedDB);
    });
  }

  public async enregistrerContact(contactSynchro: SynchronisationContactClient, action: string, emitter: EventEmitter<boolean>): Promise<void> {
    // NOTE: pour l'instant les utilisateurs clients finaux ne peuvent pas modifier de contacts,
    // il faudra modifier cette méthode si on veut donner cette possibilité
    const paramsUtilisateur: CommercialDTO | undefined = this.environnementApplicationService.getParametre(ParametreApplicationId.COMMERCIAL_ACTIF);
    const contact = ContactApp.fromContactsSynchro(contactSynchro, action);
    contact.utilisateurModif = paramsUtilisateur?.utilisateurKali;
    contact.dateModif = new Date().toISOString();
    contact.tiers = contactSynchro.tiers;
    contact.nomTiers = contactSynchro.nomTiers;
    contact.email = contactSynchro.email;
    contact.telephoneMobile = contactSynchro.telephone;
    contact.prenom = contactSynchro.prenom;
    contact.nom = contactSynchro.nom;

    await this.dbService.dexie.contacts.put(contact);
    const token = this.authService.recupererToken();
    const estConnecte = navigator.onLine;
    if(token && estConnecte) {
      this.synchroniserErp(emitter);
    }
  }

  public calculerAge(dateNaissance: string | undefined): number {
    if (!dateNaissance) return 0; // Retourne 0 si la date est indéfinie
    const naissance = new Date(dateNaissance);
    const aujourdHui = new Date();

    let age = aujourdHui.getFullYear() - naissance.getFullYear();
    const mois = aujourdHui.getMonth() - naissance.getMonth();
    const jour = aujourdHui.getDate() - naissance.getDate();

    // Réduire l'âge si l'anniversaire n'est pas encore passé cette année
    if (mois < 0 || (mois === 0 && jour < 0)) {
      age--;
    }

    return age;
  }


}
