import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { LoggerService } from '../log/logger.service';
import { PlateformeIOS } from './ios';
import { PlateformeAndroid } from './android';
import { PlateformeWeb } from './web';
import type { ConfigPlateforme, FichierATelecharger, Plateforme } from '.';
import { HttpService } from '../http/http.service';
import { HttpResponse } from '@angular/common/http';
import { recupererJetonFirebase } from './notificationsPush';
import {
  CRMEnregistrementAppareilDTO,
  recupererIdentifiantAppareil,
  recupererInformationsComplementairesAppareil,
} from './appareil';
import packageJson from 'package.json';
import { Identifiants } from '../login/dto/identifiants';
import { AppLauncher } from '@capacitor/app-launcher';

const LISTE_BLANCHE_PROTOCOLE_URL = ['https']

@Injectable({
  providedIn: 'root',
})
export class PlateformeService {
  private readonly plateforme: Plateforme;
  private config: ConfigPlateforme;

  constructor(private loggerService: LoggerService, private httpService: HttpService) {
    switch (Capacitor.getPlatform()) {
      case 'ios':
        this.plateforme = new PlateformeIOS(this.loggerService);
        break;
      case 'android':
        this.plateforme = new PlateformeAndroid(this.loggerService);
        break;
      default:
        this.plateforme = new PlateformeWeb(this.loggerService);
        break;
    }
    this.loggerService.info(`Utilisation des services plateforme ${this.plateforme.nom}`);

    this.config = {};
  }

  public get estNatif(): boolean {
    return this.plateforme.estNative;
  }

  public async configurer(): Promise<void> {
    try {
      this.config = await this.plateforme.configurer();
    } catch (e) {
      this.loggerService.error(`Erreur lors de la configuration des services plateforme: ${e}`);
    }
  }

  public telechargerEtOuvrir(fichier: FichierATelecharger): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let reponse;
      if (fichier.chargeUtile === undefined) {
        reponse = this.httpService.getBlobFromApi(fichier.url, fichier.params);
      } else {
        reponse = this.httpService.postBlobFromApi(fichier.url, fichier.chargeUtile, fichier.params);
      }
      reponse.subscribe({
        next: async (reponse: HttpResponse<Blob>) => {
          if (!reponse || !reponse.body || reponse.body.size === 0) {
            resolve(false);
          } else {
            await this.plateforme.enregistrerEtOuvrir(fichier.nom, fichier.type ?? '*', reponse.body);
            resolve(true);
          }
        },
        error: reject,
      });
    });
  }

  public telechargerSansOuvrir(fichier: FichierATelecharger): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let reponse;
      if (fichier.chargeUtile === undefined) {
        reponse = this.httpService.getBlobFromApi(fichier.url, fichier.params);
      } else {
        reponse = this.httpService.postBlobFromApi(fichier.url, fichier.chargeUtile, fichier.params);
      }
      reponse.subscribe({
        next: async (reponse: HttpResponse<Blob>) => {
          if (!reponse || !reponse.body || reponse.body.size === 0) {
            resolve(false);
          } else {
            await this.plateforme.enregistrerSeulement(fichier.nom, fichier.type, reponse.body);
            resolve(true);
          }
        },
        error: reject,
      });
    });
  }

  public async enregistrerSeulement(nom: string, blob: Blob, type?: string): Promise<void> {
    await this.plateforme.enregistrerSeulement(nom, type, blob);
  }

  private recupererJetonFirebase(): Promise<string> {
    return recupererJetonFirebase(this.config.optionsEnregistrementFirebase);
  }

  public async enregistrerAppareil(): Promise<void> {
    let jetonFirebaseMessaging;
    if (await this.plateforme.verifierPermissionsNotifications()) {
      jetonFirebaseMessaging = await this.recupererJetonFirebase();
      this.loggerService.verbose(`Notifications OK, jeton firebase messaging: [${jetonFirebaseMessaging}]`);
    } else {
      jetonFirebaseMessaging = "KAL-DESACTIVE";
      this.loggerService.verbose(`Notifications desactivées`);
    }

    const identifiantAppareil = await recupererIdentifiantAppareil();
    this.loggerService.info(`L'identifiant de cet appareil est [${identifiantAppareil}]`);

    const infosComplementaires = await recupererInformationsComplementairesAppareil();
    this.loggerService.info(`Informations de l'appareil: ${JSON.stringify(infosComplementaires)}`);

    const appareil: CRMEnregistrementAppareilDTO = {
      identifiantAppareil,
      plateforme: this.plateforme.codePlateforme,
      infosComplementaires,
      versionCRM: packageJson.version,
      jetonFirebaseMessaging,
    };
    console.log(JSON.stringify(appareil));

    try {
      await this.httpService.postFromApiDiscret('business/crm/enregistrement-appareil', appareil);
      this.loggerService.info('Appareil enregistré avec succès');
    }
    catch (err) {
      this.loggerService.error(`Erreur lors de l'enregistrement de l'appareil: ${JSON.stringify(err)}`);
    }
  }

  public async inviterEnregistrerMotDePasse(identifiants: Identifiants): Promise<void> {
    this.plateforme.inviterAEnregistrerMotDePasse(identifiants);
  }

  public async ourvirUrlExterne(url: string | null | undefined): Promise<boolean> {
    if (!url) return false;
    if (LISTE_BLANCHE_PROTOCOLE_URL.every(protocole => !url.startsWith(protocole + '://'))) {
      this.loggerService.warn(`Ouverture de l'URL "${url}" bloquée car ce protocole n'est pas autorisé.`);
      return false;
    }
    const resultat = await AppLauncher.openUrl({ url });
    return resultat.completed;
  }
}
