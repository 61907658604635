import { SynchronisationInfoDepot } from "../../synchronisation-quotidienne/synchronisation-quotidienne";
import { SynchronisationArticle, SynchronisationClient, TarifSynchronise } from "../../synchronisation/synchronisation";

export enum PanierStatut {
  MARQUE_NON_VALIDE = 0,
  MARQUE_VALIDE_NS = 1,
}

export enum PanierType {
  DEVIS = 1,
  COMMANDE = 2,
  // BL = 3,
  // FACTURE = 4
}

export class PanierEnteteApp {
  public idIndexedDB?: number;
  public id?: number;
  public commercialRef!: string;
  public clientRef!: string;
  public codeTarif!: string;
  public dateLivraisonEstimee?: string;
  public dateCreation!: string;
  public dateModif!: string;
  public statut!: PanierStatut;
  public typePiece!: PanierType;
  public tournee!: string;
  public commandeRef?: string;
  public utilisateurCreation?: string;
  public utilisateurModif?: string;
  public depotRef!: string;
  public etablissement!: string;
  public dateSaisie!: string;

  public nomClient!: string;
  public rue?: string;
  public localite?: string;
  public adresseComplement1?: string;
  public adresseComplement2?: string;
  public codePostal?: string;
  public ville?: string;
  public codeAdresse?: string;

  public supprimee!: boolean;
  public selectionne!: boolean;

  constructor(
    commercialRef: string,
    clientRef: string,
    codeTarif: string,
    depotRef: string,
    type: PanierType,
    etablissement: string
  ) {
    this.commercialRef = commercialRef;
    this.clientRef = clientRef;
    this.codeTarif = codeTarif
    this.depotRef = depotRef;
    this.typePiece = type;
    const date = new Date();
    this.dateCreation = date.toISOString();
    this.dateSaisie = date.toISOString();
    this.dateModif = date.toISOString();
    this.statut = PanierStatut.MARQUE_NON_VALIDE;
    this.supprimee = false;
    this.selectionne = false;
    this.etablissement = etablissement;
  }
}

export class PanierEnteteServeur {
  public idIndexedDB?: number; // spécif appli pour merge les lignes et les commentaires
  public readonly id!: number;
  public readonly commercialRef!: string;
  public readonly clientRef!: string;
  public readonly dateLivraisonEstimee?: string;
  public readonly statut!: PanierStatut;
  public readonly commandeRef?: string;
  public readonly rue?: string;
  public readonly adresseComplement1?: string;
  public readonly adresseComplement2?: string;
  public readonly codePostal?: string;
  public readonly ville?: string;
  public readonly codeAdresse?: string;
  public readonly createdBy!: string;
  public readonly createdDate!: string;
  public readonly lastModifiedBy!: string;
  public readonly lastModifiedDate!: string;
  public readonly indicateurSuppression!: number;
  public readonly depotRef!: string;
  public readonly typePiece!: PanierType;
  public readonly tournee!: string;
  public readonly codeTarif!: string;
  public readonly etablissement!: string;
  public readonly dateSaisie!: string;
  public readonly nomClient!: string;
}

export class PanierLigneApp {
  public idIndexedDB?: number;
  public panierIdIndexedDB?: number;
  public idCreation!: number | null;

  public id?: number;
  public panierId?: number;
  public articleRef!: string;
  public articleDesignation!: string;
  public uniteVente!: string;
  public quantite: number = 1;
  public dateCreation!: string;
  public dateModif!: string;
  public utilisateurCreation?: string;
  public utilisateurModif?: string;
  public qteGratuit: number = 0;
  public prixClavier: number | string | null = null;
  public prixUniteBase: number = 0;
  public prixUniteBaseEstNet: boolean;
  public uniteReference: string;
  public coefVente: number;
  public montantTotalLigne: number = 0;
  public prixUniteBaseRemise: number = 0;
  public motifGratuiteQuantite: string = "";
  public motifGratuitePrixClavier: string = "";
  public gratuiteAuto: boolean = false;
  public articleRefGratuitChoisi: string = "";
  public idParent: number | null;
  public remiseAppliquee: number | null;
  public remiseQteAppliquee: number | null;
  public exclureGratuite: boolean = false;
  public supprimee!: boolean;
  public aRecalculer: boolean = true;
  public tarifPourUnite?: number | null;
  public tarifPourUniteRemise?: number | null;
  public quantiteGratuitEtendu?: number | null;
  public consignePourUnite?: number | null;

  constructor(init: {
    panierIdIndexedDB: number,
    articleRef: string,
    uniteVente: string,
    quantite: number,
    qteGratuit: number,
    motifGratuiteQuantite: string,
    prixClavier: number | null,
    motifGratuitePrixClavier: string,
    prixUniteBase: number,
    prixUniteBaseEstNet: boolean,
    uniteReference: string,
    coefVente: number,
    gratuiteAuto: boolean,
    articleRefGratuitChoisi: string,
    idParent: number | null,
    remiseAppliquee: number | null,
    exclureGratuite: boolean,
    remiseQteAppliquee: number | null,
  }) {
    this.panierIdIndexedDB = init.panierIdIndexedDB;
    this.articleRef = init.articleRef;
    this.uniteVente = init.uniteVente;
    this.quantite = init.quantite;
    this.qteGratuit = init.qteGratuit;
    this.motifGratuiteQuantite = init.motifGratuiteQuantite;
    this.prixClavier = init.prixClavier;
    this.motifGratuitePrixClavier = init.motifGratuitePrixClavier;
    this.prixUniteBase = init.prixUniteBase;
    this.prixUniteBaseEstNet = init.prixUniteBaseEstNet;
    this.uniteReference = init.uniteReference;
    this.coefVente = init.coefVente;
    const date = new Date();
    this.dateCreation = date.toISOString();
    this.dateModif = date.toISOString();
    this.gratuiteAuto = init.gratuiteAuto;
    this.articleRefGratuitChoisi = init.articleRefGratuitChoisi;
    this.idParent = init.idParent;
    this.idCreation = this.idIndexedDB ?? null;
    this.exclureGratuite = init.exclureGratuite;
    this.remiseAppliquee = init.remiseAppliquee;
    this.remiseQteAppliquee = init.remiseQteAppliquee;
    this.supprimee = false;
    this.aRecalculer = true;
  }
}

export class PanierLigneServeur {
  public idIndexedDB?: number; // spécif appli
  public panierIdIndexedDB?: number; // spécif appli
  public readonly id!: number;
  public readonly panierEnteteId!: number;
  public readonly articleRef!: string;
  public readonly uniteVente!: string;
  public readonly quantite!: number;
  public readonly createdBy!: string;
  public readonly createdDate!: string;
  public readonly lastModifiedBy!: string;
  public readonly lastModifiedDate!: string;
  public readonly qteGratuit!: number;
  public readonly prixClavier!: number | null;
  public readonly prixUniteBase!: number;
  public readonly uniteReference!: string;
  public readonly coefVente!: number;
  public readonly montantTotalLigne!: number;
  public readonly motifGratuiteQuantite!: string;
  public readonly motifGratuitePrixClavier!: string;
  public readonly gratuiteAuto!: boolean;
  public readonly articleRefGratuitChoisi!: string;
  public readonly idParent!: number;
  public idCreation: number | null = null;
  public remiseAppliquee: number | null = null;
  public exclureGratuite: boolean = false;
  public remiseQteAppliquee: number | null = null;
}

export class PanierCommentaireApp {
  public idIndexedDB?: number;
  public panierIdIndexedDB?: number;
  public panierLigneIdIndexedDB?: number;

  public id?: number;
  public panierId?: number;
  public panierLigneId?: number;
  public texte!: string;
  public dateCreation!: string;
  public dateModif!: string;
  public utilisateurCreation?: string;
  public utilisateurModif?: string;

  public supprimee!: boolean;

  constructor(
    panierIdIndexedDB: number,
    texte: string,
  ) {
    this.panierIdIndexedDB = panierIdIndexedDB;
    this.texte = texte;
    const date = new Date();
    this.dateCreation = date.toISOString();
    this.dateModif = date.toISOString();
    this.supprimee = false;
  }
}

export class PanierNoteApp {
  public idIndexedDB?: number;
  public panierIdIndexedDB?: number;
  public panierLigneIdIndexedDB?: number;

  public id?: number;
  public panierId?: number;
  public panierLigneId?: number;
  public texte!: string;
  public dateCreation!: string;
  public dateModif!: string;
  public utilisateurCreation?: string;
  public utilisateurModif?: string;

  public supprimee!: boolean;

  constructor(
    panierIdIndexedDB: number,
    texte: string,
  ) {
    this.panierIdIndexedDB = panierIdIndexedDB;
    this.texte = texte;
    const date = new Date();
    this.dateCreation = date.toISOString();
    this.dateModif = date.toISOString();
    this.supprimee = false;
  }
}

export class PanierCommentaireServeur {
  public idIndexedDB?: number; // spécif appli
  public panierIdIndexedDB?: number; // spécif appli
  public panierLigneIdIndexedDB?: number; // spécif appli
  public readonly id!: number;
  public readonly panierEnteteId!: number;
  public readonly panierLigneId?: number;
  public readonly texte!: string;
  public readonly createdBy!: string;
  public readonly createdDate!: string;
  public readonly lastModifiedBy!: string;
  public readonly lastModifiedDate!: string;
}

export class PanierNoteServeur {
  public idIndexedDB?: number; // spécif appli
  public panierIdIndexedDB?: number; // spécif appli
  public panierLigneIdIndexedDB?: number; // spécif appli
  public readonly id!: number;
  public readonly panierEnteteId!: number;
  public readonly panierLigneId?: number;
  public readonly texte!: string;
  public readonly createdBy!: string;
  public readonly createdDate!: string;
  public readonly lastModifiedBy!: string;
  public readonly lastModifiedDate!: string;
}

export type PanierLigneEtendu = PanierLigneApp & {
  article?: SynchronisationArticle;
  commentaire?: PanierCommentaireApp;
  note?: PanierNoteApp;
};

export type PanierEnteteEtendu = PanierEnteteApp & {
  client?: SynchronisationClient;
  lignes?: PanierLigneEtendu[];
  depot?: SynchronisationInfoDepot;
  commentaire?: PanierCommentaireApp;
  note?: PanierNoteApp;
  total?: number;
  selectionne?: boolean;
};

export class SynchronisationPaniersAppDTO {
  public entetesSupprimees!: number[];
  public entetes!: PanierEnteteApp[];
  public lignesSupprimees!: number[];
  public lignes!: PanierLigneApp[];
  public commentairesSupprimes!: number[];
  public commentaires!: PanierCommentaireApp[];
  public notesSupprimees!: number[];
  public notes!: PanierCommentaireApp[];
}

export class SynchronisationPaniersServeurDTO {
  public entetes!: PanierEnteteServeur[];
  public lignes!: PanierLigneServeur[];
  public commentaires!: PanierCommentaireServeur[];
  public notes!: PanierNoteServeur[];
}

export class TarifLigneEtendu {
  public tarif?: TarifSynchronise;
  public exceptionTarifairePresente?: boolean;
}
