import type { ConfigPlateforme, Plateforme } from '.'
import { LoggerService } from '../log/logger.service';
import { SafeArea, SafeAreaInsets } from 'capacitor-plugin-safe-area';
import { ouvrirFichierAvecType, recupererFichier } from './fichiers';
import { demanderPermissionsEnvoiNotifications } from './notificationsPush';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { Identifiants } from '../login/dto/identifiants';

export class PlateformeIOS implements Plateforme {
  public constructor(private loggerService: LoggerService) { }

  public readonly codePlateforme = 3;
  public readonly nom = 'iOS';
  public readonly estNative = true;

  public async configurer(): Promise<ConfigPlateforme> {
    this.loggerService.verbose('Configuration de la zone de securite...');
    SafeArea.removeAllListeners();
    SafeArea.getSafeAreaInsets().then(PlateformeIOS.mettreAJourVariablesCssZoneDeSecurite)
    SafeArea.addListener('safeAreaChanged', PlateformeIOS.mettreAJourVariablesCssZoneDeSecurite);
    return {}
  }

  private static mettreAJourVariablesCssZoneDeSecurite(donnees: SafeAreaInsets): void {
    document.documentElement.style.setProperty('--kal-marge-securite-haut', `${donnees.insets.top}px`);
    document.documentElement.style.setProperty('--kal-marge-securite-bas', `${donnees.insets.bottom}px`);
    document.documentElement.style.setProperty('--kal-marge-securite-gauche', `${donnees.insets.left}px`);
    document.documentElement.style.setProperty('--kal-marge-securite-droite', `${donnees.insets.right}px`);
  }

  public async enregistrerEtOuvrir(nom: string, type: string | undefined, blob: Blob): Promise<void> {
    const chemin = await recupererFichier(nom, blob);
    await ouvrirFichierAvecType(chemin, type);
  }

  public async enregistrerSeulement(nom: string, type: string | undefined, blob: Blob): Promise<void> {
    const chemin = await recupererFichier(nom, blob);
    // on ouvre quand même pour que l'utilisateur y ait accès
    await ouvrirFichierAvecType(chemin, type);
  }

  public async verifierPermissionsNotifications(): Promise<boolean> {
    this.loggerService.verbose("Demande des permissions pour les notifications (iOS)")
    try {
      await demanderPermissionsEnvoiNotifications();
      return true;
    } catch (e) {
      this.loggerService.error(String(e));
      return false;
    }
  }

  public async inviterAEnregistrerMotDePasse(identifiants: Identifiants): Promise<void> {
    console.log("promptDialog");
    await SavePassword.promptDialog(identifiants);
  }
}