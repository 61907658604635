import { FileOpener } from '@capacitor-community/file-opener';
import { Directory, Filesystem } from '@capacitor/filesystem';
import write_blob from 'capacitor-blob-writer';

export async function recupererFichier(nomFichier: string, blob: Blob): Promise<string> {
  try {
    await enregistrerBlob(blob, nomFichier);
  } catch (e) {
    console.error(e);
  }
  const resultatUri = await Filesystem.getUri({
    path: nomFichier,
    directory: Directory.Cache,
  });
  return resultatUri.uri;
}

function enregistrerBlob(blob: Blob, nomFichier: string): Promise<string> {
  return write_blob({
    path: nomFichier,
    directory: Directory.Cache,
    blob: blob,
  });
}

export async function ouvrirFichierAvecType(chemin: string, type: string | undefined) {
  try {
    await FileOpener.open({
      filePath: chemin,
      contentType: type,
    });
  } catch (error) {
    console.error(error);
  }
}
