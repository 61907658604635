import { EnvironnementApplicationService } from './../../../services/environnement-application/environnement-application.service';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { CrmPanierService } from 'src/app/services/crm/crm-panier/crm-panier.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { environment } from 'src/environments/environment';
import { AjoutModalComponent } from '../../fragments/ajout-modal/ajout-modal.component';
import { DbService } from 'src/app/services/db/db.service';
import { ParametreApplication, ParametreApplicationId } from 'src/app/services/db/parametre-application';
import { VerifIPhoneService } from 'src/app/services/verif-iphone/verif-iphone.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DonneesService } from 'src/app/services/donnees/donnees.service';
import { CommercialDTO, ParametresUtilisateurCRM } from 'src/app/services/crm/crm';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { LoginService } from 'src/app/services/login/login.service';
import { SynchronisationService } from 'src/app/services/synchronisation/synchronisation.service';
import { PartitionnementModule } from 'src/app/tools/directives/partitionnement/partitionnement.module';
import { SynchronisationClient } from 'src/app/services/synchronisation/synchronisation';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { liveQueryReactif } from 'src/app/services/db/liveQueryReactif';
import { FamillesArticleService } from 'src/app/services/articles/familles-article.service';

enum Vue {
  Tache,
  Client
}
@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule, PartitionnementModule],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  private ngDestroy$ = new Subject<void>();
  
  public environment = environment;
  public afficherMenu = false;
  public afficherMenuAdd = false;
  public afficherMenuCompte = false;
  public synchro = false;

  public parametresCrm: ParametreApplication<ParametresUtilisateurCRM | undefined> | undefined;
  public commercial?: CommercialDTO;
  public clientsDisponibles: SynchronisationClient[] = [];
  public clientActif: string = '';

  constructor(
    private router: Router,
    private modalService: ModalService,
    private crmPanierService: CrmPanierService,
    private dbService: DbService,
    private verifIphoneService: VerifIPhoneService,
    private donneesService: DonneesService,
    private environnementApplicationService: EnvironnementApplicationService,
    public loginService: LoginService,
    private synchronisationService: SynchronisationService,
    public appConfig: AppConfigService,
    private famillesArticleService: FamillesArticleService,
  ) { }

  async ngOnInit(): Promise<void> {

    this.environnementApplicationService.getParametreReactifBrut(ParametreApplicationId.COMMERCIAL_ACTIF)
    .subscribe(() => {
      this.commercial = this.environnementApplicationService.getParametre(ParametreApplicationId.COMMERCIAL_ACTIF);
    });


    this.parametresCrm = await firstValueFrom(this.environnementApplicationService.getParametreReactifBrut(ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM));
    if(this.parametresCrm?.valeur?.clientRef) {
        this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.CLIENT_ACTIF, valeur:this.parametresCrm.valeur.clientRef as never });
    }
    this.router.events.subscribe(() => {
      this.afficherMenu = false;
      this.afficherMenuAdd = false;
    });

    if (this.appConfig.modeClientFinal) {
      this.environnementApplicationService
        .getParametreReactifBrut(ParametreApplicationId.CLIENT_ACTIF)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe((parametre) => {
          this.clientActif = parametre?.valeur ?? '';
        });

      liveQueryReactif(() => this.dbService.dexie.synchronisation_clients.toArray(), [])
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe((clients) => {
          this.clientsDisponibles = clients;
        });
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  public get nbPaniers(): number {
    return this.crmPanierService.listePaniersValeurs.length;
  }

  public clicSurMenu(): void {
    this.afficherMenu = !this.afficherMenu;
    if (this.afficherMenuAdd == true) {
      this.afficherMenuAdd = !this.afficherMenuAdd;
    }
  }

  public clicSurCatalogue(): void {
    if (this.router.url.match(/^\/crm\/produits\/[^?]/)) {
      this.router.navigate(['crm', 'produits'], { queryParamsHandling: 'preserve' });
    } else if (this.router.url.match(/^\/crm\/produits$/)) {
      this.router.navigate(['crm', 'catalogue', this.famillesArticleService.derniereSousFamilleRef]);
    } else {
      this.router.navigate(['crm', 'catalogue']);
    }
  }

  public clicSurPanier(): void {
    this.router.navigate(['crm', 'paniers']);
  }

  public clicSurMenuAdd(): void {
    this.afficherMenuAdd = !this.afficherMenuAdd;
    if (this.afficherMenu == true) {
      this.afficherMenu = !this.afficherMenu;
    }
  }

  public clicSurMenuCompte(): void {
    this.router.navigate(['crm', 'mon-compte']);
  }

  public clicSurUtilisateur(): void {
    this.afficherMenuCompte = false;
    this.router.navigate(['crm', 'mon-compte']);
  }

  public clicSurMaison(): void {
    this.router.navigate(['']);
  }

  public gererCLic(event: MouseEvent): void {
    const cible = event.target as HTMLElement;
    const menuClic: boolean = cible.closest('.menu__nav') !== null;
    if (!menuClic) {
      this.afficherMenu = false;
      this.afficherMenuAdd = false;
    }
  }

  public fermerSousMenu(): void {
    if(this.synchro) {
      this.afficherMenu = true;
      this.afficherMenuAdd = false;
    }
    else {
      this.afficherMenu = false;
      this.afficherMenuAdd = false;
    }
  }

  public ajouterPanier(): void {
    const parametreClientActif: string | undefined = this.environnementApplicationService.getParametre(ParametreApplicationId.CLIENT_ACTIF) ?? "";
    const avertissement = localStorage.getItem("avertissementMontant") || "";
    if(parametreClientActif && avertissement !== "vu") {
      localStorage.setItem('avertissementMontant', "");
    }
    this.dbService.dexie.parametres_application.delete(ParametreApplicationId.PANIER_ACTIF).then(() => {
      this.router.navigate(['crm', 'produits'], {queryParams: { utiliserReferencementClient: !!parametreClientActif }});
    });
  }

  public ajouterContact(): void {
    const modal: NgbModalRef = this.modalService.open(AjoutModalComponent, { size: "xl" });
    modal.componentInstance.referenceModal = modal;
    modal.componentInstance.vue = Vue.Client;
  }

  public ajouterTache(): void {
    const modal: NgbModalRef = this.modalService.open(AjoutModalComponent, { size: "xl" });
    modal.componentInstance.referenceModal = modal;
    modal.componentInstance.vue = Vue.Tache;
  }

  public estIphone(): boolean {
    return this.verifIphoneService.estIPhoneDixPlus();
  }

  public async synchroniser(): Promise<void> {
    this.synchro = true;
    this.afficherMenu = true;
    if(this.synchronisationService.synchronisationEnCours || this.synchronisationService.synchronisationTotaleEnCours) return;
    this.synchronisationService.synchronisationEnCours = true;
    this.synchronisationService.synchronisationTotaleEnCours = true;
    await Promise.all([
      this.donneesService.synchroniser(),
      this.donneesService.synchroniserQtd(),
    ]);
    this.synchro = false;
    this.fermerSousMenu();
    this.synchronisationService.synchronisationTotaleEnCours = false;
    return;
  }

  public selectionnerClient(client: SynchronisationClient): void {
    this.afficherMenuCompte = false;
    this.environnementApplicationService.setParametre(ParametreApplicationId.CLIENT_ACTIF, client.tiers);
  }
}
