import { mapTable } from 'src/app/tools/Fonctions/fonctions';
import { SynchronisationArticle, SynchronisationFamilleStat } from '../synchronisation/synchronisation';
import { DbService } from '../db/db.service';

export class EnrichissementArticles {
  public constructor(private dbService: DbService) {}

  private famillesStat1?: Map<string, SynchronisationFamilleStat>;
  public async chargerFamillesStat1(): Promise<void> {
    this.famillesStat1 = await mapTable(
      this.dbService.dexie.synchronisation_familles_stat_1,
      (fam) => fam.familleStatRef
    );
  }

  public traiterListeArticles(tableau: SynchronisationArticle[]): void {
    for (const article of tableau) this.traiterArticle(article);
  }

  public traiterArticle(article: SynchronisationArticle): void {
    if (this.famillesStat1) {
      article.statutMaBoutique = 'V';
      for (const familleRef of [
        article.familleStat1N4Ref,
        article.familleStat1N3Ref,
        article.familleStat1N2Ref,
        article.familleStat1N1Ref,
      ]) {
        if (familleRef) {
          const famille = this.famillesStat1.get(familleRef);

          if (famille?.statutMaBoutique === 'C') {
            article.statutMaBoutique = 'C';
          } else if (famille?.statutMaBoutique === 'R' && article.statutMaBoutique !== 'C') {
            article.statutMaBoutique = 'R';
          }

          const libelleFamille = (famille?.familleStatLibelle ?? "").replace(/_+/g, '').trim();
          if (article.familleStat1LibelleRecherche) {
            article.familleStat1LibelleRecherche = libelleFamille + " " + article.familleStat1LibelleRecherche;
          } else {
            article.familleStat1LibelleRecherche = libelleFamille;
          }
        }
      }
    }
  }
}
