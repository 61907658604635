import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Table } from 'dexie';
import { lastValueFrom } from 'rxjs';
import { ParametreApplication, ParametreApplicationId } from '../db/parametre-application';
import { DbService } from '../db/db.service';
import { HttpService } from '../http/http.service';
import { DemandeSynchronisation, Synchronisation } from './synchronisation';
import { CommercialDTO, ProfilUtilisateur, ParametresUtilisateurCRM } from '../crm/crm';
import { AppConfigService } from '../configuration/app-config.service';
import { majDerniereSynchro } from 'src/app/tools/Fonctions/synchro';
import { FamillesArticleService } from '../articles/familles-article.service';
import { EnrichissementArticles } from '../articles/enrichissement-articles';

@Injectable({
  providedIn: 'root'
})
export class SynchronisationService {

  public etapeActuelleSynchro: number = 0;
  public nombreEtapesTotalSynchro = 17;
  public etapeEnCours: string = "";

  public synchronisationEnCours: boolean = false;
  public synchronisationTotaleEnCours: boolean = false;

  constructor(
    private http: HttpService,
    private dbService: DbService,
    private appConfigService: AppConfigService,
    private famillesArticleService: FamillesArticleService,
  ) { }

  public async synchroniser(dtoSynchro?: DemandeSynchronisation): Promise<Synchronisation> {
    this.synchronisationEnCours = true;

    const [
      parametresUtilisateurCRM,
      parametresUtilisateur,
      commercialActif,
      horodatageClients,
      horodatageContactsClients,
      horodatageEvenements,
      horodatageFamillesStat,
      horodatageArticles,
      horodatageArticleMedias,
      horodatageTarifs,
      horodatageTarifsMarche,
      horodatageTarifsSpeciaux,
      horodatageRemises,
      horodatageRemisesQuantite,
      horodatageRistournes,
      horodatageOdrd
    ] = await Promise.all([
      this.dbService.dexie.parametres_application.get(ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM),
      this.dbService.dexie.parametres_application.get(ParametreApplicationId.PROFIL_UTILISATEUR),
      this.dbService.dexie.parametres_application.get(ParametreApplicationId.COMMERCIAL_ACTIF),
      this.dbService.dexie.parametres_application.get(ParametreApplicationId.DERNIERE_SYNCHRO_CLIENTS),
      this.dbService.dexie.parametres_application.get(ParametreApplicationId.DERNIERE_SYNCHRO_CONTACTS_CLIENTS),
      this.dbService.dexie.parametres_application.get(ParametreApplicationId.DERNIERE_SYNCHRO_EVENEMENTS),
      this.dbService.dexie.parametres_application.get(ParametreApplicationId.DERNIERE_SYNCHRO_FAMILLES_STAT),
      this.dbService.dexie.parametres_application.get(ParametreApplicationId.DERNIERE_SYNCHRO_ARTICLES),
      this.dbService.dexie.parametres_application.get(ParametreApplicationId.DERNIERE_SYNCHRO_ARTICLE_MEDIAS),
      this.dbService.dexie.parametres_application.get(ParametreApplicationId.DERNIERE_SYNCHRO_TARIFS),
      this.dbService.dexie.parametres_application.get(ParametreApplicationId.DERNIERE_SYNCHRO_TARIFS_MARCHE),
      this.dbService.dexie.parametres_application.get(ParametreApplicationId.DERNIERE_SYNCHRO_TARIFS_SPECIAUX),
      this.dbService.dexie.parametres_application.get(ParametreApplicationId.DERNIERE_SYNCHRO_REMISES),
      this.dbService.dexie.parametres_application.get(ParametreApplicationId.DERNIERE_SYNCHRO_REMISES_QUANTITE),
      this.dbService.dexie.parametres_application.get(ParametreApplicationId.DERNIERE_SYNCHRO_RISTOURNES),
      this.dbService.dexie.parametres_application.get(ParametreApplicationId.DERNIERE_SYNCHRO_ODRD),
    ]) as [
      ParametreApplication<ParametresUtilisateurCRM> | undefined,
      ParametreApplication<ProfilUtilisateur> | undefined,
      ParametreApplication<CommercialDTO> | undefined,
      ParametreApplication<string> | undefined,
      ParametreApplication<string> | undefined,
      ParametreApplication<string> | undefined,
      ParametreApplication<string> | undefined,
      ParametreApplication<string> | undefined,
      ParametreApplication<string> | undefined,
      ParametreApplication<string> | undefined,
      ParametreApplication<string> | undefined,
      ParametreApplication<string> | undefined,
      ParametreApplication<string> | undefined,
      ParametreApplication<string> | undefined,
      ParametreApplication<string> | undefined,
      ParametreApplication<string> | undefined,
    ];

    const derniereDateSynchroParametresUtilisateurCRM: Date | null = parametresUtilisateurCRM?.valeur?.lastModifiedDate ? new Date(parametresUtilisateurCRM?.valeur?.lastModifiedDate) : null;
    const derniereDateSynchroParametresUtilisateur: Date | null = parametresUtilisateur?.valeur?.lastModifiedDate ? new Date(parametresUtilisateur?.valeur?.lastModifiedDate) : null;
    const derniereSynchroCommercialActif: Date | null = commercialActif?.valeur?.dateModif ? new Date(commercialActif?.valeur?.dateModif) : null;

    if (!dtoSynchro) {
      dtoSynchro = {
        PARAMETRES_CRM_UTILISATEUR: derniereDateSynchroParametresUtilisateurCRM ? derniereDateSynchroParametresUtilisateurCRM.toISOString() : null,
        PROFIL_UTILISATEUR: derniereDateSynchroParametresUtilisateurCRM ? derniereDateSynchroParametresUtilisateurCRM.toISOString() : null,
        COMMERCIAL_ACTIF: derniereSynchroCommercialActif ? derniereSynchroCommercialActif.toISOString() : null,
        CLIENTS: horodatageClients?.version ?? null,
        CONTACTS_CLIENTS: horodatageContactsClients?.version ?? null,
        FAMILLES_STAT_1: horodatageFamillesStat?.valeur ?? null,
        FAMILLES_STAT_2: horodatageFamillesStat?.valeur ?? null,
        FAMILLES_STAT_3: horodatageFamillesStat?.valeur ?? null,
        ARTICLES: horodatageArticles?.version ?? null,
        ARTICLE_MEDIAS: horodatageArticleMedias?.valeur ?? null,
        TARIFS: horodatageTarifs?.version ?? null,
        TARIFS_MARCHE: horodatageTarifsMarche?.version ?? null,
        TARIFS_SPECIAUX: horodatageTarifsSpeciaux?.version ?? null,
        REMISES: horodatageRemises?.version ?? null,
        REMISES_QUANTITE: horodatageRemisesQuantite?.version ?? null,
        RISTOURNES: horodatageRistournes?.version ?? null,
        ODRD: horodatageOdrd?.version ?? null,
      };

      if (this.appConfigService.modeCommercial) {
        dtoSynchro.EVENEMENTS = horodatageEvenements?.valeur ?? null;
      }
    }

    if (!dtoSynchro.PARAMETRES_CRM_UTILISATEUR) {
      try {
        dtoSynchro.PARAMETRES_CRM_UTILISATEUR = derniereDateSynchroParametresUtilisateurCRM ? derniereDateSynchroParametresUtilisateurCRM.toISOString() : null;
      } catch (error) {
        dtoSynchro.PARAMETRES_CRM_UTILISATEUR = null;
      }
    }

    if (!dtoSynchro.PROFIL_UTILISATEUR) {
      try {
        dtoSynchro.PROFIL_UTILISATEUR = derniereDateSynchroParametresUtilisateur ? derniereDateSynchroParametresUtilisateur.toISOString() : null;
      } catch (error) {
        dtoSynchro.PROFIL_UTILISATEUR = null;
      }
    }

    const reponse: HttpResponse<Synchronisation> = await lastValueFrom(this.http.postFromApi<Synchronisation>("business/crm/synchronisation", dtoSynchro));
    const donnees: Synchronisation = reponse.body ?? new Synchronisation();
    await this.enregistrerDonneesSynchro(donnees ?? new Synchronisation());
    majDerniereSynchro(this.dbService);
    this.synchronisationEnCours = false;
    return donnees
  }

  private async enregistrerDonneesSynchro(synchronisation: Partial<Synchronisation>): Promise<void> {
    this.etapeActuelleSynchro = 0;

    let enrichissementArticles = null;
    if (this.appConfigService.modeClientFinal) {
      enrichissementArticles = new EnrichissementArticles(this.dbService);
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Paramètres utilisateurs CRM";
    if (synchronisation.PARAMETRES_CRM_UTILISATEUR) {
      console.time('synchronisationParametresCrmUtilisateur');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM, valeur: synchronisation.PARAMETRES_CRM_UTILISATEUR.valeurs[0] as never });
      console.timeEnd('synchronisationParametresCrmUtilisateur');
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Paramètres utilisateurs";
    if (synchronisation.PROFIL_UTILISATEUR) {
      console.time('synchronisationParametresUtilisateur');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.PROFIL_UTILISATEUR, valeur: synchronisation.PROFIL_UTILISATEUR.valeurs[0] as never });
      console.timeEnd('synchronisationParametresUtilisateur');
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Commercial Actif";
    if(synchronisation.COMMERCIAL_ACTIF) {
      console.time('synchronisationCommercialActif');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.COMMERCIAL_ACTIF, valeur: synchronisation.COMMERCIAL_ACTIF.valeurs[0] as never });
      console.timeEnd('synchronisationCommercialActif');
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Clients";
    if (synchronisation.CLIENTS) {
      console.time('synchronisationClients');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_CLIENTS, valeur: synchronisation.CLIENTS.horodatage as never, version: synchronisation.CLIENTS.dernierNumeroVersion as never });
      await this.gererAjoutSuppression(synchronisation.CLIENTS.valeurs, 'statutOperationBdd', 'id', this.dbService.dexie.synchronisation_clients);
      console.timeEnd('synchronisationClients');
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Contacts clients";
    if (synchronisation.CONTACTS_CLIENTS) {
      console.time('synchronisationContactsClients');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_CONTACTS_CLIENTS, valeur: synchronisation.CONTACTS_CLIENTS.horodatage as never, version: synchronisation.CONTACTS_CLIENTS.dernierNumeroVersion as never });
      await this.gererAjoutSuppression(synchronisation.CONTACTS_CLIENTS.valeurs, 'statutOperationBdd', 'id', this.dbService.dexie.synchronisation_contacts_clients);
      console.timeEnd('synchronisationContactsClients');
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Évènements";
    if (synchronisation.EVENEMENTS) {
      console.time('synchronisationEvenements');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_EVENEMENTS, valeur: synchronisation.EVENEMENTS.horodatage as never });
      await this.gererAjoutSuppression(synchronisation.EVENEMENTS.valeurs, null, 'numeroEvenement', this.dbService.dexie.synchronisation_evenements);
      console.timeEnd('synchronisationEvenements');
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Familles statistiques 1";
    if (synchronisation.FAMILLES_STAT_1) {
      console.time('synchronisationFamillesStat1');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_FAMILLES_STAT, valeur: synchronisation.FAMILLES_STAT_1.horodatage as never });
      await this.gererAjoutSuppression(synchronisation.FAMILLES_STAT_1.valeurs, null, 'familleStatRef', this.dbService.dexie.synchronisation_familles_stat_1);
      console.timeEnd('synchronisationFamillesStat1');
      if (enrichissementArticles !== null) await enrichissementArticles.chargerFamillesStat1();
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Familles statistiques 2";
    if (synchronisation.FAMILLES_STAT_2) {
      // c'est FAMILLE_STAT_1 qui contient la date de dernière synchro
      console.time('synchronisationFamillesStat2');
      await this.gererAjoutSuppression(synchronisation.FAMILLES_STAT_2.valeurs, null, 'familleStatRef', this.dbService.dexie.synchronisation_familles_stat_2);
      console.timeEnd('synchronisationFamillesStat2');
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Familles statistiques 3";
    if (synchronisation.FAMILLES_STAT_3) {
      // c'est FAMILLE_STAT_1 qui contient la date de dernière synchro
      console.time('synchronisationFamillesStat3');
      await this.gererAjoutSuppression(synchronisation.FAMILLES_STAT_3.valeurs, null, 'familleStatRef', this.dbService.dexie.synchronisation_familles_stat_3);
      console.timeEnd('synchronisationFamillesStat3');
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Articles";
    if (synchronisation.ARTICLES) {
      console.time('synchronisationArticles');
      if (enrichissementArticles !== null) enrichissementArticles.traiterListeArticles(synchronisation.ARTICLES.valeurs);
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_ARTICLES, valeur: synchronisation.ARTICLES.horodatage as never, version: synchronisation.ARTICLES.dernierNumeroVersion as never });
      await this.gererAjoutSuppression(synchronisation.ARTICLES.valeurs, 'statutOperationBdd', 'id', this.dbService.dexie.synchronisation_articles);
      console.timeEnd('synchronisationArticles');
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Médias articles";
    if (synchronisation.ARTICLE_MEDIAS) {
      console.time('synchronisationArticleMedias');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_ARTICLE_MEDIAS, valeur: synchronisation.ARTICLE_MEDIAS.horodatage as never, version: synchronisation.ARTICLE_MEDIAS.dernierNumeroVersion as never });
      await this.gererAjoutSuppression(synchronisation.ARTICLE_MEDIAS.valeurs, null, 'id', this.dbService.dexie.synchronisation_article_medias);
      console.timeEnd('synchronisationArticleMedias');
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Tarifs";
    if (synchronisation.TARIFS) {
      console.time('synchronisationTarifs');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_TARIFS, valeur: synchronisation.TARIFS.horodatage as never, version: synchronisation.TARIFS.dernierNumeroVersion as never });
      await this.gererAjoutSuppression(synchronisation.TARIFS.valeurs, 'statutOperationBdd', 'id', this.dbService.dexie.synchronisation_tarifs);
      console.timeEnd('synchronisationTarifs');
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Tarifs marché";
    if (synchronisation.TARIFS_MARCHE) {
      console.time('synchronisationTarifsMarche');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_TARIFS_MARCHE, valeur: synchronisation.TARIFS_MARCHE.horodatage as never, version: synchronisation.TARIFS_MARCHE.dernierNumeroVersion as never });
      await this.gererAjoutSuppression(synchronisation.TARIFS_MARCHE.valeurs, 'statutOperationBdd', 'id', this.dbService.dexie.synchronisation_tarifs_marche);
      console.timeEnd('synchronisationTarifsMarche');
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Tarifs spéciaux";
    if (synchronisation.TARIFS_SPECIAUX) {
      console.time('synchronisationTarifsSpeciaux');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_TARIFS_SPECIAUX, valeur: synchronisation.TARIFS_SPECIAUX.horodatage as never, version: synchronisation.TARIFS_SPECIAUX.dernierNumeroVersion as never });
      await this.gererAjoutSuppression(synchronisation.TARIFS_SPECIAUX.valeurs, 'statutOperationBdd', 'id', this.dbService.dexie.synchronisation_tarifs_speciaux);
      console.timeEnd('synchronisationTarifsSpeciaux');
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Remises";
    if (synchronisation.REMISES) {
      console.time('synchronisationRemisesClient');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_REMISES, valeur: synchronisation.REMISES.horodatage as never, version: synchronisation.REMISES.dernierNumeroVersion as never });
      await this.gererAjoutSuppression(synchronisation.REMISES.valeurs, 'statutOperationBdd', 'id', this.dbService.dexie.synchronisation_remises);
      console.timeEnd('synchronisationRemisesClient');
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Remises quantité";
    if (synchronisation.REMISES_QUANTITE) {
      console.time('synchronisationRemisesQuantiteClient');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_REMISES_QUANTITE, valeur: synchronisation.REMISES_QUANTITE.horodatage as never, version: synchronisation.REMISES_QUANTITE.dernierNumeroVersion as never });
      await this.gererAjoutSuppression(synchronisation.REMISES_QUANTITE.valeurs, 'statutOperationBdd', 'id', this.dbService.dexie.synchronisation_remises_quantite);
      console.timeEnd('synchronisationRemisesQuantiteClient');
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Ristournes";
    if (synchronisation.RISTOURNES) {
      console.time('synchronisationRistournesClient');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_RISTOURNES, valeur: synchronisation.RISTOURNES.horodatage as never, version: synchronisation.RISTOURNES.dernierNumeroVersion as never });
      await this.gererAjoutSuppression(synchronisation.RISTOURNES.valeurs, 'statutOperationBdd', 'id', this.dbService.dexie.synchronisation_ristournes);
      console.timeEnd('synchronisationRistournesClient');
    }

    this.etapeActuelleSynchro++;
    this.etapeEnCours = "Offres de remboursement différées";
    if (synchronisation.ODRD) {
      console.time('synchronisationOdrdClient');
      await this.dbService.dexie.parametres_application.put({ id: ParametreApplicationId.DERNIERE_SYNCHRO_ODRD, valeur: synchronisation.ODRD.horodatage as never, version: synchronisation.ODRD.dernierNumeroVersion as never });
      await this.gererAjoutSuppression(synchronisation.ODRD.valeurs, 'statutOperationBdd', 'id', this.dbService.dexie.synchronisation_odrd);
      console.timeEnd('synchronisationOdrdClient');
    }

    if (this.appConfigService.modeClientFinal) await this.famillesArticleService.recompilerInfosArticles();
  }

  private async gererAjoutSuppression<T>(donneesTable: T[], cleSuppression: (keyof T) | null, cleIdentifiant: (keyof T) | null, table: Table<T, unknown>): Promise<void> {
    if (!cleSuppression) {
        await table.bulkPut(donneesTable);
        return;
    }

    const ajouts: T[] = [];
    const suppressions: T[] = [];
    for (const donnee of donneesTable) {

        if (typeof donnee[cleSuppression] === 'object') {
            ajouts.push(donnee);
        }

        if (typeof donnee[cleSuppression] === 'boolean') {
            if (donnee[cleSuppression]) {
                suppressions.push(donnee);
            } else {
                ajouts.push(donnee);
            }
        }

        if (typeof donnee[cleSuppression] === 'string') {
          if (donnee[cleSuppression] === 'S') {
            suppressions.push(donnee);
          }
          else {
            const dateDonnee = new Date(donnee[cleSuppression] as string);
            const dateMaintenant = new Date();

            if (dateDonnee.getTime() < dateMaintenant.getTime()) {
                suppressions.push(donnee);
            } else {
                ajouts.push(donnee);
            }
          }

        }
      }

      if(suppressions.length > 0) {
        await this.supprimerElementsTable(table, suppressions, cleIdentifiant);
      }
      await table.bulkPut(ajouts);
    }

private async supprimerElementsTable<T>(table: Table<T, unknown>, elements: T[], cleIdentifiant: (keyof T) | null): Promise<void> {
  if (table === this.dbService.dexie.synchronisation_contacts_clients && cleIdentifiant) {
      for (const element of elements) {
          const idSupprime = element[cleIdentifiant];
          if (idSupprime !== null && idSupprime !== undefined) {
            this.dbService.dexie.synchronisation_contacts_clients.filter((cont) => cont.id === idSupprime).delete();
          }
      }
  }
  else if (table === this.dbService.dexie.synchronisation_clients && cleIdentifiant) {
      for (const element of elements) {
          const idSupprime = element[cleIdentifiant];
          if (idSupprime !== null && idSupprime !== undefined) {
            this.dbService.dexie.synchronisation_clients.filter((cli) => cli.id === idSupprime).delete();
          }
      }
  }
  else if (table === this.dbService.dexie.synchronisation_tarifs && cleIdentifiant) {
      for (const element of elements) {
          const idSupprime = element[cleIdentifiant];
          if (idSupprime !== null && idSupprime !== undefined) {
            this.dbService.dexie.synchronisation_tarifs.filter((tarif) => tarif.id === idSupprime).delete();
          }
      }
  }
  else if (table === this.dbService.dexie.synchronisation_tarifs_speciaux && cleIdentifiant) {
      for (const element of elements) {
          const idSupprime = element[cleIdentifiant];
          if (idSupprime !== null && idSupprime !== undefined) {
            this.dbService.dexie.synchronisation_tarifs_speciaux.filter((tarif) => tarif.id === idSupprime).delete();
          }
      }
  }
  else if (table === this.dbService.dexie.synchronisation_libelles_tarifs && cleIdentifiant) {
      for (const element of elements) {
          const idSupprime = element[cleIdentifiant];
          if (idSupprime !== null && idSupprime !== undefined) {
            this.dbService.dexie.synchronisation_libelles_tarifs.filter((lib) => lib.id === idSupprime).delete();
          }
      }
  }
  else if (table === this.dbService.dexie.synchronisation_exceptions_tarifaires && cleIdentifiant) {
      for (const element of elements) {
          const idSupprime = element[cleIdentifiant];
          if (idSupprime !== null && idSupprime !== undefined) {
            this.dbService.dexie.synchronisation_exceptions_tarifaires.filter((exception) => exception.id === idSupprime).delete();
          }
      }
  }
  else if (table === this.dbService.dexie.synchronisation_remises && cleIdentifiant) {
      for (const element of elements) {
          const idSupprime = element[cleIdentifiant];
          if (idSupprime !== null && idSupprime !== undefined) {
            this.dbService.dexie.synchronisation_remises.filter((remise) => remise.id === idSupprime).delete();
          }
      }
  }
  else if (table === this.dbService.dexie.synchronisation_remises_quantite && cleIdentifiant) {
      for (const element of elements) {
          const idSupprime = element[cleIdentifiant];
          if (idSupprime !== null && idSupprime !== undefined) {
            this.dbService.dexie.synchronisation_remises_quantite.filter((remiseQuantite) => remiseQuantite.id === idSupprime).delete();
          }
      }
  }
  else if (table === this.dbService.dexie.synchronisation_ristournes && cleIdentifiant) {
      for (const element of elements) {
          const idSupprime = element[cleIdentifiant];
          if (idSupprime !== null && idSupprime !== undefined) {
            this.dbService.dexie.synchronisation_ristournes.filter((ristourne) => ristourne.id === idSupprime).delete();
          }
      }
  }
  else if (table === this.dbService.dexie.synchronisation_odrd && cleIdentifiant) {
      for (const element of elements) {
          const idSupprime = element[cleIdentifiant];
          if (idSupprime !== null && idSupprime !== undefined) {
            this.dbService.dexie.synchronisation_odrd.filter((ristourne) => ristourne.id === idSupprime).delete();
          }
      }
  }
  else if (table === this.dbService.dexie.synchronisation_articles && cleIdentifiant) {
      for (const element of elements) {
          const idSupprime = element[cleIdentifiant];
          if (idSupprime !== null && idSupprime !== undefined) {
            this.dbService.dexie.synchronisation_articles.filter((article) => article.id === idSupprime).delete();
          }
      }
  }
}

}
