<header class="entete bg-primary m-0 pb-0 d-flex justify-content-between gap-2">
  <div class="d-flex gap-2 entete__conteneurlogo">
    <div *appCommercial>
      <div class="entete__img">
        <img src="./assets/logo/logotexte.png" alt="Kalico CRM" class="entete__logo cliquable" routerLink="/" />
      </div>
    </div>
    <div *appClientFinal class="entete__img" (click)="retourAccueil()">
      <img src="./assets/maboutique/logo.png" class="entete__logo" style="max-height: 80px" />
    </div>

    <div class="d-flex flex-column gap-2 visible-desktop justify-content-center">
      <div class="d-flex gap-2 align-items-center">
        <button class="btn btn-menu btn-sm" (click)="retourArriere()" title="Retour arrière">
          <span class="fa fa-fw fa-arrow-left" role="button"></span>
        </button>
        <button class="btn btn-menu btn-sm" (click)="retourAvant()" title="Retour avant">
          <span class="fa fa-fw fa-arrow-right"></span>
        </button>
      </div>
      <div class="d-flex gap-2 align-items-center">
        <button class="btn btn-info btn-sm flex-grow-1" [disabled]="
            synchronisationService.synchronisationEnCours ||
            chargement ||
            synchronisationService.synchronisationTotaleEnCours
          " (click)="synchroniserTout()" data-toggle="tooltip" data-placement="bottom"
          title="Mettre à jour les données">
          <span class="fa fa-fw fa-spinner fa-spin"
            *ngIf="synchronisationService.synchronisationEnCours || synchronisationService.synchronisationTotaleEnCours"></span>
          <span class="fa fa-fw fa-refresh" *ngIf="
              !synchronisationService.synchronisationEnCours && !synchronisationService.synchronisationTotaleEnCours
            "></span>
        </button>
      </div>
    </div>
  </div>

  <div *appCommercial class="d-flex gap-2">
    <div class="d-flex flex-column gap-2 visible-desktop justify-content-center">
      <div class="d-flex align-items-center input-group" title="Montants dus">
        <button class="btn btn-light btn-sm" (click)="afficherMontant = !afficherMontant" data-toggle="tooltip"
          data-placement="top" title="Montants dûs">
          <span class="fa fa-fw fa-euro"></span>
        </button>
        <input class="input-group-text h-100" *ngIf="afficherMontant" type="text" [value]="obtenirMontant()"
          [disabled]="true" style="background-color: white" size="10" />
      </div>
      <button class="btn btn-info btn-sm" [disabled]="assistanceEnCours" (click)="ouvrirAssistance()"
        data-toggle="tooltip" data-placement="top" title="Assistance">
        <span class="fa fa-fw fa-spinner fa-spin" *ngIf="assistanceEnCours"></span>
        <span class="fa fa-fw fa-headset" *ngIf="!assistanceEnCours"></span>
      </button>
    </div>
    <div class="d-flex flex-column gap-2 visible-desktop justify-content-center">
      <button class="btn btn-light btn-sm" (click)="changerAffichageTarifs()" data-toggle="tooltip" data-placement="top"
        [title]="!articleService.afficherTarifDansListe ? 'Afficher (F2)' : 'Masquer (F2)'">
        <span class="fa fa-fw fa-eye" *ngIf="articleService.afficherTarifDansListe"></span>
        <span class="fa fa-fw fa-eye-slash" *ngIf="!articleService.afficherTarifDansListe"></span>
      </button>
      <button class="btn btn-info btn-sm" (click)="ouvrirAide()" data-toggle="tooltip" data-placement="top"
        title="Aide (F1)">
        <span class="fa fa-fw fa-question"></span>
      </button>
    </div>
    <div class="d-flex flex-column gap-2 visible-desktop justify-content-center">
      <button class="btn btn-light btn-sm" (click)="ouvrirAnniversaires()" data-toggle="tooltip" data-placement="top" title="Anniversaires">
        <span class="fa fa-fw fa-cake-candles"></span>
      </button>
    </div>
  </div>

  <div *appCommercial class="d-flex flex-column gap-2 taille-environnement me-2">
    <div class="input-group">
      <div class="input-group-text" title="Dépôt">
        <span class="fa fa-fw fa-warehouse"></span>
      </div>
      <select [ngModel]="depotActif" class="form-select form-select-sm cliquable"
        (ngModelChange)="mettreAJourDepotEnvironnement($event)">
        <option [value]="depot.depotRef" *ngFor="let depot of depotsDisponibles">({{depot.depotRef}}) {{depot.depotLibelle}}</option>
      </select>
    </div>
    <div class="input-group">
      <div class="input-group-text" title="Commercial">
        <span class="fa fa-fw fa-user"></span>
      </div>
      <select [ngModel]="commercialActif" class="form-select form-select-sm cliquable"
        (ngModelChange)="mettreAJourCommercialEnvironnement($event)">
        <option value="">TOUS</option>
        <option [value]="commercial.commercialRef" *ngFor="let commercial of commerciauxDisponibles">({{commercial.commercialRef}}) {{commercial.commercialNom}}</option>
      </select>
    </div>
  </div>

  <div *appClientFinal class="d-flex flex-row align-items-center gap-2 taille-environnement-extra me-2 visible-desktop">
    <button class="btn btn-link p-0">
      <img src="./assets/stores-marketing/appstore.svg" alt="Télécharger dans l'App Store"
        class="entete__logo_store cliquable" />
    </button>
    <button class="btn btn-link p-0">
      <img src="./assets/stores-marketing/googleplay.png" alt="Disponible sur Google Play"
        class="entete__logo_store cliquable" />
    </button>
    <div class="input-group">
      <div class="input-group-text" title="Commercial">
        <span class="fa fa-fw fa-user"></span>
      </div>
      <!-- <input
        *ngIf="clientsDisponibles.length <= 1"
        class="form-control form-control-sm"
        type="text"
        disabled
        [value]="clientsDisponibles.length > 0 ? clientsDisponibles[0].enseigne + ' (' + clientsDisponibles[0].nomClient + ')' : ''"
      /> -->
      <!-- *ngIf="clientsDisponibles.length > 1" -->
      <select [ngModel]="clientActif" class="form-select form-select-sm cliquable"
        (ngModelChange)="mettreAJourClientEnvironnement($event)">
        <option [value]="client.tiers" *ngFor="let client of clientsDisponibles">
          {{ client.enseigne }} ({{ client.nomClient }})
        </option>
      </select>
    </div>
  </div>
</header>
