import { Injectable } from '@angular/core';
import { Identifiants } from '../login/dto/identifiants';
import { HttpEvent, HttpRequest, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Token } from '../login/dto/token';
import { ParametreApplicationId } from '../db/parametre-application';
import { DbService } from '../db/db.service';
import { EnvironnementApplicationService } from '../environnement-application/environnement-application.service';
import { FamillesArticleService } from '../articles/familles-article.service';

@Injectable({
  providedIn: 'root',
})
export class DemoService {
  public constructor(
    private dbService: DbService,
    private environnement: EnvironnementApplicationService,
    private famillesArticleService: FamillesArticleService
  ) {}

  public async connexionCompteDemo(identifiants: Identifiants): Promise<boolean> {
    if (identifiants.username === 'demo@kalico-informatique.fr' && identifiants.password === 'Kalico1703') {
      await this.initDonnees();
      return true;
    } else {
      return false;
    }
  }

  public getToken(): HttpResponse<Token> {
    return new HttpResponse({
      body: {
        id_token:
          'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkZW1vQGthbGljby1pbmZvcm1hdGlxdWUuZnIiLCJhdXRoI' +
          'joiUk9MRV9DUk1fQ0xJRU5UX0ZJTkFMIiwiZXhwIjoxOTk5OTk5OTk5fQ.EMCYMs-KG31TiTc52F0U' +
          'PbiyPHujTlM4uiza4lb4WC_5nc_554zs2G1lZ-iqb1tGvVdccCKAiZgdnklMnJCkQw',
      },
    });
  }

  public async initDonnees(): Promise<void> {
    window.localStorage.setItem('mode-demo', 'true');

    if (!this.environnement.getParametre(ParametreApplicationId.MODE_DEMO)) {
      const importData: Record<string, object[]> = (await import('./donneesDemo.json')).default;
      for (const table of this.dbService.dexie.tables) {
        await table.clear();
        await table.bulkAdd(importData[table.name] ?? []);
      }
      await this.famillesArticleService.recompilerInfosArticles();
    }
  }

  public traiterRequeteHttp(requete: HttpRequest<unknown>): HttpEvent<unknown> {
    return new HttpResponse({
      url: requete.url,
      status: HttpStatusCode.RequestTimeout,
      statusText: 'Désactivé par le mode démo',
    });
  }
}
