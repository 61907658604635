import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ChampMotDePasseService } from 'src/app/services/utilitaire/champ-motdepasse.service';

@Component({
  selector: 'app-champ-motdepasse',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './champ-motdepasse.component.html',
  styleUrls: ['./champ-motdepasse.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ChampMotDePasseComponent,
    },
  ],
})
export class ChampMotDePasseComponent implements ControlValueAccessor {
  @Input() idChamp: string | null = null;
  @Input() nomChamp: string | null = null;
  @Input() autocompletion: string | null = null;
  @Input() isInvalid: boolean = false;
  @Input() placeholder: string = '';

  @Input() set disabled(value: boolean) {
    this.setDisabledState(value);
  }
  public get disabled(): boolean {
    return this._disabled;
  }

  public motDePasse: string = '';

  @ViewChild("boutonVisibilite") boutonVisibilite?: ElementRef<HTMLButtonElement>;

  public onChange: (a: string) => void = () => {
    /* ne rien faire par défaut */
  };
  public onTouched: () => void = () => {
    /* ne rien faire par défaut */
  };
  private _touched: boolean = false;
  private _disabled: boolean = false;
  private _focused: boolean = false;

  public survolBouton: boolean = false;

  public constructor(private service: ChampMotDePasseService, private controlContainer: ControlContainer) {}

  public saisie(value: string) {
    this.markAsTouched();
    if (!this.disabled) {
      this.motDePasse = value;
      this.onChange(this.motDePasse);
    }
  }

  public writeValue(motDePasse: string): void {
    this.motDePasse = motDePasse;
  }

  public registerOnChange(fn: (a: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public markAsTouched() {
    if (!this._touched) {
      this.onTouched();
      this._touched = true;
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  public get estVisible(): boolean {
    return this._focused && this.service.motDePasseVisible;
  }

  public basculerVisible(): void {
    this.service.basculer();
  }

  public focus(): void {
    this._focused = true;
  } 

  public blur(): void {
    this._focused = false;
  }

  public get focused(): boolean {
    return this._focused;
  }
  
  public mousemove(ev: MouseEvent) {
    const bouton = this.boutonVisibilite?.nativeElement;
    if (bouton && this._focused && this.motDePasse) {
      this.survolBouton = ev.offsetX >= bouton.offsetLeft;
    }
  }
  
  public click(ev: MouseEvent) {
    const bouton = this.boutonVisibilite?.nativeElement;
    if (bouton && this._focused && this.motDePasse) {
      if (ev.offsetX >= bouton.offsetLeft) {
        this.basculerVisible();
      }
    }
  }
}
