import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule, isDevMode } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './services/auth/auth.service';
import { LoginService } from './services/login/login.service';
import { CarrouselEvenementsComponent } from './modules/fragments/carrousel-evenements/carrousel-evenements.component';
import { ToastComponent } from './services/toast/toast.component';
import { MenuComponent } from './modules/general/menu/menu.component';
import { EnteteComponent } from './modules/general/entete/entete.component';
import { AccesApiInterceptor } from './interceptors/acces-api/acces-api.interceptor';
import { AuthInterceptor } from './interceptors/auth/auth.interceptor';
import * as fr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { LoggerService } from './services/log/logger.service';
import { LoggerInterceptor } from './interceptors/logger/logger.interceptor';
import { QuillModule } from 'ngx-quill';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AppConfigService } from './services/configuration/app-config.service';
import { toucheClavierDirective } from './tools/directives/touches-clavier/touches-clavier.directive';
import { EnvironnementApplicationService } from './services/environnement-application/environnement-application.service';
import { DemoInterceptor } from './interceptors/demo/demo.interceptor';
import { DemoService } from './services/demo/demo.service';


export function initConfig(appConfig: AppConfigService) {
  return () => appConfig.loadConfigXhr();
}
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastComponent,
    toucheClavierDirective,
    QuillModule.forRoot(),

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BrowserAnimationsModule,
    NgbModule,
    CarrouselEvenementsComponent,
    MenuComponent,
    EnteteComponent,
    CalendarModule.forRoot(
      {
        provide: DateAdapter,
        useFactory: adapterFactory
      })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccesApiInterceptor,
      multi: true,
      deps: [
        EnvironnementApplicationService,
        LoggerService,
        AppConfigService,
      ]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [
        AuthService,
        LoginService,
        LoggerService,
        AppConfigService,
      ]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggerInterceptor,
      multi: true,
      deps: [
        LoggerService,
      ]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DemoInterceptor,
      multi: true,
      deps: [
        DemoService,
      ]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfigService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(fr.default)
  }
}
