<div class="modal-header text-light" [ngClass]="{'bg-primary': !boutonDanger, 'bg-danger': boutonDanger}" *ngIf="titre">
  <h4 class="modal-title">{{titre}}</h4>
</div>
<div class="modal-body" *ngIf="corps">
    {{corps}}
</div>
<div class="modal-footer">

    <button *ngIf="afficherNon" [classList]="classeBoutonNon + ' d-flex align-items-center gap-1'"
        (click)="fermerNon()" (keydown.enter)="$event.preventDefault()" (keyup.enter)="fermerNon()">
        <span *ngIf="texteNon">{{texteNon}}</span>
        <span *ngIf="iconeBoutonNon" [ngClass]="iconeBoutonNon"></span>
    </button>

    <button *ngIf="afficherOui" [classList]="classeBoutonOui + ' d-flex align-items-center gap-1'"
        (click)="fermerOui()" (keydown.enter)="$event.preventDefault()" (keyup.enter)="fermerOui()">
        <span *ngIf="texteOui">{{texteOui}}</span>
        <span *ngIf="decompteConfirmation">({{decompteConfirmation}})</span>
        <span *ngIf="iconeBoutonOui" [ngClass]="iconeBoutonOui"></span>
    </button>

    <button *ngFor="let sup of boutonsSupplementaires" [ngClass]="sup.classe + ' d-flex align-items-center gap-2 text-white'"
        (click)="sup.action(activeModal)" (keydown.enter)="$event.preventDefault()" (keyup.enter)="sup.action(activeModal)">
        <span *ngIf="sup.texte">{{sup.texte}}</span>
        <span *ngIf="sup.icone" [classList]="sup.icone"></span>
    </button>

</div>
