import type { ConfigPlateforme, Plateforme } from '.';
import { LoggerService } from '../log/logger.service';
import { Identifiants } from '../login/dto/identifiants';

export class PlateformeWeb implements Plateforme {
  public constructor(private loggerService: LoggerService) {}

  public readonly codePlateforme = 1;
  public readonly nom = 'web';
  public readonly estNative = false;

  public async configurer(): Promise<ConfigPlateforme> {
    // rien à configurer pour le web
    return {};
  }

  public async enregistrerEtOuvrir(nom: string, _: string | undefined, blob: Blob): Promise<void> {
    const url: string = window.URL.createObjectURL(blob);
    this.declencherEnregistrement(url, nom);
    window.open(url, '_blank');
  }

  private declencherEnregistrement(url: string, nom: string) {
    const link: HTMLAnchorElement = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', nom);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public async enregistrerSeulement(nom: string, _: string | undefined, blob: Blob): Promise<void> {
    const url: string = window.URL.createObjectURL(blob);
    this.declencherEnregistrement(url, nom);
  }

  public async verifierPermissionsNotifications(): Promise<boolean> {
    return false;
  }

  public async inviterAEnregistrerMotDePasse(): Promise<void> { }
}
