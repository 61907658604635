import { Injectable } from '@angular/core';
import { DbService } from '../db/db.service';
import { HttpService } from '../http/http.service';
import { SynchronisationPlanning } from '../synchronisation-quotidienne/synchronisation-quotidienne';
import { ParametresUtilisateurCRM } from '../crm/crm';
import { ParametreApplication, ParametreApplicationId } from '../db/parametre-application';
import { SynchronisationPlanningAppDTO, SynchronisationPlanningServeurDTO } from './planning';
import { firstValueFrom, Subject } from 'rxjs';
import { SynchronisationQuotidienneService } from '../synchronisation-quotidienne/synchronisation-quotidienne.service';
import { SynchronisationService } from '../synchronisation/synchronisation.service';
import { dateVersTexteISOAvecGMTAmeliore } from 'src/app/tools/Fonctions/fonctions';
import { PanierEnteteEtendu } from '../crm/crm-panier/crm-panier';
import { isSameDay } from 'date-fns';
import { UtilitaireGeneralService } from '../utilitaire/utilitaire-general.service';
import { EnvironnementApplicationService } from '../environnement-application/environnement-application.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PlanningService {
  public rechargementListe = new Subject<void>();
  public utilisateurConnecte: boolean = false;

  constructor(
    private dbService: DbService,
    private authService: AuthService,
    private httpService: HttpService,
    private environnement: EnvironnementApplicationService,
    private synchronisationService: SynchronisationService,
    private utilitaireGeneralService: UtilitaireGeneralService,
    private synchronisationQuotidienneService: SynchronisationQuotidienneService,
  ) { }

  private async recupererInfosCRM(): Promise<ParametresUtilisateurCRM | undefined> {
    const parametresCrm = await this.dbService.dexie.parametres_application.get(ParametreApplicationId.PARAMETRES_UTILISATEUR_CRM) as ParametreApplication<ParametresUtilisateurCRM> | undefined;
    return parametresCrm?.valeur;
  }

  public async enregistrerPlanning(planning: SynchronisationPlanning): Promise<void> {
    const paramsCrm: ParametresUtilisateurCRM | undefined = await this.recupererInfosCRM();
    if (!paramsCrm) return;
    planning.modifier = true;
    planning.utilisateurModification = paramsCrm.codeUtilisateur;
    planning.dateModification = dateVersTexteISOAvecGMTAmeliore(new Date()) + "Z";
    await this.dbService.dexie.synchronisation_planning.put(planning);
    this.synchroniserErp();
  }

  private async synchroniserErp(): Promise<void> {
    if (this.synchronisationService.synchronisationEnCours) return;
    this.synchronisationService.synchronisationEnCours = true;

    const listeSynchro = (await this.dbService.dexie.synchronisation_planning.toArray()).filter((e) => e.modifier);
    if (!listeSynchro || listeSynchro.length == 0) {
      this.synchronisationService.synchronisationEnCours = false;
      return;
    }
    const utilisateurConnecte = await firstValueFrom(
      this.environnement.getParametreReactif(ParametreApplicationId.UTILISATEUR_CONNECTE)
    );
    this.utilisateurConnecte = utilisateurConnecte ?? false;
    const token = this.authService.recupererToken();
    const estConnecte = navigator.onLine;

    if(this.utilisateurConnecte && token && estConnecte) {
      const dto = new SynchronisationPlanningAppDTO(listeSynchro);

      this.httpService.postFromApi<SynchronisationPlanningServeurDTO>(("business/crm/planning"), dto)
        .subscribe({
          next: async () => {
            await this.synchronisationQuotidienneService.synchroniser({
              PLANNING: "",
            });
            this.synchronisationService.synchronisationEnCours = false;
            this.rechargementListe.next();
          },
          error: () => {
            this.synchronisationService.synchronisationEnCours = false;
          }
        });
    }
    else {
      this.synchronisationService.synchronisationEnCours = false;
    }
  }

  public async marquerPlanningTermine(panier: PanierEnteteEtendu): Promise<void> {
    const recurrent = await this.dbService.dexie.synchronisation_planning
      .filter((el) => {
        const dateEffective = new Date(el.dateEffective);
        const aujourdHui = new Date();
        return isSameDay(dateEffective, aujourdHui) && el.clientRef === panier?.client?.tiers;
      })
      .first();

    if (recurrent) {
      if (panier) {
        recurrent.statutEvenement = "3";
        recurrent.heureEvenement = panier.dateModif ? this.utilitaireGeneralService.formatHeure(new Date(panier.dateModif)) : "000000";
        recurrent.dateEffective = panier.dateModif ? this.utilitaireGeneralService.formatDate(new Date(panier.dateModif)) : this.utilitaireGeneralService.formatDate(new Date(panier.dateCreation));
        this.enregistrerPlanning(recurrent);
      }
    }
  }

  public async marquerPlanningEnCours(tiers: string): Promise<void> {
    const recurrent = await this.dbService.dexie.synchronisation_planning
      .filter((el) => {
        const dateEffective = new Date(el.dateEffective);
        const aujourdHui = new Date();
        return isSameDay(dateEffective, aujourdHui) && el.clientRef === tiers && el.statutEvenement !== "3" && el.statutEvenement !== "5";
      })
      .first();

    if (recurrent) {
      recurrent.statutEvenement = "5";
      recurrent.heureEvenement = this.utilitaireGeneralService.formatHeure(new Date());
      recurrent.dateEffective = this.utilitaireGeneralService.formatDate(new Date());
      this.enregistrerPlanning(recurrent);
    }
  }
}
