import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChampMotDePasseService {
  private _motDePasseVisible: boolean = false;

  public get motDePasseVisible(): boolean { return this._motDePasseVisible; }

  public masquer(): void {
    this._motDePasseVisible = false;
  }

  public basculer(): void {
    this._motDePasseVisible = !this._motDePasseVisible;
  }
}
