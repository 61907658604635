import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { DemoService } from 'src/app/services/demo/demo.service';

@Injectable()
export class DemoInterceptor implements HttpInterceptor {
  private modeDemo: boolean = false;
  
  constructor(private demoService: DemoService) {
    this.modeDemo = window.localStorage.getItem('mode-demo') === 'true';
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.modeDemo) {
      return of(this.demoService.traiterRequeteHttp(request));
    } else {
      return next.handle(request);
    }
  }
}
