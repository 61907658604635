import type { ConfigPlateforme, Plateforme } from '.';
import { LoggerService } from '../log/logger.service';
import { Identifiants } from '../login/dto/identifiants';
import { ouvrirFichierAvecType, recupererFichier } from './fichiers';
import { configurerCanauxDeNotification, demanderPermissionsEnvoiNotifications } from './notificationsPush';

export class PlateformeAndroid implements Plateforme {
  public constructor(private loggerService: LoggerService) {}
  
  public readonly codePlateforme = 2;
  public readonly nom = 'Android';
  public readonly estNative = true;

  public async configurer(): Promise<ConfigPlateforme> {
    this.loggerService.verbose('Configuration des notifications...');
    await configurerCanauxDeNotification();
    return {}
  }

  public async enregistrerEtOuvrir(nom: string, type: string | undefined, blob: Blob): Promise<void> {
    const chemin = await recupererFichier(nom, blob);
    await ouvrirFichierAvecType(chemin, type);
  }

  public async enregistrerSeulement(nom: string, type: string | undefined, blob: Blob): Promise<void> {
    const chemin = await recupererFichier(nom, blob);
    // on ouvre quand même pour que l'utilisateur y ait accès
    await ouvrirFichierAvecType(chemin, type);
  }

  public async verifierPermissionsNotifications(): Promise<boolean> {
    this.loggerService.verbose("Demande des permissions pour les notifications (Android 13+ seulement)")
    try {
      demanderPermissionsEnvoiNotifications();
      return true;
    } catch (e) {
      this.loggerService.error(String(e));
      return false;
    }
  } 

  public async inviterAEnregistrerMotDePasse(): Promise<void> { }
}
