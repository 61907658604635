import { Injectable } from '@angular/core';
import { etendreEnsemble, etendreFourchette, InfosArticlesFamille } from './infos-articles-famille';
import { SynchronisationArticle, SynchronisationFamilleStat } from '../synchronisation/synchronisation';
import { LoggerService } from '../log/logger.service';
import { DbService } from '../db/db.service';
import { formatISO } from 'date-fns';

export interface FamilleStatEtendue extends SynchronisationFamilleStat {
  infosArticles?: InfosArticlesFamille;
}

@Injectable({
  providedIn: 'root',
})
export class FamillesArticleService {
  public derniereSousFamilleRef: string | undefined;

  public constructor(private log: LoggerService, private dbService: DbService) {}

  public async recompilerInfosArticles(): Promise<void> {
    console.time('Compilation infos articles');
    const articles = await this.dbService.dexie.synchronisation_articles.toArray();
    console.timeLog('Compilation infos articles');

    const infosGroupees = new Map<string, InfosArticlesFamille>();
    const aujourdhui = formatISO(new Date(), { representation: 'date' });

    for (const article of articles) {
      const estDisponible = FamillesArticleService.statutMaBoutiqueAutoriseArticle(article);
      const tableauValidite = article.listeDatesFinValiditesDepots
        .split(';')
        .map<[number, boolean]>((date, idx) => [idx + 1, !date || date > aujourdhui]);

      for (const [indice, estValide] of tableauValidite) {
        const validite = estDisponible && estValide;
        this.agregerInfosDansFamille(infosGroupees, article, '', indice, 0, validite);
        if (article.familleStat1N1Ref) {
          this.agregerInfosDansFamille(infosGroupees, article, article.familleStat1N1Ref, indice, 1, validite);
        }
        if (article.familleStat1N2Ref) {
          this.agregerInfosDansFamille(infosGroupees, article, article.familleStat1N2Ref, indice, 2, validite);
        }
      }
    }

    await this.dbService.dexie.transaction('rw', 'infos_articles_familles', async () => {
      await this.dbService.dexie.infos_articles_familles.clear();
      await this.dbService.dexie.infos_articles_familles.bulkPut(Array.from(infosGroupees.values()));
    });
    console.timeEnd('Compilation infos articles');
  }

  private agregerInfosDansFamille(
    infosGroupees: Map<string, InfosArticlesFamille>,
    article: SynchronisationArticle,
    familleRef: string,
    indice: number,
    niveau: number,
    validite: boolean
  ): void {
    const cle = `${familleRef},${indice}`;
    const entreeExistante = infosGroupees.get(cle);
    if (entreeExistante) {
      if (validite) {
        entreeExistante.articlesDisponibles = true;
        entreeExistante.contenance = etendreFourchette(entreeExistante.contenance, article.contenance);
        entreeExistante.volumeAlcool = etendreFourchette(entreeExistante.volumeAlcool, article.volumeAlcool);
        entreeExistante.couleur = etendreEnsemble(entreeExistante.couleur, article.codeCouleur);
        entreeExistante.millesime = etendreEnsemble(entreeExistante.millesime, article.millesime);
        entreeExistante.typeTeteFut = etendreEnsemble(entreeExistante.typeTeteFut, article.typeTeteFut);
      }
    } else {
      const infosOptionelles: Partial<InfosArticlesFamille> = {};
      if (validite) {
        infosOptionelles.contenance = etendreFourchette(undefined, article.contenance);
        infosOptionelles.volumeAlcool = etendreFourchette(undefined, article.volumeAlcool);
        infosOptionelles.couleur = etendreEnsemble(undefined, article.codeCouleur);
        infosOptionelles.millesime = etendreEnsemble(undefined, article.millesime);
        infosOptionelles.typeTeteFut = etendreEnsemble(undefined, article.typeTeteFut);
      }
      infosGroupees.set(cle, {
        familleRef,
        niveau,
        indiceValiditeDepot: indice,
        articlesDisponibles: validite,
        ...infosOptionelles,
      });
    }
  }

  public async recupererListeFamilles(niveau: number): Promise<FamilleStatEtendue[]> {
    const infosArticlesFamilles = await this.dbService.dexie.infos_articles_familles
      .where('niveau')
      .equals(niveau)
      .toArray();
    const famillesStat1 = await this.dbService.dexie.synchronisation_familles_stat_1.toArray();

    const resultat = [];
    for (const fam of famillesStat1) {
      if (FamillesArticleService.statutMaBoutiqueAutorise(fam)) {
        const infosArticles = infosArticlesFamilles.find((ia) => fam.familleStatRef === ia.familleRef);
        if (infosArticles && infosArticles.articlesDisponibles) resultat.push({ ...fam, infosArticles });
      }
    }
    return resultat;
  }

  public static statutMaBoutiqueAutorise(fam: SynchronisationFamilleStat | undefined): boolean {
    return (
      fam === undefined ||
      fam.statutMaBoutique === undefined ||
      fam.statutMaBoutique.trim().length === 0 ||
      fam.statutMaBoutique === 'V'
    );
  }

  public static statutMaBoutiqueAutoriseArticle(art: SynchronisationArticle | undefined): boolean {
    return (
      art === undefined ||
      art.statutMaBoutique === undefined ||
      art.statutMaBoutique.trim().length === 0 ||
      art.statutMaBoutique === 'V'
    );
  }
}
