import type { SchemaBDD } from "./db";

export default (db: SchemaBDD) => {

db.version(99).stores({
    parametres_application: 'id',
    synchronisation_clients: 'tiers, nomClient, codePostal, ville, rue, titreRef, nomAbrege',
    synchronisation_contacts_clients: '[tiers+contact], email, tiers',
    synchronisation_evenements: 'numeroEvenement, dateDebut, tiersRef',
    synchronisation_familles_stat_1: 'familleStatRef',
    synchronisation_familles_stat_2: 'familleStatRef',
    synchronisation_familles_stat_3: 'familleStatRef',
    synchronisation_articles: 'id, articleRef, designation, dateCreation, fournisseurHabituelRef, familleStat1Ref, classeRemise',
    synchronisation_article_medias: 'id, articleRef',
    synchronisation_adresses: 'id, codeAdresse, tiers',
    synchronisation_unites: 'uniteVente',
    synchronisation_encours_vente: 'clientRef',
    synchronisation_encours_facture: 'clientRef',
    synchronisation_indicateurs_stat: '++idIndexedDB, client',
    synchronisation_indicateurs_stat_progression: '++idIndexedDB, client',
    synchronisation_stock_articles: '[depotRef+articleRef], articleRef, depotRef',
    synchronisation_info_depots: 'depotRef',
    synchronisation_info_depots_visu: 'depotRef',
    synchronisation_info_commerciaux: 'commercialRef',
    synchronisation_ficjoint_articles: '++idIndexedDB, reference',
    synchronisation_ficjoint_clients: 'id, reference',
    synchronisation_stat_simple: 'clientRef',
    synchronisation_tarifs: 'id, articleRef, [codeTarif+articleRef]',
    synchronisation_tarifs_speciaux: 'id, clientRef, articleRef, [clientRef+articleRef]',
    synchronisation_exceptions_tarifaires: 'id, codeTarif, tiers, famille, [famille+tiers]',
    synchronisation_remises: 'id, codeTarif, tiers, famille',
    synchronisation_remises_quantite: 'id, codeTarif, tiers, famille',
    synchronisation_ristournes: 'id, codeTarif, tiers, famille',
    synchronisation_odrd: 'id, codeTarif, tiers, famille',
    synchronisation_libelles_tarifs: 'id, codeTarif',
    synchronisation_referencement: 'clientRef',
    synchronisation_planning: 'planningId',
    synchronisation_tournee: 'tourneeId, numero',
    synchronisation_motifs_gratuite: 'motifId, motif, libelle, motifType',
    synchronisation_historique_article: '[tiers+articleRef]',
    synchronisation_frais_supplementaires: 'codeFraisFacturation',
    synchronisation_classe_remise: 'id, classeRef',
    synchronisation_deconsignes_attente: 'tiers',
    synchronisation_mise_en_avant: null,
    favoris_contacts_clients: '[tiers+contact], tiers',
    favoris_clients: 'tiers',
    favoris_articles: 'articleRef',
    paniers_entetes: '++idIndexedDB, [commercialRef+clientRef+dateCreation+utilisateurCreation], clientRef, commercialRef, id',
    paniers_lignes: '++idIndexedDB, [panierIdIndexedDB+articleRef+uniteVente], [panierId+articleRef+uniteVente+dateCreation+utilisateurCreation], id, panierId, panierIdIndexedDB',
    paniers_commentaires: '++idIndexedDB, [panierId+panierLigneId+dateCreation+utilisateurCreation], [panierId+dateCreation+utilisateurCreation], panierId, panierLigneId, panierIdIndexedDB, panierLigneIdIndexedDB',
    paniers_notes: '++idIndexedDB, [panierId+panierLigneId+dateCreation+utilisateurCreation], [panierId+dateCreation+utilisateurCreation], panierId, panierLigneId, panierIdIndexedDB, panierLigneIdIndexedDB',
    evenements: '++idIndexedDB, id, tiersRef',
    contacts: '++idIndexedDB, id, tiers',
});

db.version(104).stores({
    parametres_application: 'id',
    synchronisation_clients: 'tiers, nomClient, codePostal, ville, rue, titreRef, nomAbrege',
    synchronisation_contacts_clients: '[tiers+contact], email, tiers',
    synchronisation_evenements: 'numeroEvenement, dateDebut, tiersRef',
    synchronisation_familles_stat_1: 'familleStatRef',
    synchronisation_familles_stat_2: 'familleStatRef',
    synchronisation_familles_stat_3: 'familleStatRef',
    synchronisation_articles: 'id, articleRef, designation, dateCreation, fournisseurHabituelRef, familleStat1Ref, classeRemise',
    synchronisation_article_medias: 'id, articleRef',
    synchronisation_adresses: 'id, codeAdresse, tiers',
    synchronisation_unites: 'uniteVente',
    synchronisation_encours_vente: 'clientRef',
    synchronisation_encours_facture: 'clientRef',
    synchronisation_indicateurs_stat: '++idIndexedDB, client',
    synchronisation_indicateurs_stat_progression: '++idIndexedDB, client',
    synchronisation_stock_articles: '[depotRef+articleRef], articleRef, depotRef',
    synchronisation_info_depots: 'depotRef',
    synchronisation_info_depots_visu: 'depotRef',
    synchronisation_info_commerciaux: 'commercialRef',
    synchronisation_ficjoint_articles: '++idIndexedDB, reference',
    synchronisation_ficjoint_clients: 'id, reference',
    synchronisation_stat_simple: 'clientRef',
    synchronisation_tarifs: 'id, articleRef, [codeTarif+articleRef]',
    synchronisation_tarifs_speciaux: 'id, clientRef, articleRef, [clientRef+articleRef]',
    synchronisation_exceptions_tarifaires: 'id, codeTarif, tiers, famille, [famille+tiers]',
    synchronisation_remises: 'id, codeTarif, tiers, famille',
    synchronisation_remises_quantite: 'id, codeTarif, tiers, famille',
    synchronisation_ristournes: 'id, codeTarif, tiers, famille',
    synchronisation_odrd: 'id, codeTarif, tiers, famille',
    synchronisation_libelles_tarifs: 'id, codeTarif',
    synchronisation_referencement: 'clientRef',
    synchronisation_planning: 'planningId',
    synchronisation_tournee: 'tourneeId, numero',
    synchronisation_motifs_gratuite: 'motifId, motif, libelle, motifType',
    synchronisation_historique_article: '[tiers+articleRef]',
    synchronisation_frais_supplementaires: 'codeFraisFacturation',
    synchronisation_classe_remise: 'id, classeRef',
    synchronisation_deconsignes_attente: 'tiers',
    synchronisation_mise_en_avant: 'id, type',
    favoris_contacts_clients: '[tiers+contact], tiers',
    favoris_clients: 'tiers',
    favoris_articles: 'articleRef',
    paniers_entetes: '++idIndexedDB, [commercialRef+clientRef+dateCreation+utilisateurCreation], clientRef, commercialRef, id',
    paniers_lignes: '++idIndexedDB, [panierIdIndexedDB+articleRef+uniteVente], [panierId+articleRef+uniteVente+dateCreation+utilisateurCreation], id, panierId, panierIdIndexedDB',
    paniers_commentaires: '++idIndexedDB, [panierId+panierLigneId+dateCreation+utilisateurCreation], [panierId+dateCreation+utilisateurCreation], panierId, panierLigneId, panierIdIndexedDB, panierLigneIdIndexedDB',
    paniers_notes: '++idIndexedDB, [panierId+panierLigneId+dateCreation+utilisateurCreation], [panierId+dateCreation+utilisateurCreation], panierId, panierLigneId, panierIdIndexedDB, panierLigneIdIndexedDB',
    evenements: '++idIndexedDB, id, tiersRef',
    contacts: '++idIndexedDB, id, tiers',
    infos_articles_familles: null
});

db.version(106).stores({
    parametres_application: 'id',
    synchronisation_clients: 'tiers, nomClient, codePostal, ville, rue, titreRef, nomAbrege',
    synchronisation_contacts_clients: '[tiers+contact], email, tiers',
    synchronisation_evenements: 'numeroEvenement, dateDebut, tiersRef',
    synchronisation_familles_stat_1: 'familleStatRef',
    synchronisation_familles_stat_2: 'familleStatRef',
    synchronisation_familles_stat_3: 'familleStatRef',
    synchronisation_articles: 'id, articleRef, designation, dateCreation, fournisseurHabituelRef, familleStat1Ref, classeRemise',
    synchronisation_article_medias: 'id, articleRef',
    synchronisation_adresses: 'id, codeAdresse, tiers',
    synchronisation_unites: 'uniteVente',
    synchronisation_encours_vente: 'clientRef',
    synchronisation_encours_facture: 'clientRef',
    synchronisation_indicateurs_stat: '++idIndexedDB, client',
    synchronisation_indicateurs_stat_progression: '++idIndexedDB, client',
    synchronisation_stock_articles: '[depotRef+articleRef], articleRef, depotRef',
    synchronisation_info_depots: 'depotRef',
    synchronisation_info_depots_visu: 'depotRef',
    synchronisation_info_commerciaux: 'commercialRef',
    synchronisation_ficjoint_articles: '++idIndexedDB, reference',
    synchronisation_ficjoint_clients: 'id, reference',
    synchronisation_stat_simple: 'clientRef',
    synchronisation_tarifs: 'id, articleRef, [codeTarif+articleRef]',
    synchronisation_tarifs_speciaux: 'id, clientRef, articleRef, [clientRef+articleRef]',
    synchronisation_exceptions_tarifaires: 'id, codeTarif, tiers, famille, [famille+tiers]',
    synchronisation_remises: 'id, codeTarif, tiers, famille',
    synchronisation_remises_quantite: 'id, codeTarif, tiers, famille',
    synchronisation_ristournes: 'id, codeTarif, tiers, famille',
    synchronisation_odrd: 'id, codeTarif, tiers, famille',
    synchronisation_libelles_tarifs: 'id, codeTarif',
    synchronisation_referencement: 'clientRef',
    synchronisation_planning: 'planningId',
    synchronisation_tournee: 'tourneeId, numero',
    synchronisation_motifs_gratuite: 'motifId, motif, libelle, motifType',
    synchronisation_historique_article: null,
    synchronisation_frais_supplementaires: 'codeFraisFacturation',
    synchronisation_classe_remise: 'id, classeRef',
    synchronisation_deconsignes_attente: 'tiers',
    synchronisation_mise_en_avant: 'id, type',
    favoris_contacts_clients: '[tiers+contact], tiers',
    favoris_clients: 'tiers',
    favoris_articles: 'articleRef',
    paniers_entetes: '++idIndexedDB, [commercialRef+clientRef+dateCreation+utilisateurCreation], clientRef, commercialRef, id',
    paniers_lignes: '++idIndexedDB, [panierIdIndexedDB+articleRef+uniteVente], [panierId+articleRef+uniteVente+dateCreation+utilisateurCreation], id, panierId, panierIdIndexedDB',
    paniers_commentaires: '++idIndexedDB, [panierId+panierLigneId+dateCreation+utilisateurCreation], [panierId+dateCreation+utilisateurCreation], panierId, panierLigneId, panierIdIndexedDB, panierLigneIdIndexedDB',
    paniers_notes: '++idIndexedDB, [panierId+panierLigneId+dateCreation+utilisateurCreation], [panierId+dateCreation+utilisateurCreation], panierId, panierLigneId, panierIdIndexedDB, panierLigneIdIndexedDB',
    evenements: '++idIndexedDB, id, tiersRef',
    contacts: '++idIndexedDB, id, tiers',
    infos_articles_familles: '[familleRef+indiceValiditeDepot], niveau'
});

}