import { Observable, tap } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../services/auth/auth.service';
import { LoginService } from '../../services/login/login.service';
import { LoggerService } from 'src/app/services/log/logger.service';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';

export class AuthInterceptor implements HttpInterceptor {
  private urlServeur = '';

  public constructor(
    private authService: AuthService,
    private loginService: LoginService,
    private loggerService: LoggerService,
    private appConfigService: AppConfigService
  ) {
    this.urlServeur = this.appConfigService.getConfig().urlBaseServeurERP;
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      !request ||
      !request.url ||
      (/^http/.test(request.url) && !(this.urlServeur && request.url.startsWith(this.urlServeur)))
    ) {
      this.loggerService.verbose(`Requête vers ${request.url} non authentifiée`);
      return next.handle(request);
    }

    const token: string | null = this.authService.recupererToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
        },
        url: request.url,
      });
      this.loggerService.verbose(`Requête vers ${request.url} authentifiée`);
    } else {
      this.loggerService.verbose(`Requête vers ${request.url} non authentifiée`);
    }


    return next.handle(request).pipe(tap({ error: (err) => this.gestionErreur(request, err) }));
  }

  public gestionErreur(request: HttpRequest<unknown>, err: unknown): void {
    if (err instanceof HttpErrorResponse) {
      if (!err.ok) {
        let tokenRenouvele = false;
        if (this.loginService.peutGererRefreshToken) {
          // TODO Renouveler le token si possible
          tokenRenouvele = false;
        }
        if (!tokenRenouvele) {
          this.loginService.ouvrirModale();
          this.loggerService.warn(
            `Essai appel ${request.url} mais problème d'authentification, déconnexion et ouverture de la modale de login.`
          );
          this.loginService.authentificationEnCours = true;
        }
      }
    }
  }
}
