import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { SynchronisationArticle, SynchronisationClient, SynchronisationUnite, TarifSynchronise } from '../synchronisation/synchronisation';
import { UtilitaireGeneralService } from '../utilitaire/utilitaire-general.service';
import { ArticleEtendu } from 'src/app/modules/crm/catalogue/liste-articles/liste-articles.component';
import { SynchronisationStockArticle } from '../synchronisation-quotidienne/synchronisation-quotidienne';
import { UtilitaireCrmService } from '../utilitaire/utilitaire-crm.service';

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  constructor(
    private httpService: HttpService,
    private utilitaireGeneralService: UtilitaireGeneralService,
    private utilitaireCrmService: UtilitaireCrmService,
  ) { }

  public urlImage(image?: string): string {
    if (!image) return "";
    return this.httpService.urlBaseRessources + "business/crm/image/" + (image ? image : "");
  }

  public urlFichier(typeFichier: string, codeFichier: string | number): string {
    if (!typeFichier) return "";
    return `${this.httpService.urlBase}business/crm/fichier/${typeFichier}/${codeFichier}`;
  }

  public urlImageMiseEnAvant(mea?: number): string {
    return this.httpService.urlBase + "business/crm/image-mise-en-avant/" + mea;
  }

  public filtreClient(client: SynchronisationClient, recherche: string): boolean {
    return this.utilitaireGeneralService.strContientSouple(`${client.titreRef} ${client.nomClient}`, recherche)
      || this.utilitaireGeneralService.strContientSouple(client.ville, recherche)
      || this.utilitaireGeneralService.strContientSouple(client.codePostal, recherche)
      || this.utilitaireGeneralService.strContientSouple(client.rue, recherche)
      || this.utilitaireGeneralService.strContientSouple(client.tiers, recherche)
      || this.utilitaireGeneralService.strContientSouple(client.nomAbrege, recherche)
  }

  public etendreArticle(article: SynchronisationArticle, unites: SynchronisationUnite[], stocks: SynchronisationStockArticle[]): ArticleEtendu {
    const coefficientsUnites: { unite: SynchronisationUnite, coefficient: number }[] = unites
      .filter((unite: SynchronisationUnite) =>
        [article.uniteAchat, article.uniteVente, article.uniteStockage, article.uniteReference, article.uniteDivers1, article.uniteDivers2, article.unitePalette].includes(unite.uniteVente))
      .map((unite: SynchronisationUnite) => ({
        unite,
        coefficient: this.obtenirCoefficient(article, unite)
      }));
    coefficientsUnites.sort((a, b) => a.coefficient - b.coefficient);
    const unitesArticle: SynchronisationUnite[] = coefficientsUnites.map(pair => pair.unite);

    const stock: SynchronisationStockArticle | undefined = stocks.find((stock: SynchronisationStockArticle) => stock.articleRef === article.articleRef);
    const stockDispoConverti = this.utilitaireCrmService.conversionUniteRefVersStock(article, stock?.qteStock ?? 0);
    const stockReserveConverti = this.utilitaireCrmService.conversionUniteRefVersStock(article, stock?.qteStockReserve ?? 0);

    return {
      ...article,
      panierQuantite: 0,
      panierQuantiteGratuit: 0,
      motifQuantiteGratuit: "",
      panierPrixClavier: null,
      motifPrixClavier: "",
      panierUnite: article.uniteVente,
      panierUnites: unitesArticle,
      estFavori: false,
      chargement: false,
      uniteStockConverti: stockDispoConverti.unite,
      stockConverti: stockDispoConverti.qte,
      stockConvertiCoefficient: stockDispoConverti.coefficient,
      uniteStockReserveConverti: stockReserveConverti.unite,
      stockReserveConverti: stockReserveConverti.qte,
      stockReserveConvertiCoefficient: stockReserveConverti.coefficient,
      tarif: undefined,
      tarifReference: undefined,
    }
  }

  private obtenirCoefficient(article: SynchronisationArticle, unite: SynchronisationUnite): number {
    switch (unite.uniteVente) {
      case article.uniteAchat: return article.uniteAchatCoefficient ?? 0;
      case article.uniteVente: return article.uniteVenteCoefficient ?? 0;
      case article.uniteStockage: return article.uniteStockageCoefficient ?? 0;
      case article.uniteReference: return article.uniteReferenceCoefficient ?? 0;
      case article.uniteDivers1: return article.uniteDivers1Coefficient ?? 0;
      case article.uniteDivers2: return article.uniteDivers2Coefficient ?? 0;
      case article.unitePalette: return article.unitePaletteCoefficient ?? 0;
      default: return 0;
    }
  }

  public recupererTarifPourUnite(article: SynchronisationArticle, unite: string, tarif?: TarifSynchronise): number | undefined {
    if(!tarif) return undefined;
    const coefficients = {
      [article.uniteReference]: 1,
      [article.uniteAchat]: article.uniteAchatCoefficient,
      [article.uniteVente]: article.uniteVenteCoefficient,
      [article.uniteStockage]: article.uniteStockageCoefficient,
      [article.uniteDivers1]: article.uniteDivers1Coefficient,
      [article.uniteDivers2]: article.uniteDivers2Coefficient,
      [article.unitePalette]: article.unitePaletteCoefficient,
    }

    const coefficient: number | undefined = coefficients[unite];
    if (!coefficient) return undefined;

    return tarif.prix * coefficient;
  }

  public recupererConsignePourUnite(article: SynchronisationArticle, unite: string): number | undefined {
    const prix = {
      [article.uniteReference]: article.uniteReferenceConsigne,
      [article.uniteAchat]: article.uniteAchatConsigne,
      [article.uniteVente]: article.uniteVenteConsigne,
      [article.uniteStockage]: article.uniteStockageConsigne,
      [article.uniteDivers1]: article.uniteDivers1Consigne,
      [article.uniteDivers2]: article.uniteDivers2Consigne,
      [article.unitePalette]: article.unitePaletteConsigne,
    }

    return prix[unite];
  }
}
