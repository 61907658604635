import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppCustomisationConfig } from './AppCustomisationConfig';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private config: AppCustomisationConfig = { urlBaseServeurERP: '', clientFinal: false };
  loaded = false;

  public modeDev: boolean = false;
  
  constructor(private http: HttpClient) { }

  loadConfigXhr(): Promise<AppCustomisationConfig> {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', 'custom-perso/config.json');

      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          this.config = JSON.parse(xhr.responseText);
          this.loaded = true;
          resolve(this.config);
        } else if (xhr.readyState === XMLHttpRequest.DONE) {
          // Pas de fichier de custo ==> on prend celui de l'environnement
          const parametreSpecifique = environment.parametreSpecifique as AppCustomisationConfig;
          this.config = {
            urlBaseServeurERP: parametreSpecifique.urlBaseServeurERP,
            urlBaseServeurRessources: parametreSpecifique.urlBaseServeurRessources,
            clientFinal: parametreSpecifique.clientFinal
          };
          this.modeDev = parametreSpecifique.modeDevAuLancement ?? false;
          resolve(this.config);
          // reject();
        }
      });

      xhr.send(null);
    });
  }
  loadConfig(): Promise<void> {
    return this.http
      .get<AppCustomisationConfig>('/assets/app.config.json')
      .toPromise()
      .then(data => {
        if (data) this.config = data;
        this.loaded = true;
      }
       );
  }

  getConfig(): AppCustomisationConfig {
    return this.config;
  }

  public get modeClientFinal(): boolean {
    return this.config.clientFinal
  }

  public get modeCommercial(): boolean {
    return !this.config.clientFinal
  }
}
